/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Fab } from '@material-ui/core';
import { connect } from 'react-redux';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';

const { de_de } = lang[1];
const fields = ['email'];
const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Pflichtfeld'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail nicht korrekt'
  }
  return errors;
};

const renderField = ({
  input,
  type,
  placeholder,
  meta: { touched, error },
  className
}) => (
  <React.Fragment>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      className={(touched && error) ? `${className} error` :  `${className}`}
    />
    <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
);

class WizardEmailForm extends Component {
  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isEmailRegistered,
      isFetching,
      submitting
    } = this.props;
    
    return (
      <form onSubmit={isFetching ? '' : handleSubmit} noValidate={true}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            {de_de.intro2_title}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.login_email_title_input_email}</h2>
          <p>{de_de.login_email_message}</p>
          <div className="input-fields field-email">
            <Field 
              name="email"
              type="email"
              placeholder={de_de.login_email_placeholder_input_email}
              component={renderField}
              label="Username"
              className="input-id-login"
            />
            {!isEmailRegistered && 
              <div>
                <span>{de_de.login_email_registriert_email}</span>
              </div>
            }
          </div>
          <div className="submits submit-email">
            <button className="account-submit" type="submit" disabled={submitting}>
                {de_de.login_email_button_submit_email}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.LoginPhone.isFetching
});
 
export default reduxForm({
  form: 'loginEmail',
  fields,
  destroyOnUnmount: false,
  validate
})(connect(mapStateToProps)(WizardEmailForm));
