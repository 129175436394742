/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { formValueSelector, reset } from 'redux-form';
import { _dataInfoTracking } from '../../lib';
import { history } from '../../helper';
import {
  getError,
  updateProfile,
  checkEmailPhoneErr,
  getPhone,
  getEmail
} from '../../actions/register';
import { _getUserProfile } from '../../actions';
import lang from '../../config/languages.json';
import FormUserName from './form-username-form';
import FormEmailPhone from './form-email-phone';
import { errorCodeConstants } from '../../config';

const formSelector = formValueSelector('updateProfile');

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isPhoneExist: false,
      isEmailExist: false,
      isPhoneWrongFormat: false,
    };
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handlePreviousPageFromUsernam = this.handlePreviousPageFromUsernam.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    this.props.reset('updateProfile');
    this.props._getUserProfile();
  }

  handleNextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  async handleUpdateProfile() {
    const { de_de } = lang[1];
    const {
      gender,
      firstname,
      lastname, day, month, year,
      address, zipcode, city, phone, email,
      userId,
      reset,
      getPhone,
      currentPhone,
      updateProfile,
      getEmail
    } = this.props;
    this.setState({
      isPhoneExist: false,
      isEmailExist: false,
    });
    const dayFormat = (day < 10 && day.toString().length === 1) ? `0${day}` : day;
    const monthFormat = (month < 10 && month.toString().length === 1) ? `0${month}` : month;
    const birthday = `${dayFormat}.${monthFormat}.${year}`;
    // CHECK IF EMAIL EXISTS
    await getEmail({ email });
    // CHECK IF PHONE EXISTS
    await getPhone({ phone });
    if (!this.props.checkPhoneError) {
      if (!this.props.isPhoneExist.exists) {
        this.setState({
          isPhoneExist: false,
        });
      } else if (this.props.isPhoneExist.exists) {
        this.setState({ isPhoneExist: true });
      }
      this.setState({ isPhoneWrongFormat: false })
    } else {
      this.setState({ isPhoneWrongFormat: true });
    }
    if (this.props.isEmailExist.exists) {
      this.setState({
        isEmailExist: true,
      });
    } else {
      this.setState({
        isEmailExist: false,
      });
    }
    // END CHECK PHONE EXISTS
    if (!(this.state.isEmailExist || this.state.isPhoneExist)) {
      _dataInfoTracking(userId.id, de_de.sentry_info, de_de.sentry_profile_updated, errorCodeConstants.profileUpdate);
      await updateProfile(
        { gender, firstname, lastname, birthday, zipcode, city, address, phone, email },
        userId.id);
      if (!this.props.updateError) {
        reset('updateProfile');
        history.push('/shoppy-pass');
      }
    }
  }

  handlePreviousPage() {
    this.setState({ page: this.state.page - 1, isPhoneExist: false, isEmailExist: false ,isPhoneWrongFormat: false });
  }

  handlePreviousPageFromUsernam() {
    this.props.reset('updateProfile');
    history.push("/shoppy-pass");
  }

  render() {
    const { page, isPhoneExist, isEmailExist, isPhoneWrongFormat } = this.state;
    return (
      <div className="register-page">
        {page === 1 &&
          <FormUserName
            onSubmit={this.handleNextPage}
            handlePreviousPage={this.handlePreviousPageFromUsernam}
          />
        }
        {page === 2 &&
          <FormEmailPhone
            onSubmit={this.handleUpdateProfile}
            handlePreviousPage={this.handlePreviousPage}
            isPhoneExist={isPhoneExist}
            isEmailExist={isEmailExist}
            isPhoneWrongFormat={isPhoneWrongFormat}
          />
        }
        <datalist id="gender">
          <option value="Frau" />
          <option value="Herr" />
        </datalist>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const gender = formSelector(state, 'gender');
  const firstname = formSelector(state, 'firstname');
  const lastname = formSelector(state, 'lastname');
  const day = formSelector(state, 'day');
  const month = formSelector(state, 'month');
  const year = formSelector(state, 'year');
  const address = formSelector(state, 'address');
  const email = formSelector(state, 'email');
  const zipcode = formSelector(state, 'zipcode');
  const city = formSelector(state, 'city');
  let phone = formSelector(state, 'phone');
  const updateError = getError(state.UpdateProfile);
  const isPhoneExist = state.Register.dataPhone.data;
  const isEmailExist = state.Register.dataEmail.data;
  const checkPhoneError = checkEmailPhoneErr(state.Register, 'phoneError');
  const currentPhone = state.profile.data.data;
  const userId = state.profile.data.data;
  const isAuthenticated = state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated;

  phone = phone ? phone.replace(/\s/g, "") : '';
  if (phone && phone.substring(0, 3) === '+41') {
    phone = `0${phone.substring(3)}`;
  }

  return {
    gender,
    email,
    firstname,
    lastname,
    day,
    month,
    year,
    address,
    zipcode,
    city,
    phone,
    updateError,
    isPhoneExist,
    isEmailExist,
    checkPhoneError,
    currentPhone,
    userId,
    isAuthenticated,
  }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    reset,
    _getUserProfile,
    updateProfile,
    getPhone,
    getEmail
  }, dispatch)
  // {
  //   reset: () => dispatch(reset()),
  //   _getUserProfile: () => dispatch(_getUserProfile()),
  //   updateProfile: () => dispatch(updateProfile()),
  //   getPhone: () => dispatch(getPhone()),
  //   getEmail: () => dispatch(getEmail())
  // }
);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
