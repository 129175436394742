import axios from 'axios';
import {
  getData,
  getDataSuccess,
  getDataFailure,
} from '../actions';
import {
  methodConstants,
} from '../config';
import { authHeader } from '../helper';

export const $fetch = (ROOT_URL, urn, method, $geDataType = null, $getDataSuccessType = null, $getDataFailureType = null, bodyParameters = {}, auth = true, store = null) => {
  const url = ROOT_URL + urn;
  let requestPromise = null;
  let headersAuthor = authHeader();
  if (Object.keys(headersAuthor).length === 0 && headersAuthor.constructor === Object ) {
    headersAuthor['X-Guest-Id'] = localStorage.getItem('bonus_uuid_guest');
  }
  return (dispatch) => {
    switch (method) {
      case methodConstants.POST:
        dispatch(getData($geDataType));
        requestPromise = axios({
          method: 'POST',
          url,
          headers: headersAuthor,
          data: bodyParameters
        });
        break;
      case methodConstants.GET:
        dispatch(getData($geDataType));
        requestPromise = axios({
          method: 'GET',
          headers: headersAuthor,
          url
        });
        break;
      case methodConstants.PUT:
        dispatch(getData($geDataType));
        requestPromise = axios({
          method: 'PUT',
          url,
          headers: headersAuthor,
          data: bodyParameters
        });
        break;
      case methodConstants.DELETE:
        dispatch(getData($geDataType));
        requestPromise = axios({
          method: 'DELETE',
          url,
          headers: headersAuthor
        });
        break;
      default:
        return false;
    }

    return requestPromise.then((response) => {
      dispatch(getDataSuccess($getDataSuccessType, response));
      return response;
    })
      .catch((error) => {
        dispatch(getDataFailure($getDataFailureType, error));
        return error;
      });
  };
};


