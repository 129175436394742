import {
  alertConstants
} from '../config';

const INITIALSTATE = {
  data: [],
  isFetching: false,
  error: false,
  birthDay: ''
};

export const ProfileReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_CURRENT_USER:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_CURRENT_USER:
      return {
        ...state,
        data: action.payload,
        birthDay: action.payload.data.birthday,
        isFetching: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    default:
      return state;
  }
};

const INITIALSTATE_UPLOAD = {
  data: [],
  isFetching: false,
  error: false,
  birthDay: ''
};

export const UploadPhoto = (state = INITIALSTATE_UPLOAD, action) => {
  switch (action.type) {
    case alertConstants.UPLOAD_PHOTO:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        birthDay: action.payload.data.birthday,
        isFetching: false,
        error: false
      };
    case alertConstants.UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    default:
      return state;
  }
};
