import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Fab } from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import validate from './form-validation';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';
import { settingContants } from '../../config';

const fields = ['email', 'phone', 'address', 'zipcode', 'city'];

const normalizePostalCode = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 5 ) {
    return value;
  }
}
const normalizeAddressPlace = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 50 ) {
    return value;
  }
}
const renderField = ({
  input,
  type,
  label,
  meta: { touched, error },
  className,
  disabled,
  isEmailExist,
}) => (
  <React.Fragment>
    <input
      {...input}
      placeholder={label}
      type={type}
      className={((touched && error)) || isEmailExist ? `${className} error` :  `${className}`}
      disabled={disabled}
    />
    <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
);

const renderPhoneField = ({
  meta: { touched, error },
  name,
  input,
  isPhoneExist,
  isPhoneWrongFormat
}) => (
  <ReactPhoneInput
    defaultCountry={settingContants.countryCode}
    containerStyle={styleContainer}
    inputClass={(touched && error) || isPhoneExist || isPhoneWrongFormat? 'input-id-login-phone error' : 'input-id-login-phone' }
    buttonClass="input-id-login-phone-flag"
    disableDropdown
    disableAreaCodes
    disableSearchIcon
    inputExtraProps={{
      name: name,
      required: true,
      autoFocus: true
    }}
    {...input}
    countryCodeEditable={false}
  />
);

class WizardUserName extends Component {

  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isPhoneExist,
      isEmailExist,
      isPhoneWrongFormat
    } = this.props;
    const { de_de } = lang[1];
    return (
      <form onSubmit={handleSubmit}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            Profil aktualisieren
          </Fab>
        </div>
        <div className="section-content">
          <h2 className="brow-color">{de_de.register_title_contact_info}</h2>
          <div className="input-fields">
            <Field 
              name="email"
              type="email"
              component={renderField}
              label={de_de.register_placeholder_email}
              disabled={false}
              className="input-id-login disable"
              isEmailExist={isEmailExist}
            />
            <span>{isEmailExist ? 'Diese E-mail wird bereits genutzt' : ''}</span>
            {/* <Field 
              name="phone"
              type="tel"
              component={renderField}
              label="Handynummer"
              className="input-id-login"
              // disabled={true}
              // className="input-id-login disable"
            /> */}
            <Field
              name="phone"
              type="tel"
              component={renderPhoneField}
              label={de_de.register_placeholder_username}
              className="input-id-login"
              isPhoneExist={isPhoneExist}
              isPhoneWrongFormat={isPhoneWrongFormat}
            />
            <span>{isPhoneExist ? 'Diese Telefonnummer wird bereits genutzt' : ''}</span>
            <span>{isPhoneWrongFormat ? 'Dieses Handynummer ist nicht gültig!' : ''}</span>
            <Field 
              name="address"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_address}
              className="input-id-login"
              normalize={normalizeAddressPlace}
            />
            <Field 
              name="zipcode"
              type="number"
              component={renderField}
              label={de_de.register_placeholder_postcode}
              normalize={normalizePostalCode}
              className="input-id-login"
            />
            <Field 
              name="city"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_place}
              className="input-id-login"
              normalize={normalizeAddressPlace}
            />
          </div>
          <div className="submits submit-email-phone">
            <button className="account-submit brow-background-color" type="submit">
              {de_de.register_placeholder_button}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
 
export default reduxForm({
  form: 'updateProfile',
  fields,
  destroyOnUnmount: false,
  validate,
})(WizardUserName);

const styleContainer = {
  width: '90%',
  margin: '0 auto',
  marginTop: '10px'
};
