/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { formValueSelector, reset } from 'redux-form'; 
import { calculate_age, _dataInfoTracking } from '../../lib';
import FormUserName from './form-username-form';
import FormEmailPhone from './form-email-phone';
import FormPhoneCode from './form-phone-code';
import {
  getOTP,
  verfiyOTP,
  registerUser,
  getError,
  checkEmailPhoneErr,
  getEmail,
  getPhone
} from '../../actions/register';
import { loginEmail, getError as getErrorFromLogin } from '../../actions/login';
import lang from '../../config/languages.json';
import { errorCodeConstants } from '../../config';

const formSelector = formValueSelector('register');

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      codeError: false,
      isEmailExist: false,
      isPhoneExist: false,
      isPhoneInvalid: false,
      isPersonOldUnder18: false,
      code: ''
    };
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handlePreviousPageFromUsernam = this.handlePreviousPageFromUsernam.bind(this);
    this.handleNextPageGetOPT = this.handleNextPageGetOPT.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleGetOtherOTP = this.handleGetOtherOTP.bind(this);
  }

  handleNextPage() {
    const { day, month, year } = this.props;
    const date = String(month + '/' + day + '/' + year);
    const yearOlds = calculate_age(new Date(date));

    if (yearOlds < 18) {
      return this.setState({ isPersonOldUnder18: true });
    }

    this.setState({ page: this.state.page + 1, isPersonOldUnder18: false });
  }

  async handleNextPageGetOPT() {
    const { de_de } = lang[1];
    const { phone, email, getOTP, getEmail, getPhone, phoneError } = this.props;
    await getEmail({ email });
    await getPhone({ phone });
    if (!this.props.checkEmailError &&
      !this.props.checkPhoneError) {

      if(!this.props.isPhoneExist.exists &&
        !this.props.isEmailExist.exists) {
        this.setState({
          isEmailExist: false,
          isPhoneExist: false
        });
        return await getOTP({ phone }).then(() => {
          if (!this.props.getOtpError) {
            this.setState({ page: this.state.page + 1 });
          }
        });
      } else if (this.props.isPhoneExist.exists && !this.props.isEmailExist.exists){
        _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_register_fail_phone_exist, errorCodeConstants.registerFailPhoneExist);
        this.setState({ isPhoneExist: true, isPhoneInvalid: false, isEmailExist: false });
      } else if (this.props.isEmailExist.exists && !this.props.isPhoneExist.exists) {
        _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_register_fail_email_exist, errorCodeConstants.registerFailEmailExist);
        this.setState({ isEmailExist: true, isPhoneExist: false });
      } else if (this.props.isEmailExist.exists && this.props.isPhoneExist.exists) {
        _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_register_fail_phone_email_exist, errorCodeConstants.registerFailEmailPhoneExist);
        this.setState({ isEmailExist: true, isPhoneExist: true, isPhoneInvalid: false });
      } 
    }
    // const isPhoneInvalid = (_.get(phoneError, 'response.data.type', "Err") === "ValidationException");
    if (this.props.checkPhoneError){
      this.setState({
        isPhoneInvalid: true,
        isPhoneExist: false
      });
    } 
    
    if (!this.props.isEmailExist.exists){
      this.setState({
        isEmailExist: false,
      });
    }else{
      this.setState({
        isEmailExist: true,
      });
    }
  }

  async handleGetOtherOTP(e) {
    e.preventDefault();
    const { phone, getOTP } = this.props;
    this.setState({
      code: ''
    });
    return await getOTP({ phone });
  }

  async handleRegister(code) {
    const { de_de } = lang[1];
    const {
      gender,
      firstname, lastname, day, month, year,
      email, phone, address, zipcode, city,
      verfiyOTP,
      registerUser,
      loginEmail,
      reset
    } = this.props;
    const dayFormat = (day < 10 && day.toString().length === 1) ? `0${day}` : day;
    const monthFormat = (month < 10 && month.toString().length === 1) ? `0${month}` : month;
    const birthday = `${dayFormat}.${monthFormat}.${year}`;
    
    if (!code || code === '') {
      this.setState({ codeError: true})
      return;
    }

    await verfiyOTP({ phone, code });
    if (!this.props.verifyOtpError) {
      await registerUser({
        gender, lastname, firstname, birthday,
        email, phone, address, zipcode, city
      });
      if (!this.props.registerError) {
        await loginEmail({email, postcode: zipcode}, true);
        if(!this.props.loginError) {
          _dataInfoTracking(phone, de_de.sentry_info, de_de.sentry_register_successful, errorCodeConstants.registerSuccess);
          this.props.history.push('/user/my-page');
          reset("register");
        }
        else {
         
          this.props.history.push('/login-email');
          reset("register");
        }
      } else {
        this.setState({ codeError: false });
      }
    } else {
      _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_register_fail_opt_wrong, errorCodeConstants.registerFailOTPWrong);
      this.setState({ codeError: true})
    }
    
  }

  handlePreviousPage() {
    this.setState({ page: this.state.page - 1, codeError: false });
  }

  handlePreviousPageFromUsernam() {
    this.props.history.goBack();
    // this.props.history.push("/home");
  }

  render() {
    const { page, codeError, isPersonOldUnder18, code } = this.state;
    return (
      <div className="register-page">
        {page === 1 &&
          <FormUserName
            onSubmit={this.handleNextPage}
            isPersonOldUnder18={isPersonOldUnder18}
            handlePreviousPage={this.handlePreviousPageFromUsernam}
          />
        }
        {page === 2 &&
          <FormEmailPhone
            onSubmit={this.handleNextPageGetOPT}
            handlePreviousPage={this.handlePreviousPage}
            isEmailExist={this.state.isEmailExist}
            isPhoneExist={this.state.isPhoneExist}
            isPhoneInvalid={this.state.isPhoneInvalid}
          />
        }
        {page === 3 &&
          <FormPhoneCode
            handleRegister={this.handleRegister}
            handlePreviousPage={this.handlePreviousPage}
            handleGetOtherCode={this.handleGetOtherOTP}
            codeError={codeError}
            code={code}
          />
        }
        <datalist id="gender">
          <option value="Frau" />
          <option value="Herr" />
        </datalist>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const gender = formSelector(state, 'gender');
  const firstname = formSelector(state, 'firstname');
  const lastname = formSelector(state, 'lastname');
  const day = formSelector(state, 'day');
  const month = formSelector(state, 'month');
  const year = formSelector(state, 'year');
  const email = formSelector(state, 'email');
  let phone = formSelector(state, 'tel');
  const address = formSelector(state, 'address');
  const zipcode = formSelector(state, 'zipcode');
  const city = formSelector(state, 'city');
  const registerError = getError(state.Register);
  const getOtpError = getError(state.GetOTP);
  const verifyOtpError = getError(state.VerifyOTP);
  const isPhoneExist = state.Register.dataPhone.data;
  const isEmailExist = state.Register.dataEmail.data;
  const checkEmailError = checkEmailPhoneErr(state.Register, 'emailError');
  const checkPhoneError = checkEmailPhoneErr(state.Register, 'phoneError');
  const phoneError = checkEmailPhoneErr(state.Register, 'dataPhone');
  const loginError = getErrorFromLogin(state.LoginEmail);

  phone = phone ? phone.replace(/\s/g, "") : '';
  if (phone && phone.substring(0, 3) === '+41') {
    phone = `0${phone.substring(3)}`;
  }

  return {
    gender,
    firstname,
    lastname,
    day,
    month,
    year,
    email,
    phone,
    address,
    zipcode,
    city,
    registerError,
    getOtpError,
    verifyOtpError,
    isPhoneExist,
    isEmailExist,
    checkEmailError,
    checkPhoneError,
    phoneError,
    loginError,
  }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ getOTP, verfiyOTP, registerUser, getEmail, getPhone, loginEmail, reset }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
