import React, { Component } from 'react';
import BackButton from '../shared/back-button';
import GutscheinCoffee from '../../assets/img/gutschein-coffee.jpg';
import { Button } from '@material-ui/core';
import lang from '../../config/languages.json';

class Gutschein extends Component {
  render() {
    const { de_de } = lang[1];
    return (
      <div className="gutschein-container">
        <BackButton to="/" title="Gutschein" />
        <div className="gutschein-content">
          <img src={GutscheinCoffee} alt="" />
          <div className="gutschein-message">
            <h1>{de_de.text_kaffee}</h1>
            <p>{de_de.kaffee_content}</p>
            <strong>{de_de.kaffee_years}</strong>
            <Button
              variant="contained"
              className="btn-gutschein"
              // onClick={props.handleSaveProfilePicture}
            >
                  {de_de.btn_gutschein_ein}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Gutschein;
