/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import IconCropQuare from '@material-ui/icons/CropSquare';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import { _getRedeemVoucher } from '../../actions';
import FormGroup from '@material-ui/core/FormGroup';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import lang from '../../config/languages.json';


const styles = {
  root: {
    color: '#ffffff',
    '&$checked': {
      color: '#ffffff',
    },
  },
}


class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: localStorage.getItem("v"),
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, id } = this.props;
    const { checked } = this.state;
    const { de_de } = lang[1];
    checked ? localStorage.setItem("v", 1) : localStorage.removeItem("c");
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={true}
          className="dialog-screen"
          classes={classes}
        >
          <Button
            onClick={this.props.handleClose}
            color="primary" autoFocus
            className="btn-deny"
          >
            <ClearIcon classes={classes} />
          </Button>
          <div className="dialog-bg"></div>
          <DialogTitle id="alert-dialog-title" >
            {"Mochten Sie dieses Gutschein wirklich löschen"}</DialogTitle>
          <DialogContent>
            <DialogContentText className="alert-dialog-description">
              <Button
                onClick={this.props.handleConfirm.bind(this, id)}
                color="primary"
                className="btn-sign-in"
              >
                    {de_de.btn_gutschein}
            </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="action-dialog">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checked}
                    onChange={this.handleChange('checked')}
                    value="checked"
                    color="primary"
                    classes={classes}
                  />
                }
                label={de_de.hide_msg}
              />
            </FormGroup>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function CheckedIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.05152L8.92235 14.8571L22 2" stroke="#C0A35E" strokeWidth="4" />
    </svg>

  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getRedeemVoucher
  }, dispatch)
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AlertDialog));
