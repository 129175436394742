/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CallMade from '@material-ui/icons/CallMade';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import lang from '../../../config/languages.json';

class ListPage extends Component {
  handleOpenShop = (link) => {
    window.open(link, "_blank");
  }

  render() {
    const { classes, data } = this.props;
    const { de_de } = lang[1];
    const currentPath = this.props.match.path.match(/public|user/g)[0];
    return (
      <List component="nav" className={classes.root}>
        <ListItem className={classes.header}>
          <ListItemText primary="Profil" className="list-header-title" />
        </ListItem>
        <div className="list-wrapper">
          {currentPath === 'user' ?
          <ListItem button className={classes.dimension} component={Link} to="/shoppy-pass">
            <ListItemText primary="Mein Shoppy-Pass" className="list-item-title" />
            <ArrowForwardIos className={classes.icon} />
          </ListItem>
          : (
          <div>
            <ListItem 
              button 
              divider 
              className={classes.dimension}
              component={Link}
              to="/login-phone"
            >
              <ListItemText primary="Anmelden" className="list-item-title" />
              <ArrowForwardIos className={classes.icon} />
            </ListItem>
            <ListItem 
              button 
              className={classes.dimension}
              component={Link}
              to="/register"
            >
              <ListItemText primary="Registrieren" className="list-item-title" />
              <ArrowForwardIos className={classes.icon} />
            </ListItem>
          </div>
          )}
        </div>
        <ListItem className={classes.header}>
          <ListItemText primary="informationen" className="list-header-title" />
        </ListItem>
        <div className="list-wrapper">
          {data.length !== 0 &&
            data.map((item) => 
              <ListItem
                key={item.id}
                button
                divider
                className={classes.dimension}
                onClick={() => this.handleOpenShop(item.link)}
              >
                <ListItemText primary={item.name} className="list-item-title" />
                <CallMade className={classes.icon} />
              </ListItem>
            )
          }
          {/* <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Öffnungszeiten" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem> */}
          {/* <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Shops & Gastro" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem>
          <ListItem button divider className={classes.dimension}>
            <ListItemText primary="News & Events" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem>
          <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Anfahrt & Parken" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem>
          <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Kontakt" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem>
          <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Impressum" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem>
          <ListItem button divider className={classes.dimension}>
            <ListItemText primary="Datenschutz" className="list-item-title" />
            <CallMade className={classes.icon} />
          </ListItem> */}
        </div>
      </List>

    )
  }
}

export default withStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: 0
  },
  icon: {
    color: '#C0A35E'
  },
  dimension: {
    height: '66px',
  },
  header: {
    height: '66px',
    backgroundColor: theme.palette.background.paper,
  }
}))(withRouter((ListPage)));
