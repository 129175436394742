/* eslint-disable no-console */
/* eslint-disable no-this-before-super */
/* eslint-disable constructor-super */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../helper';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import lang from '../../config/languages.json';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastAuth: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    
    const currentAuth = nextProps.isAuthenticated;
    const lastAuth = state.lastAuth;
    if (currentAuth !== lastAuth) {
      history.push('/user/my-page');
      return {
        lastAuth: currentAuth
      }
    }
    
    return null;
  }

  render() {
    const { de_de } = lang[1];
    return (
      <div className="home welcome-container">
        <div className="welcome-screen">
          <div className="welcome-header">{de_de.intro2_title}</div>
          <div className="welcome-body">
            <p>{de_de.intro2_h1}</p>
            <p>{de_de.intro2_h2} <br />
            {de_de.intro2_h2_1}</p>
            <p>
              {de_de.intro2_sub}
            </p>
            <p><Link to="/vortelie" className="text-link">{de_de.intro2_vorteile_link}</Link></p>
           
          </div>
          <Button
            variant="contained"
            className="btn-welcome"
            component={Link}
            to="/register"
          >
                {de_de.intro2_register_btn}
          </Button>
          <Button
            variant="contained"
            className="btn-signup"
            component={Link}
            to="/login-phone"
          >
                {de_de.intro2_login_btn}
          </Button>
          <Button
            variant="contained"
            className="btn-guest"
            component={Link}
            to="/public/guest"
          >
                {de_de.intro2_guest_link}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated
});

export default connect(mapStateToProps)(Home);
