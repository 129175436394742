/* eslint-disable no-unused-vars */
import green from '@material-ui/core/colors/green';
export const breakpoint = theme => ({
  root: {
    padding: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: green[500],
    },
  },
});

export const isValidDate = s => {
  let day, A= s.match(/[1-9][\d]*/g);
  try{
    A[0]-= 1;
    day= new Date(+A[2], A[0], +A[1]);
    if(day.getMonth() === parseInt(A[0]) && day.getDate() === parseInt(A[1])) return day;
    throw new Error('bad_day');
  }
  catch(er){
    return er.message;
  }
}

export const calculate_age = (date) => { 
  const diff_ms = Date.now() - date.getTime();
  const age_dt = new Date(diff_ms); 

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}
