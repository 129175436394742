/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { history } from '../../helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Header from '../shared/back-button';
import Carousel from '../GuestCarouselCoupon';
import News from '../News';
import BottomNavigation from '../BottomNavigation';
import lang from '../../config/languages.json';
import { _checkRefresh } from '../../lib'; 

import {
  _getOpeningTime,
  _getLastedNews,
  _getGuestCoupons,
  _getGuestCategories,
  _cleanUnusedQuestCategories
} from '../../actions/guest';

class Guest extends Component {
  
  componentDidMount() {
    const {
      _getOpeningTime,
      _getLastedNews,
      _getGuestCoupons,
      _getGuestCategories,
      _cleanUnusedQuestCategories
    } = this.props;
    const uuidGuest = localStorage.getItem('bonus_uuid_guest');
    _getOpeningTime();
    _getLastedNews();
    _getGuestCoupons();
    _getGuestCategories();
    _cleanUnusedQuestCategories();
    // _checkRefresh(hasVisited);
  }

  render() {
    const { news, openTime, coupons, categories } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 1,
      arrows: false
    };
    const { de_de } = lang[1];
    return (
      <div className="main-page">
        <Header to="" title={de_de.header_top} />
        <div className="main-screen">
          <div className="guest-container">
            <div className="guest-screen">
              <div className="section-1">
                <h1>{de_de.gretting}</h1>
                {openTime.status === 'open' ?
                  <p>{de_de.opentime_first_text} <strong>{openTime.timeFrom} </strong> {de_de.opentime_mid_text} <strong>{openTime.timeTo}
                    </strong> {de_de.opentime_last_text}
                  </p>
                  :
                  <p>{de_de.greeting_close}</p>
                }
                <Button
                  variant="contained"
                  className="btn-sign-in"
                  component={Link}
                  to="/register"
                >
                  {de_de.title_btn}
                </Button>
                <Button
                  variant="contained"
                  className="btn-sign-up"
                  component={Link}
                  to="/login-phone"
                >
                  {de_de.intro2_login_btn}
                </Button>
              </div>
              <div className="section-2">
                <Carousel
                  title={de_de.voucher_header}
                  settings={settings}
                  data={coupons}
                  orderKey="id"
                  order="desc"
                />
              </div>
              <div className="section-3">
                <News news={news.News} url={news.NewsUrl} />
              </div>
            </div>
          </div>
        </div>
        <BottomNavigation />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  news: state.Guest.dataLastedNews,
  openTime: state.Guest.dataOpeningTime,
  coupons: state.Guest.dataCoupons,
  categories: state.Guest.dataCategories,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getOpeningTime,
    _getLastedNews,
    _getGuestCoupons,
    _getGuestCategories,
    _cleanUnusedQuestCategories
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Guest);
