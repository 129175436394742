/* eslint-disable no-extra-semi */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helper';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import lang from '../../config/languages.json';
import iconAddNew from '../../assets/img/add-new.png';
import iconShareiPhone from '../../assets/img/share-icon-iphone.jpg';
import { _createStringFromTemplate } from '../../lib';
import iconShoppy from '../../assets/img/shoppyapp.png';
import ErrorBoundary from '../ErrorBoundary';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastAuth: false,
      ios: false,
      android: false,
      homescreen: false
    };
    this.isMount = false;
  }

  static getDerivedStateFromProps(nextProps, state) {

    const currentAuth = nextProps.isAuthenticated;
    const lastAuth = state.lastAuth;
    if (currentAuth !== lastAuth) {
      history.push('/user/my-page');
      return {
        lastAuth: currentAuth
      }
    }
    return null;
  }

  componentDidMount() {
    this.isMount = true;
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos() && !isInStandaloneMode()) {
      setTimeout(() => {
        this.setState({ ios: true, hidden: false });
      }, 1000);
    }
    const isAndroid = /(android)/i.test(navigator.userAgent)
    if (isAndroid && !isInStandaloneMode()) {
      setTimeout(() => {
        this.isMount && this.setState({ android: true });
      }, 1000);
    }

  }

  componentWillUnmount() {
    this.isMount = false;
  }

  handleCloseInstallApp = (e) => {
    localStorage.setItem('ahs', true);
  }

  handleCloseInstallAppAndroid = (e) => {
    localStorage.setItem('ahs', true);
    this.setState({
      homescreen: true
    });
  }

  handleAddToHomescreenClick = () => {
    const { de_de } = lang[1];
    alert(`
      ${de_de.add_homescreen_step1}
      ${de_de.add_homescreen_step2}`);
  };

  render() {
    const { classes, isAuthenticated } = this.props;
    const checkA2hs = localStorage.getItem('ahs');
    const { ios, android, homescreen } = this.state;
    const { de_de } = lang[1];
    const user = undefined;
    return (
      <div className="welcome welcome-container">
        <div className="welcome-screen">
          <div className="welcome-header">{de_de.intro1_title}</div>
          <div className="welcome-body">
            <p dangerouslySetInnerHTML={{ __html: de_de.intro1_h1 }} />
            <p>{de_de.intro1_h2}</p>
            <p>
              {de_de.intro1_sub}
            </p>
          </div>
          <Button
            variant="contained"
            className="btn-welcome"
            component={Link}
            to="/home"
          >
            {de_de.intro1_btn}
          </Button>
        </div>
        {/* {
          !isAuthenticated && ios && !checkA2hs && (
            <div className="add-to-home-screen-ios arrow_box">
              <div><img src={iconAddNew} alt="" className="icon-add-new" /></div>
              <div dangerouslySetInnerHTML={{ __html: _createStringFromTemplate(de_de.add_homescreen_text_ios, { iconShareiPhone: iconShareiPhone }) }} />
              <i className="button-close" onClick={this.handleCloseInstallApp}></i>
            </div>
          )
        } */}
        {/* {
          !isAuthenticated && android && !checkA2hs && !homescreen && (
            <React.Fragment>
              <div className="add-homescreen-container">
                <div className="button-close" onClick={this.handleCloseInstallAppAndroid}></div>
                <div
                  className="add-homescreen-button"
                  onClick={this.handleAddToHomescreenClick}
                >
                  <img src={iconShoppy} alt="" />
                  {de_de.add_homescreen_title}

                </div>

              </div>
            </React.Fragment>
          )
        } */}
      </div>
    )
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isFetching: state.profile.isFetching
});

export default connect(mapStateToProps)(Welcome);