/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { history } from '../../helper';
import Slider from "react-slick";
import Item from './Item';
import Preloader from '../Preloader';
import { _sortData, _unixTimestamp, _dataInfoTracking } from '../../lib';
import { _deleteCoupon, _getCouponList, _getCategories } from '../../actions';
import DialogConfirmDeletePage from '../../components/DialogConfirmDeleteCouponPage';
import lang from '../../config/languages.json';
import { errorCodeConstants } from '../../config';

class Carousel extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isExpired: false,
      open: false,
      id: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isExpired !== this.state.isExpired) {
      _getCouponList();
    }
  }

  componentDidMount() {
    const { isAuthenticated, _getCouponList } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getCouponList();
  }

   handleDeleteCoupon = async(id, event) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const isChecked = localStorage.getItem("cp") ? true : false;
    event.preventDefault();
    const { _getCouponList, _deleteCoupon } = this.props;
    if (isChecked) {
      await _deleteCoupon(id);
      await _getCouponList();
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_coupon, errorCodeConstants.couponDelete);
    }
    this.setState({
      open: true,
      id
    })
  }

   handleClickConfirm = async (id) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _getCouponList, _deleteCoupon, _getCategories } = this.props;
    await _deleteCoupon(id);
    await _getCategories();
    await _getCouponList();
    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_coupon, errorCodeConstants.couponDelete);
    this.setState({
      open: false
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  }


  handleExpiration = () =>  {
    this.setState({
      isExpired: true
    });
  }

  render() {
    const isChecked = localStorage.getItem("cp") ? true : false;
    const { isAuthenticated, data, type, view, isFetching, orderKey, order } = this.props;
    const { isExpired, open, id } = this.state;
    const setting = {
      dots: this.props.settings.dots,
      infinite: this.props.settings.infinite,
      speed: this.props.settings.speed,
      slidesToShow: this.props.settings.slidesToShow,
      slidesToScroll: this.props.settings.slidesToScroll,
      initialSlide: this.props.settings.initialSlide,
      rows: this.props.settings.rows,
      arrows: this.props.settings.arrows,
    };

    const isAuth = isAuthenticated ? 'user' : 'public';
    const titleCarousel = this.props.hasOwnProperty('title') === true ? <h1>{this.props.title}</h1> : '';
    if (isFetching)
      return <Preloader />
    return (
      <div className={`carousel-container ${isAuth}`}>
        {titleCarousel}
        <div className="carousel-screen">
          <Slider {...setting}>
            {
              data && _sortData(data, orderKey, order).map((value, index) => {
                const couponPublic = value.public;
                const viewCoupon = value.view;
                const { redemption } = value;
                const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
                const typeOfCoupon = (minuteMount && !isExpired) || redemption === null ? 'gold' : 'disabled';
                const couponDefault = (minuteMount && !isExpired) || redemption === null ? '' : 'disabled';
                // if (redemption === null || (redemption && minuteMount > 0)) {
                if (!couponPublic) {
                  return (
                    <Item
                      key={index}
                      data={value}
                      redemption={redemption}
                      type={typeOfCoupon}
                      expiration={minuteMount}
                      handleDeleteCoupon={this.handleDeleteCoupon}
                      isAuthenticated={isAuthenticated}
                      handleExpiration={this.handleExpiration}
                      isExpired={isExpired}
                    />
                  )
                } else if (view) {
                  return (
                    <Item
                      key={index}
                      data={value}
                      redemption={redemption}
                      type={typeOfCoupon}
                      expiration={minuteMount}
                      handleDeleteCoupon={this.handleDeleteCoupon}
                      isAuthenticated={isAuthenticated}
                      handleExpiration={this.handleExpiration}
                      isExpired={isExpired}
                    />
                  )
                }
                return (
                  <Item
                    key={index}
                    data={value}
                    redemption={redemption}
                    type={couponDefault}
                    expiration={minuteMount}
                    handleDeleteCoupon={this.handleDeleteCoupon}
                    isAuthenticated={isAuthenticated}
                    handleExpiration={this.handleExpiration}
                    isExpired={isExpired}
                  />
                )
                // }
              })
            }
          </Slider>
          {
            open && !isChecked && (<DialogConfirmDeletePage
              open={open}
              handleConfirm={this.handleClickConfirm}
              handleClose={this.handleClose}
              id={id}
            />)
          }
        </div>

      </div>
    );
  }

}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isFetching: state.coupon.isFetching,
  email: state.profile.data.data
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _deleteCoupon,
    _getCouponList,
    _getCategories
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carousel));
