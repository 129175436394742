export const methodConstants = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const TIME_EXPIRED = {
  type: 'hour', // day, hour or minute
  expire: 1     // for expiration
};

const code = process.env.NODE_ENV === 'development' ? 'vn' : 'ch';
export const settingContants = {
  kmFromMall: 3,
  countryCode: code
};

export const errorCodeConstants = {
  loginByOTP: {
    tag: 'Login', 
    code: '1000'
  },
  loginByEmail: { 
    tag: 'Login', 
    code: '1010'
  },
  loginByEmailNotFound: { 
    tag: 'Login', 
    code: '1020'
  },
  loginByPhoneNotFound: { 
    tag: 'Login', 
    code: '1030'
  },
  loginByEmailFail: { 
    tag: 'Login', 
    code: '1040'
  },
  loginByOTPFail: {
    tag: 'Login', 
    code: '1050'
  },
  registerSuccess: {
    tag: 'Registration', 
    code: '2000'
  },
  registerFailPhoneExist: {
    tag: 'Registration', 
    code: '2010'
  },
  registerFailEmailExist: {
    tag: 'Registration', 
    code: '2020'
  },
  registerFailEmailPhoneExist: {
    tag: 'Registration', 
    code: '2030'
  },
  registerFailOTPWrong: {
    tag: 'Registration', 
    code: '2040'
  },
  checkinSuccessul: {
    tag: 'Check-in', 
    code: '3000'
  },
  checkinFailOutOfRang: {
    tag: 'Check-in', 
    code: '3010'
  },
  checkinFailOutOfOpeningTime: {
    tag: 'Check-in', 
    code: '3020'
  },
  checkinSucessPlusLevelUp: {
    tag: 'Check-in', 
    code: '3030'
  },
  checkinSucessPlusBirthday: {
    tag: 'Check-in', 
    code: '3040'
  },
  checkinScanned: {
    tag: 'Check-in', 
    code: '3050'
  },
  locationDeactivate: {
    tag: 'Location', 
    code: '0000'
  },
  voucherViewed: {
    tag: 'Voucher', 
    code: '4000'
  },
  voucherCancel: {
    tag: 'Voucher', 
    code: '4010'
  },
  voucherRedeem: {
    tag: 'Voucher', 
    code: '4020'
  },
  voucherDelete: {
    tag: 'Voucher', 
    code: '4030'
  },
  couponViewed: {
    tag: 'Coupon', 
    code: '5000'
  },
  couponCancel: {
    tag: 'Coupon', 
    code: '5010'
  },
  couponRedeem: {
    tag: 'Coupon', 
    code: '5020'
  },
  couponDelete: {
    tag: 'Coupon', 
    code: '5030'
  },
  profileUpdate: {
    tag: 'Profile', 
    code: '6000'
  },
  profileImageUpload: {
    tag: 'Profile', 
    code: '6010'
  },
  listCreate: {
    tag: 'Shopping list', 
    code: '7000'
  },
  listView: {
    tag: 'Shopping list', 
    code: '7010'
  },
  listDelete: {
    tag: 'Shopping list', 
    code: '7020'
  },
  entryAdd: {
    tag: 'Shopping list', 
    code: '7030'
  },
  entryCheck: {
    tag: 'Shopping list', 
    code: '7040'
  },
  entryUncheck: {
    tag: 'Shopping list', 
    code: '7050'
  },
  entryDelete: {
    tag: 'Shopping list', 
    code: '7060'
  },
}

