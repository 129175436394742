/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../shared/back-button';
import ListItem from './ListItem';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';

class ListPage extends Component {

  componentDidMount(){
    TagManager.dataLayer(tagManagerArgs);
  }

  render() {
    const { de_de } = lang[1];
    return (
      <div className="main-page">
        <Header to="" title={de_de.shopping} />
        <div className="main-screen">
          <div className="list-container">
            <ListItem 
              orderKey="id"
              order="desc"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ListPage);