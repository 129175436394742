/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../shared/back-button';
import ListItem from './ListItem';
import { _getMenuLists } from '../../actions';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';

class Points extends Component {
  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    this.props._getMenuLists();
  }
  
  render() {
    const { data } = this.props;
    const { de_de } = lang[1];
    return (
      <div className="main-page">
        <Header to="" title={de_de.more} />
        <div className="main-screen">
          <div className="more-container">
            <div className="more-screen">
             <ListItem data={data} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  error: state.MenuList.error,
  data: state.MenuList.data
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getMenuLists
  }, dispatch)
);

const PointsConnect = connect(mapStateToProps, mapDispatchToProps)(Points);

export default withRouter(PointsConnect);
