/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Header from '../../shared/back-button';
import CouponItem from './Item';
import GetPointRegular from '../GetPointRegular';
import lang from '../../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../../index';
class CouponScreen extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      showScreen: null
    };

  }

  componentDidMount(){
    TagManager.dataLayer(tagManagerArgs);
  }

  handleClick = (event) => {
    this.setState({
      showScreen: event
    });
  }


  render() {
    const { showScreen } = this.state;
    const { de_de } = lang[1];
    return (
      <div className="main-page">
        <Header to="" title="Punkte" />
        <div className="main-screen">
          <div className="coupon-regular-screen">
            <div className="coupon-regular-gift-bg">
            {(() => {
                switch(showScreen) {
                  case 'get-point':
                    return <GetPointRegular />;
                  default:
                    return <CouponItem
                            confirmed={false}
                            type="green"
                            expire={de_de.kaffee_years}
                          />;
                }
            })()}
              
            </div>
            {showScreen === null && <Button
              variant="contained"
              className="btn-points"
              onClick={this.handleClick.bind(this, 'get-point')}
            >
              {de_de.save_and_continue}
            </Button>}
          </div>
        </div>
      </div>
    );
  }
}

export default CouponScreen;