import {
  alertConstants
} from '../config';

const INITIALSTATE = {
  data: [],
  isFetching: false,
  error: false
};

export default (state = INITIALSTATE, action) => {
  switch (action.type) {
    case alertConstants.GET_CATEGORIES_DATA:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.GET_CATEGORIES_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false
      };
    case alertConstants.GET_CATEGORIES_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
