import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Fab } from '@material-ui/core';
import { isValidDate } from '../../lib';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';

const fields = ['day', 'month', 'year'];

const validate = values => {
  const errors = {};
  if(values.day > 31 || values.day < 1) {
    errors.day = 'Invalid day';
  } else if (values.day && values.day.toString().length === 3) {
    errors.day = 'Invalid day';
  } else if (!values.day) {
    errors.day = 'Required';
  }

  if(values.month > 12 || values.day < 1) {
    errors.month = 'Invalid month';
  } else if (values.month && values.month.toString().length === 3) {
    errors.month = 'Invalid month';
  } else if (!values.month) {
    errors.month = 'Required';
  }

  if(values.year < 1900) {
    errors.year = 'Invalid year';
  } else if (!values.year) {
    errors.year = 'Required';
  }
  
  const month = (values.month === undefined) ? 13 : values.month;
  const day = (values.day === undefined) ? 32 : values.day;
  const year = (values.year === undefined) ? 0 : values.year;
  const date = String(month + '/' + day + '/' + year);
  if (isValidDate(date) === 'bad_day' && values.day && values.month && values.year) {
    errors.year = 'Invalid year';
    errors.month = 'Invalid month';
    errors.day = 'Invalid day';
  }
  // if (Object.entries(errors).length !== 0 && errors.constructor === Object) {
  // }
  
  return errors;
};

const renderField = ({
  input,
  type,
  placeholder,
  meta: { touched, error },
  className,
  checkDay
}) => {
    return (<input
      {...input}
      placeholder={placeholder}
      type={type}
      onKeyUp={checkDay}
      onClick={checkDay}
      className={(touched && error) ? `${className} error` :  `${className}`}
    />)
};

class WizardBirthdayForm extends Component {

  checkDay = (e) => {
    const thisValue = e.target.value;
    const thisName = e.target.name;
    const thisYear = new Date().getFullYear();

    // year validate
    if(thisValue > 31 && thisName === "day") {
      e.target.value = '';
    }
    else if((thisValue < 0 || thisValue === 1 || thisValue === '') && thisName === "day") {
      e.target.value = '';
    }
    // month validate
    if(thisValue > 12 && thisName === "month") {
      e.target.value = '';
    }
    else if((thisValue < 0  || thisValue === 1 || thisValue === '')&& thisName === "month") {
      e.target.value = '';
    }
    // year validate
    if(thisValue > thisYear && thisName === "year") {
      e.target.value = '';
    }
    else if((thisValue < 1 || thisValue === 1)&& thisName === "year") {
      e.target.value = '';
    }
  }

  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isSubmitBirthDayError,
      isFetching
    } = this.props;
    const { de_de } = lang[1];
    const disable = isFetching ? true : false;
    return (
      <form onSubmit={handleSubmit}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            anmeldung
          </Fab>
        </div>
        <div className="section-content">
          <h2>Dein Geburtsdatum</h2>
          <p>Bitte gib dein Geburtsdatum ein damit wir dein Profil verifizieren können.</p>
          <div className="input-fields login-verify">
            <Field 
              name="day"
              type="number"
              placeholder="TT"
              component={renderField}
              label="Username"
              className="birthday-input"
              checkDay={this.checkDay}
            />
            <Field 
              name="month"
              type="number"
              placeholder="MM"
              component={renderField}
              label="Username"
              className="birthday-input"
              checkDay={this.checkDay}
            />
            <Field 
              name="year"
              type="number"
              placeholder="JJJJ"
              component={renderField}
              label="Username"
              className="birthday-input"
              checkDay={this.checkDay}
            />
            {isSubmitBirthDayError && 
              (<div>
                <span>{de_de.error_birthday_form}</span>
              </div>)
            }
           
          </div>
          <div className="submits submit-email">
            <button className="account-submit" type="submit" disabled={disable}>
              Geburtsdatum bestätigen
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  email: state.form.loginEmail.values.email,
});
 
export default reduxForm({
  form: 'loginEmail',
  fields,
  destroyOnUnmount: false,
  validate
})(connect(mapStateToProps)(WizardBirthdayForm));
