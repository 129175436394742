import * as Sentry from "@sentry/browser";

export const _dataInfoTracking = (id, type, message, errorCode) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ id });
    scope.setTag("page_locale", navigator.language);
    scope.setTag("tag_name", errorCode.tag);
    scope.setLevel(type);
  });
  Sentry.captureMessage('[ FE ]-[ ' + errorCode.code + ' ]-[ ' + message + ' ]', type);
}

export const _errorInfoTracking = (id, type, message, error) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ id });
    scope.setTag("page_locale", navigator.language);
    Sentry.captureException(error);
    scope.setLevel(type);
  });
  Sentry.captureMessage('[ ' + id + ' ] *** ' + message, type);
};