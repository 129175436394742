/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { formValueSelector, reset } from 'redux-form';
import WizardEmailForm from './wizard-email-form';
import WizardBirthday from './wizard-birthday-form';
import WizardPostCodeForm from './wizard-postcode-form';
import lang from '../../config/languages.json';
import { _dataInfoTracking } from '../../lib';
import { loginEmail, checkEmailOrPostCode, getError, getErrorEmail } from '../../actions/login';
import { getEmail, checkEmailPhoneErr } from '../../actions';
import { errorCodeConstants } from '../../config';

const formSelector = formValueSelector('loginEmail');

class LoginEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isSubmitBirthDayError: false,
      isEmailPage: false,
      isEmailRegistered: true
    };
    this.handNextPage = this.handNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handlePreviousPageFromEmail = this.handlePreviousPageFromEmail.bind(this);
    this.handleLoginBirthday = this.handleLoginBirthday.bind(this);
    this.handleLoginPostCode = this.handleLoginPostCode.bind(this);
  }

  handNextPage() {
    const { de_de } = lang[1];
    const { checkEmailOrPostCode, getEmail, email } = this.props;
    return getEmail({ email }).then(() => {
      if (!this.props.checkEmailExistError) {
        if (this.props.isEmailExist.exists) {
          return checkEmailOrPostCode({ email }).then(() => {

            if (!this.props.checkEmailError) {
              if (this.props.checEmailResult.birthday_exists) {
                this.setState({
                  isEmailPage: true,
                  page: this.state.page + 1,
                  isEmailRegistered: true
                });
              } else if (this.props.checEmailResult.postcode_exists) {
                this.setState({
                  isEmailPage: false,
                  page: this.state.page + 1,
                  isEmailRegistered: true
                });
              }
            }
          });
        } else {
          _dataInfoTracking(email, de_de.sentry_warning, de_de.sentry_login_email_fail, errorCodeConstants.loginByEmailNotFound)
           this.setState({ isEmailRegistered: false });
        }
      }
    });
  }

  handlePreviousPage() {
    this.setState({ page: this.state.page - 1, isSubmitBirthDayError: false });
  }

  handlePreviousPageFromEmail() {
    this.props.reset("loginEmail");
    this.props.reset("loginPhone");
    this.props.history.goBack();
    // this.props.history.push("/home");
  }

  handleLoginBirthday() {
    const { de_de } = lang[1];
    const {
      loginEmail,
      email,
      day,
      month,
      year,
      reset
    } = this.props;

    const dayFormat = (day < 10 && day.toString().length === 1) ? `0${day}` : day;
    const monthFormat = (month < 10 && month.toString().length === 1) ? `0${month}` : month;
    const birthday = `${dayFormat}.${monthFormat}.${year}`;
    // const birthday = `${day}.${month}.${year}`;
    const user = { email, birthday };
    loginEmail(user).then(() => {
      const error = this.props.loginError;
      if (error) {
        _dataInfoTracking(email, de_de.sentry_error, de_de.sentry_login_email_bitrthday_fail, errorCodeConstants.loginByEmailFail);
        this.setState({ isSubmitBirthDayError: true });
      } else {
        _dataInfoTracking(email, de_de.sentry_info, de_de.sentry_login_email_success, errorCodeConstants.loginByEmail);
        this.setState({ isSubmitBirthDayError: false });
        this.props.history.push('/user/my-page');
        reset("loginEmail");
      }
    });
  }

  handleLoginPostCode(postcode) {
    const { de_de } = lang[1];
    const {
      email,
      loginEmail,
    } = this.props;
    const user = { email, postcode };

    return loginEmail(user, true).then(() => {
      const error = this.props.loginError;
      if (!error) {
        _dataInfoTracking(email, de_de.sentry_info, de_de.sentry_login_success, errorCodeConstants.loginSuccess);
        this.setState({ isSubmitBirthDayError: false });
        this.props.history.push('/user/my-page');
        reset("loginEmail");
      } else {
        _dataInfoTracking(email, de_de.sentry_error, de_de.error_code_number, errorCodeConstants.passcodeFail);
        this.setState({ isSubmitBirthDayError: true });
      }
    });
  }

  render() {
    const { page, isSubmitBirthDayError, isEmailPage, isEmailRegistered } = this.state;
    const { isFetching } = this.props;
    return (
      <div className="login-page">
        {page === 1 &&
          <WizardEmailForm
            isEmailRegistered={isEmailRegistered}
            onSubmit={this.handNextPage}
            handlePreviousPage={this.handlePreviousPageFromEmail} />
        }
        {page === 2 &&
          (isEmailPage ?
            <WizardBirthday
              onSubmit={this.handleLoginBirthday}
              handlePreviousPage={this.handlePreviousPage}
              isSubmitBirthDayError={isSubmitBirthDayError}
              isFetching={isFetching}
            /> :
            <WizardPostCodeForm
              handlePreviousPage={this.handlePreviousPage}
              handleLoginPostCode={this.handleLoginPostCode}
              isSubmitBirthDayError={isSubmitBirthDayError}
            />)
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  email: formSelector(state, 'email'),
  day: formSelector(state, 'day'),
  month: formSelector(state, 'month'),
  year: formSelector(state, 'year'),
  loginError: getError(state.LoginEmail),
  isEmailExist: state.Register.dataEmail.data,
  checkEmailError: getErrorEmail(state.LoginEmail, 'errorCheckEmail'),
  checkEmailExistError: checkEmailPhoneErr(state.Register, 'emailError'),
  checEmailResult: state.LoginEmail.dataCheckEmailPostcode.data,
  isFetching: state.LoginEmail.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loginEmail, checkEmailOrPostCode, reset, getEmail }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmail);
