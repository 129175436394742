/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { history } from '../../../helper';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { _getShoppingList } from '../../../actions';
import Preloader from '../../Preloader';
import { _sortData, _getTime } from '../../../lib';
import lang from '../../../config/languages.json';


class ListPage extends Component {

  async componentDidMount() {
    const { isAuthenticated, _getShoppingList } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    await _getShoppingList();
  }

  render() {
    const { classes, isFetching, isShoppingList, orderKey, order, location } = this.props;
    const { data } = isShoppingList;
    const { de_de } = lang[1];
   
    return (
      <React.Fragment>

        {
          isFetching ? <Preloader />
            : (
              <div className="list-screen">
                <List component="nav" className={classes.root}>
                  {
                    _sortData(data, orderKey, order).map((item) => {
                      const { id, name, created_at, updated_at } = item;
                      return (
                        <ListItem key={id} button divider className={classes.dimension} component={Link} to={{
                            pathname: `/shopping/list-added/${btoa(`${id}/${name}/${created_at}`)}`,
                          }} >
                          <ListItemText primary={name} secondary={_getTime(created_at).date} className="list-item-title" />
                          <ArrowForwardIos className={classes.icon} />
                        </ListItem>
                      )
                    })
                  }
                  <Button
                    size="small"
                    className="btn-delete"
                    component={Link}
                    to="/user/shopping/create-list"
                  >
                    <AddIcon className={classNames(classes.leftIcon, classes.iconSmall, classes.icon)} />
                    {de_de.new_shopping_list}
                </Button>
                </List>

              </div>
            )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isShoppingList: state.shoppingList,
  isFetching: state.shoppingList.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ _getShoppingList }, dispatch)
);

export default withStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: '#C0A35E'
  },
  dimension: {
    height: '66px'
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPage)));
