/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Header from '../shared/back-button';
import lang from '../../config/languages.json';

const Vorteile = (props) => {
  const { de_de } = lang[1];
  return (
    <div className="vorteile-container">
      <Header to="/home" title={ de_de.header_vor } />
      <div className="screen-container">
        <h1>{de_de.title}</h1>
        <h2>{de_de.title_sub}</h2>
         <span dangerouslySetInnerHTML={{__html: de_de.content }} />
        <Button 
          variant="contained" 
          className="btn-vortelie"
          component={Link}
          to="/register"
        >
              {de_de.title_btn}
        </Button>
      </div>
    </div>
  );
}

export default Vorteile;