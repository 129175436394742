/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import IconCropQuare from '@material-ui/icons/CropSquare';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import { _getRedeemVoucher } from '../../actions';
import FormGroup from '@material-ui/core/FormGroup';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import lang from '../../config/languages.json';


const styles = {
  root: {
    color: '#ffffff',
    '&$checked': {
      color: '#ffffff',
    },
  },
}


class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: localStorage.getItem("del")
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, id } = this.props;
    const { checked } = this.state;
    const { de_de } = lang[1];
    checked ? localStorage.setItem("del", 1) : localStorage.removeItem("del");
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={true}
          className="dialog-screen"
          classes={classes}
        >
          <Button
            onClick={this.props.handleClose}
            color="primary" autoFocus
            className="btn-deny"
          >
            <ClearIcon classes={classes} />
          </Button>
          <div className="dialog-bg"></div>
          <DialogTitle id="alert-dialog-title">
            {de_de.delete_shoppin_list_all_items}</DialogTitle>
          <DialogContent>

            {/* <DialogContentText className="alert-dialog-description">
            Mochten Sie
diesen Artikeln wircklich von Ihrem Einkaufszetteln entfernen 

            </DialogContentText>
            <DialogContentText className="alert-dialog-description">
              Wenn Sie das Angebot jezt einlösen können Sie später nicht mehr davon profitieren.
                Das Angebot ist nach der Einlösung während 10 Minuten gültig und läuft danach automatisch ab. Lösen Sie das Angebot
                daher erst dann ein, wenn Sie sich an
  der Kasse befinden.

            </DialogContentText> */}
            <DialogContentText className="alert-dialog-description">
              <Button
                onClick={this.props.handleConfirm.bind(this, id)}
                color="primary"
                className="btn-sign-in"
              >
                {de_de.delete_article}
            </Button>
              {/* <Button
                onClick={this.props.handleClose}
                color="primary" autoFocus
                className="btn-deny"
              >
                > Angebot später einlösen
            </Button> */}

            </DialogContentText>
          </DialogContent>
          <DialogActions className="action-dialog">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checked}
                    onChange={this.handleChange('checked')}
                    value="checked"
                    color="primary"
                    classes={classes}
                  />
                }
                label={de_de.hide_msg}
              />
            </FormGroup>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function CheckedIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.05152L8.92235 14.8571L22 2" stroke="#C0A35E" strokeWidth="4" />
    </svg>

  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getRedeemVoucher
  }, dispatch)
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AlertDialog));
