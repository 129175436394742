import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Header from '../shared/back-button';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';

class MorePoints extends Component {

  componentDidMount(){
    TagManager.dataLayer(tagManagerArgs);
  }

  render() {
    const { de_de } = lang[1];
    return (
      <div className="morepoints-container">
        <Header to="/user/profile" title={de_de.collect_point} />
        <div className="screen-container">
          <h2>{de_de.soyoucan}</h2>
          <h1>{de_de.collect_point}</h1>
          <ul>
            <li>
              <div className="item-prefix">
                <span>1</span>
              </div>
              <strong>{de_de.checkin_text}</strong>
              <p dangerouslySetInnerHTML={{ __html: de_de.collect_content }}/>
            </li>
            <li>
              <div className="item-prefix">
                <span>5</span>
              </div>
              <strong>{de_de.checkin_text_geburtstag}</strong>
              <p dangerouslySetInnerHTML={{ __html: de_de.collect_content_geburtstag }} />
            </li>
            <li>
              <div className="item-prefix ind">
                <span>IND</span>
              </div>
              <strong>{de_de.collect_wettbewerben}</strong>
              <p dangerouslySetInnerHTML={{ __html: de_de.collect_teilnahme }}/>
            </li>
            <li>
              <div className="item-prefix ind">
                <span>IND</span>
              </div>
              <strong>{de_de.collect_kundenumfragen}</strong>
              <p dangerouslySetInnerHTML={{__html: de_de.collect_content_kundenumfragen }} />
            </li>
          </ul>
          <Button
            variant="contained"
            className="btn-more-points"
            component={Link}
            to="/user/profile"
          >
            {de_de.collect_point_ok_button}
          </Button>
        </div>
      </div>
    );
  }
}

export default MorePoints;
