import React from 'react';
import { Link } from 'react-router-dom';
import lang from '../../config/languages.json';

const { de_de } = lang[1];
export const NextVoucher = (props) => (
  <div className="next-voucher-container">
    <h1>Nächster Gutschein</h1>
    <div className="next-voucher-screen">
      <div className="saler">
        <h1>{ props.isNextVoucher}</h1>
        <hr />
      </div>
      <div className="progress">
        <div className="progress-content">
          <div className="number-slider">
            <span style={{ left: `${props.value - 10}%` }}>
              {/* {(props.value === 0 || props.value === 100) ? '' : props.value} */}
              {props.value}
            </span>
          </div>
          <div className="ruler">
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm">
            </div>
            <div className="cm"></div>
          </div>
          <div className="input-progress">
            <span style={{ width: `${props.value}%` }}></span>
            <span className="slider"></span>
          </div>
          <p>Noch {100 - (props.value)} {de_de.point}</p>
        </div>
        <hr />
      </div>
      <div className="saler">
        <Link to="/more-points">
          <h6 className="">{de_de.earn_point}</h6>
        </Link>
      </div>
    </div>
  </div>
);


