import React from 'react';

export const IconPhoto = (props) => (
  <svg className={props.className} width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0351 0H2.00221C0.896781 0 0 0.892912 0 1.99357V17.9811C0 19.0827 0.896781 19.9757 2.00221 19.9757H10.0341C11.1405 19.9757 12.0373 19.0827 12.0373 17.9821V1.99357C12.0373 0.892912 11.1405 0 10.0351 0ZM6.01866 18.9769C5.32652 18.9769 4.76477 18.5304 4.76477 17.9781C4.76477 17.4258 5.32652 16.9793 6.01866 16.9793C6.71081 16.9793 7.27255 17.4258 7.27255 17.9781C7.27255 18.5304 6.71081 18.9769 6.01866 18.9769ZM10.0311 15.9805H2.00622V1.99757H10.0311V15.9805Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.97237 10.7378H7.15754V7.46455H8.79642L6.06496 4.73682L3.3335 7.46455H4.97237V10.7378Z" fill="white"/>
    <rect x="3.3335" y="11.7544" width="5.63036" height="1.54865" fill="white"/>
    </svg>
);

export const IconCamera = (props) => (
  <svg className={props.className} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 5.96363C8.343 5.96363 7 7.29948 7 8.94544C7 10.5914 8.343 11.9273 10 11.9273C11.656 11.9273 13 10.5914 13 8.94544C13 7.29948 11.656 5.96363 10 5.96363ZM18 2.98181H15.6C15.27 2.98181 14.914 2.72737 14.811 2.41527L14.19 0.565551C14.085 0.254448 13.731 0 13.4 0H6.6C6.27 0 5.914 0.254448 5.811 0.564557L5.189 2.41527C5.085 2.72737 4.73 2.98181 4.4 2.98181H2C0.9 2.98181 0 3.87636 0 4.96969V13.9151C0 15.0085 0.9 15.903 2 15.903H18C19.1 15.903 20 15.0085 20 13.9151V4.96969C20 3.87636 19.1 2.98181 18 2.98181ZM10 13.9151C7.238 13.9151 5 11.6907 5 8.94544C5 6.20018 7.238 3.97575 10 3.97575C12.761 3.97575 15 6.20018 15 8.94544C15 11.6907 12.761 13.9151 10 13.9151ZM17.5 6.16242C17.113 6.16242 16.8 5.85032 16.8 5.46566C16.8 5.083 17.113 4.76991 17.5 4.76991C17.887 4.76991 18.2 5.08201 18.2 5.46566C18.2 5.85032 17.887 6.16242 17.5 6.16242Z" fill="white"/>
    </svg>
);

