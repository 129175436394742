/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helper';
import Coins from '../../../assets/img/coin-img.svg';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Header from '../../shared/back-button';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../../index';
class GetPointRegular extends Component {

  componentDidMount() {
    const { isAuthenticated } = this.props;
    TagManager.dataLayer(tagManagerArgs);
    if (!isAuthenticated) {
      history.push('/login');
    }

    return false;
  }

  render() {
    const { total_point } = this.props;
    return (
      <div className="get-point-screen-outside-denied">
        <Header to="" title="Punkte" />
        <div className="get-point-gift-bg">
          <h3>ACHTUNG</h3>
          <h6>Bitte aktivieren Sie die Ortungsfunktion ihres Smartphones um Punkte sammeln zu können.</h6>
          <Button
            variant="contained"
            className="btn-back"
            component={Link}
            to="/user/my-page"
          >
            weiter
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated
});

export default connect(mapStateToProps)(GetPointRegular);