/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Fab } from '@material-ui/core';
import ReactCodeInput from '../shared/verification';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';
import { _dataInfoTracking } from '../../lib';
import Preloader from '../Preloader';

const { de_de } = lang[1];
const props = {
  inputStyle: {
    fontFamily: 'ITC Avant Garde Gothic Std',
    fontWeight: 'normal',
    width: '60px',
    height: '60px',
    fontSize: '14px',
    MozAppearance: 'textfield',
    backgroundColor: '#f5f5f5',
    color: '#000000',
    border: '0px solid',
    marginLeft: '4px',
    textAlign: 'center'
  },
  placeholders: ['C', 'O', 'D', 'E']
};

class WizardCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };
    this.handSubmitCode = this.handSubmitCode.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
  }

  handSubmitCode(e) {
    e.preventDefault();
    const { code } = this.state;
    const { handleLoginPhone } = this.props;
    return handleLoginPhone(code);
  }

  onChangeCode(value) {
    const { codeOnChange } = this.props;
    this.setState({ code: value });
    codeOnChange(value);
  }

  // componentDidUpdate(prevProps){
  //   const { codeOnChange } = this.props;
  //   if (this.props.isCodeError){
  //     codeOnChange('');
  //   }
  // }

  render() {
    const { handlePreviousPage, handleGetOtherCode, isCodeError, isFetching, isFetchingGetOTPCode, isText, code } = this.props;
    const disable = isFetching ? true : false;
    const classText = isText !== '' ? 'textColor' : '';
    return (
      <form onSubmit={this.handSubmitCode}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            {de_de.intro2_title}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.login_title_verify_phone_code}</h2>
          <p>{de_de.login_verify_mobile_message}</p>
          <div className="input-fields code-input">
            <ReactCodeInput
              type='number'
              fields={4}
              {...props}
              pattern="\d*" 
              onChange={this.onChangeCode}
              //isClearPasscode={isClearPasscode}
              code={code}
            />
            <span className={`error-code ${classText}`}>
              {isCodeError ? de_de.error_code_number : ''}
              {isFetchingGetOTPCode ? <Preloader /> : isText}
            </span>
            <p>{de_de.register_verify_exist_code_question}</p>
            <span
              className="resend-code"
              onClick={handleGetOtherCode}
            >
              {de_de.resend_code}
            </span>
          </div>
          <div className="submits submit-code-input">
            <button className="account-submit" type="submit" disabled={disable}>
              {de_de.login_submit_code}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  phone: state.form.loginPhone.values.tel
});

export default connect(mapStateToProps)(WizardCodeForm);
