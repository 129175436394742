
function compareValues(key, order='asc') {
  return function(a, b) {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === 'string') ?
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ?
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export const _sortData = (data, key, order) => {
  // array is sorted by band, in ascending order by default
  // data.sort(compareValues('id'));

  // array is sorted by band in descending order
  // data.sort(compareValues('band', 'desc'));

  // array is sorted by albums in ascending order
  // data.sort(compareValues('id'));
  const newData = data && data.sort(compareValues(key, order));
  return newData;
};
