import { history } from '../helper';
import { settingContants } from '../config';
// const mall = [
//   ["mall1", 10, 50, "blah"],
//   ["mall2", 10.819050, 106.633229, "blah"],
//   ["mall3", 25, 10, "blah"],
//   ["mall4", 10.824905, 106.630837, "blah"]
// ];

export const _getLocation = () => {
  const geolocation = navigator.geolocation;
  const location = new Promise((resolve, reject) => {
    if (!geolocation) {
      
      reject(new Error('Not Supported'));
    }
    navigator.geolocation.getCurrentPosition(
      browserGeolocationSuccess => {
        resolve(browserGeolocationSuccess);
      },
      browserGeolocationFail,
      { maximumAge: 50000, timeout: 20000, enableHighAccuracy: true });
  });

  return location;
};


// const browserGeolocationSuccess = function(position) {
//   alert("Browser geolocation success!\n\nlat = " + position.coords.latitude + "\nlng = " + position.coords.longitude);
// };

const browserGeolocationFail = (error) => {
  switch (error.code) {
    case 1:
      history.push('/user/denied');
      break;
    case error.TIMEOUT:
      alert("Browser geolocation error !\n\nTimeout.");
      break;
    case error.PERMISSION_DENIED:
      if (error.message.indexOf("Only secure origins are allowed") === 0) {
        alert("Only secure origins are allowed")
      }
      break;
    case error.POSITION_UNAVAILABLE:
      alert("Browser geolocation error !\n\nPosition unavailable.");
      break;
    default:
      break;
  }
};

//10.756028, 106.685266
//10.757946, 106.684681
// const latitude = 10.756028;
// const longitude = 106.685266;

function deg2rad(deg) {
  return deg * Math.PI / 180
}

export const _getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d * settingContants.kmFromMall;
}

// export const _nearestMall = (latitude, longitude) => {
//   let minDif = 99999;
//   // let closest;

//   for (let index = 0; index < mall.length; ++index) {
//     const dif = _getDistance(latitude, longitude, mall[index][1], mall[index][2]);
//     if (dif < minDif) {
//       // closest = index;
//       minDif = dif;
//     }
//   }

//   // echo the nearest mall
//   // console.log(mall[closest]);
// }

// _nearestMall(latitude, longitude);