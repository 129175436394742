/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import Slider from "react-slick";
import Item from './Item';
import { _sortData, _unixTimestamp } from '../../lib';
import { _deleteGuestCoupon, _getGuestCoupons, _cleanUnusedQuestCategories } from '../../actions';

class Carousel extends Component {

  constructor(props){
    super(props);
    this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
  }

   handleDeleteCoupon = async (id, event) => {
    const { _getGuestCoupons, _deleteGuestCoupon, _cleanUnusedQuestCategories } = this.props;
    event.preventDefault();
    await _deleteGuestCoupon(id);
    await _getGuestCoupons();
    await _cleanUnusedQuestCategories();
  }

  render() {
    const { isAuthenticated, data, type, view, orderKey, order } = this.props;
    const setting = {
      dots: this.props.settings.dots,
      infinite: this.props.settings.infinite,
      speed: this.props.settings.speed,
      slidesToShow: this.props.settings.slidesToShow,
      slidesToScroll: this.props.settings.slidesToScroll,
      initialSlide: this.props.settings.initialSlide,
      rows: this.props.settings.rows,
      arrows: this.props.settings.arrows,
    };

    const isAuth = isAuthenticated ? 'user' : 'public';
    const titleCarousel = this.props.hasOwnProperty('title') === true ? <h1>{this.props.title}</h1> : '';

    return (
      <div className={`carousel-container ${isAuth}`}>
        {titleCarousel}
        <div className="carousel-screen">
          <Slider {...setting}>
            {
             
              data && _sortData(data, orderKey, order).slice(0, 3).map((value, index, array) => {
                const couponPublic = value.public;
                const viewCoupon = value.view;
                const { redemption } = value;
                const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
                const typeOfCoupon = minuteMount || redemption === null ? 'gold-guest' : 'disabled';
                const couponDefault = minuteMount || redemption === null ? '' : 'disabled';
                // if (array.length === index + 1) {
                //   if (!couponPublic) {
                //     return (
                //       <Link key={index} to="/public/coupons">
                //         <div
                //           className="btn-all"
                //         >
                //           <div className="carousel-view">
                //             alle angebote<br />
                //             anzeigen
                //           </div>
                //         </div>
                //       </Link>
                //     )
                //   } 
                //   return (
                //     <Link key={index} to="/public/coupons">
                //       <div
                //         className="btn-all"
                //       >
                //         <div className="carousel-view">
                //           alle angebote<br />
                //           anzeigen
                //           </div>
                //       </div>
                //     </Link>
                //   )
                // }
                
                if (couponPublic) {
                  return (
                    <Item
                    key={index}
                    data={value}
                    redemption={redemption}
                    type={typeOfCoupon}
                    expiration={minuteMount}
                    handleDeleteCoupon={this.handleDeleteCoupon}
                    isAuthenticated={isAuthenticated}
                    />
                  )
                } 
                return (
                  null
                )
              })
            }
            <Link key={'show-all'} to={{
                          pathname: "/user/coupons",
                          state: {
                            value: 0
                          }
                        }}>
                        <div
                          className="btn-all"
                        >
                          <div className="carousel-view">
                            alle angebote<br />
                            anzeigen
                          </div>
                        </div>
                      </Link>
          </Slider>
        </div>

      </div>
    );
  }

}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _deleteGuestCoupon,
    _getGuestCoupons,
    _cleanUnusedQuestCategories
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carousel));
