/* eslint-disable no-unused-vars */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const Preloader = ({
  classes,
}) => {
  return (
    <div className="preloader-container">
      <CircularProgress className={classes.progress} size={30} thickness={5} />
    </div>
  );
}

export default withStyles(theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: '#ffffff',
  },
}))(Preloader);