/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { formValueSelector, reset } from 'redux-form';
import { _dataInfoTracking } from '../../lib';
import { history } from '../../helper';
import WizardPhoneForm from './wizard-phone-form';
import WizardCodeForm from './wizard-code-form';
import { loginPhone, getOTPCode, getError } from '../../actions/login';
import { getPhone, checkEmailPhoneErr } from '../../actions';
import lang from '../../config/languages.json';
import { errorCodeConstants } from '../../config';

const formSelector = formValueSelector('loginPhone');

class LoginPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isCodeError: false,
      isPhoneRegistered: true,
      isPhoneTrueFormat: true,
      isClearError: false,
      isText: '',
      isClearPasscode: false,
      code: '',
    };
    this.handNextPage = this.handNextPage.bind(this);
    this.handPreviousPageFromCode = this.handPreviousPageFromCode.bind(this);
    this.handlePreviousPageFromPhone = this.handlePreviousPageFromPhone.bind(this);
    this.handleLoginOTP = this.handleLoginOTP.bind(this);
    this.handleGetOtherCode = this.handleGetOtherCode.bind(this);
    this.codeOnChange = this.codeOnChange.bind(this);
  }

  handNextPage() {
    const { de_de } = lang[1];
    const { phone, getOTPCode, getPhone } = this.props;
    return getPhone({ phone }).then(() => {
      if (!this.props.checkPhoneError) {
        if (this.props.isPhoneExist.exists) {
          return getOTPCode({ phone }).then(() => {
            if (!this.props.getOtpError) {
              this.setState({
                page: this.state.page + 1,
                isPhoneRegistered: true
              });
            }
          });
        } else {
          _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_login_phone_fail, errorCodeConstants.loginByPhoneNotFound);
          this.setState({ isPhoneRegistered: false });
        }
        this.setState({ isPhoneTrueFormat: true });
      } else {
        this.setState({ isPhoneTrueFormat: false });
      }
    });
  }

  handleGetOtherCode(e) {
    const { de_de } = lang[1];
    e.preventDefault();
    const { phone, getOTPCode } = this.props;
    this.setState({
      isClearError: true,
      isCodeError: false,
      isText: de_de.passcode_sent,
      code: ''
      //isClearPasscode: true
    });
    return getOTPCode({ phone });
  }

  handleClearPasscode = () => {
    // this.setState({
    //   isClearPasscode: false
    // });
  }

  handPreviousPageFromCode() {
    this.setState({ page: this.state.page -1, isCodeError: false, isPhoneRegistered: true, isPhoneTrueFormat: true });
  }

  handleLoginOTP(code) {
    const { de_de } = lang[1];
    const {
      phone,
      loginPhone,
      reset
    } = this.props;

    this.setState({
      isText: ''
    });

    return loginPhone({ phone, code }).then(() => {
      if (!this.props.loginError) {
        _dataInfoTracking(phone, de_de.sentry_info, de_de.sentry_login_otp_success, errorCodeConstants.loginByOTP);
        this.setState({ isCodeError: false });
          history.push('/user/my-page');
          reset("loginPhone");
      } else {
        _dataInfoTracking(phone, de_de.sentry_warning, de_de.sentry_login_otp_fail, errorCodeConstants.loginByOTPFail);
        this.setState({ isCodeError: true });
      }
    });
  }

  handlePreviousPageFromPhone() {
    this.props.reset("loginPhone");
    history.goBack();
    // history.push("/home");
  }

  codeOnChange(value){
    this.setState({
      code: value
    })
  }

  render() {
    const { page, isCodeError, isPhoneRegistered, isPhoneTrueFormat, isClearError, isText, code } = this.state;
    const { isFetching, isFetchingGetOTPCode } = this.props;
    return (
      <div className="login-page">
        {page === 1 &&
          <WizardPhoneForm
            onSubmit={this.handNextPage}
            isPhoneRegistered={isPhoneRegistered}
            isPhoneTrueFormat={isPhoneTrueFormat}
            handlePreviousPage={this.handlePreviousPageFromPhone}
          />
        }
        {page === 2 &&
          <WizardCodeForm
            handlePreviousPage={this.handPreviousPageFromCode}
            handleLoginPhone={this.handleLoginOTP}
            handleGetOtherCode={this.handleGetOtherCode}
            isCodeError={isCodeError}
            isFetching={isFetching}
            isFetchingGetOTPCode={isFetchingGetOTPCode}
            isClearError={isClearError}
            isText={isText}
            codeOnChange={this.codeOnChange}
            code={code}
            //isClearPasscode={isClearPasscode}
            //handleClearPasscode={this.handleClearPasscode}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  let phone = formSelector(state, 'tel');
  const loginError = getError(state.LoginPhone);
  const getOtpError = getError(state.GetOTP);
  const isCodeError = state.LoginPhone.loginError;
  const isFetching = state.LoginPhone.isFetching;
  const isAuthenticated = state.LoginPhone.isAuthenticated;
  const checkPhoneError = checkEmailPhoneErr(state.Register, 'phoneError');
  const isPhoneExist = state.Register.dataPhone.data;
  const isFetchingGetOTPCode = state.GetOTP.isFetching;

  phone = phone ? phone.replace(/\s/g, "") : '';
  if (phone && phone.substring(0, 3) === '+41') {
    phone = `0${phone.substring(3)}`;
  }

  return {
    phone,
    loginError,
    getOtpError,
    isCodeError,
    isFetching,
    isAuthenticated,
    checkPhoneError,
    isPhoneExist,
    isFetchingGetOTPCode,
  }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loginPhone, getOTPCode, reset, getPhone }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPhone);
