import Cookies from 'js-cookie';

export const COOKIE_APP_SUGGESTION_NAME = 'bonus_app';

export const COOKIE_APP_SUGGESTION_VALUE = 'true';

export const setCookieForToken = (tokenExpires) => {
  Cookies.set(
    COOKIE_APP_SUGGESTION_NAME,
    COOKIE_APP_SUGGESTION_VALUE,
    { expires: tokenExpires, path: '/' }
  );
}
