import {
  $fetch,
  alertConstants,
  methodConstants,
  endpointContants
} from '../config';

export const _getMenuLists = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_LIST_MENU,
    methodConstants.GET,
    alertConstants.USER_REGISTERED_GET_MENU_LIST,
    alertConstants.USER_REGISTERED_GET_MENU_LIST_SUCCESS,
    alertConstants.USER_REGISTERED_GET_MENU_LIST_FAILURE,
  );
};
