/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Fab, Button } from '@material-ui/core';
import JSBarCode from 'jsbarcode';

import { history } from '../../helper';
import { _logOut } from '../../actions';
import { _getUserProfile } from '../../actions';

import BackIcon from '../shared/back-icon';
import SwithIdIcon from '../shared/shoppy-pass-icons';
import ProfileImg from '../../assets/img/profile.jpg';
import CardBackSide from '../../assets/img/shoppypass-card-back.png';
import lang from '../../config/languages.json';

class ShoppyPass extends Component {
  state = { isToggleOn: true, barCodeImg: '' };

  async componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    await this.props._getUserProfile();

    // const { id } = (this.props.userInfo === undefined) ? '123456789' : this.props.userInfo;
    // JSBarCode("#barcode", id, {
    //   jsbarcodeFormat: "CODE39",
    //   jsbarcodeTextmargin: "0",
    //   jsbarcodeFontoptions: "bold",
    //   width: 1,
    //   height: 35,
    //   displayValue: false,
    //   fontSize: "13px",
    // });

    return null;
  }

  handleBack = () => {
    history.push('/user/my-page');
  }

  handleSignOut = () => {
    const { _logOut } = this.props;
    _logOut();
    history.push('/home');
  }

  handleFlipButton = () => {
    this.setState(function(prevState) {
			return {isToggleOn: !prevState.isToggleOn};
		});
  }
  
  handleEditData = () => {
    history.push('/update-profile');
  }

  handleOpenHeir = (link) => {
    window.open(link, "_blank");
  }

  render() {
    const { isToggleOn } = this.state;

    const { userInfo, error, isFetching } = this.props;
    const tempProfile = { id: '123', firstname: '', lastname: '', birthday: '', photo_url: '' };
    const {
      id, firstname , lastname, birthday, photo_url
    } = (userInfo === undefined || error) ? tempProfile : userInfo;

    const canvas = document.createElement("canvas");
    JSBarCode(canvas, id, {
      jsbarcodeFormat: "EAN",
      width: 1,
      height: 35,
      marginTop: 0,
      marginBottom: 0,
      displayValue: false,
    });
    const barCodeImg = canvas.toDataURL("image/jpeg");
    const { de_de } = lang[1];

    return (
      <div className="shoppy-pass-container">
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={this.handleBack}
          >
            <BackIcon className="svg-back-icon" />
          {de_de.shop_pass}
          </Fab>
        </div>
        <div className="shoppy-content">
          <div className="id-card">
            <Fab
              variant="extended"
              className="btn-switch"
              onClick={this.handleFlipButton}
            >
              <SwithIdIcon />
            </Fab>
            <div className={`flip-box ${isToggleOn ? '': 'flip'}`}>
              <div className="flip-box-inner">
                <div className="flip-box-front">
                 
                  <div className="image">
                  <Link to="/upload-profile">
                    <img
                      src={(photo_url === '' || photo_url === null) ? ProfileImg : photo_url}
                      alt=""
                    />
                  </Link>
                  </div>
                  <div className="front-content">
                    {/* <div className="info"> */}
                      <div className="personal">
                        <p>{firstname}</p>
                        <p>{lastname}</p>
                        <p>{birthday}</p>
                        <p>ID {id}</p>
                      </div>
                      <div className="shop">
                        <strong> {de_de.shop_pass_year}</strong>
                        <p>{de_de.shop_pass_site}</p>
                        <p>{de_de.shop_pass_facebook}</p>
                      </div>
                    {/* </div> */}
                  </div>
                  <div className="front-barcode">
                    {/* <svg id="barcode" className={isToggleOn ? '' : 'hide-svg'}></svg> */}
                    <img src={barCodeImg} alt="" />
                  </div>
                </div>
                <div className="flip-box-back">
                  <img src={CardBackSide} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-button">
            <Button
              variant="contained"
              className="btn-shoppy-card first-sp"
              onClick={this.handleEditData}
            >
{de_de.edit_data}
            </Button>
            <Button
              variant="contained"
              className="btn-shoppy-card second-sp"
              onClick={this.handleSignOut}
            >
              {de_de.signout}
            </Button>
          </div>
          <div className="message">
            <p>
                {de_de.shopy_pas_message}
              <span
                onClick={() => this.handleOpenHeir(`http://newsletter.migros-aare.ch/dispatcher/service?ac=mpr&m=12171129054553143355&si=${id}`)}
              >
                  {de_de.shopy_pas_message_hier}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  error: state.profile.error,
  userInfo: state.profile.data.data,
  isFetching: state.profile.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ _logOut, _getUserProfile }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ShoppyPass);
