/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Fab } from '@material-ui/core';
import NumericInput from 'react-numeric-input';
import { withStyles } from '@material-ui/core/styles';
import validate from './form-validation';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

const fields = ['agreed', 'gender', 'firstname', 'lastname', 'day', 'month', 'year'];
const { de_de } = lang[1];
const renderField = ({
  input,
  type,
  label,
  id,
  list,
  meta: { touched, error },
  checkDay,
  className
}) => (
    <React.Fragment>
      <input
        {...input}
        placeholder={label}
        type={type}
        id={id}
        list={list}
        onKeyUp={checkDay}
        onClick={checkDay}
        className={(touched && error) ? `${className} error` : `${className}`}
      />
      <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
  );
// const renderFieldDays = ({
//   input,
//   className,
//   label,
//   meta: { touched, error },
//   name
// }) => (
//     <NumericInput
//       name={name}
//       mobile={false}
//       className={(touched && error) ? `${className} error` : `${className}`}
//       style={{}}
//       placeholder={label}
//       {...input}
//     />
//   );

const renderBirthDay = ({
  input,
  type,
  label,
  id,
  list,
  meta: { touched, error },
  checkDay,
  className,
  min
}) => (
    <React.Fragment>
      <div className="render-birthday-input"><input
        {...input}
        placeholder={label}
        type={type}
        id={id}
        list={list}
        onKeyUp={checkDay}
        min={min}
        onClick={checkDay}
        className={(touched && error) ? `${className} error` : `${className}`}
      />
        <span>{(touched && error) ? `${error}` : ''}</span>
      </div>

    </React.Fragment>
  );

const renderGender = ({
  input,
  className,
  meta: { touched, error },
  onChange,
  isGenderDefault
}) => (
    <React.Fragment>
      <select
        id="gender"
        className={(touched && error)
          ? `${className} error gender-default`
          : (isGenderDefault ? `${className} gender-default` : `${className}`)
        }
        onChange={onChange}
        {...input}
      >
        <option value="" disabled defaultValue>{de_de.register_placeholder_gender}</option>
        <option value='Frau'>Frau</option>
        <option value='Herr'>Herr</option>
      </select>
      <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
  );

const RenderCheckbox = (fields) => {
  const {
    input,
    type,
    label,
    meta: { touched, error },
    classes,
    name,
    className
  } = fields;
  return (
    <React.Fragment>
      <Checkbox
        {...input}
        name={name}
        checked={input.checked ? true : false}
        value=""
        classes={classes}
        className={(touched && error) ? 'error' : ''}
      />
      <label dangerouslySetInnerHTML={{ __html: label }} />
      {/* {touched && error && (<FormHelperText className="error-message-checkbox">{de_de.error_message_agree}</FormHelperText>)} */}
    </React.Fragment>
  )
}

const normalizeFirstLastName = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 40) {
    return value;
  }
}


class WizardUserName extends Component {
  constructor(props) {
    super(props);
    this.checkBirthDayField = this.checkBirthDayField.bind(this);
    this.state = {
      isGenderDefault: true,
    }
  }

  checkBirthDayField(e, type) {
    const thisValue = e.target.value;
    const thisName = e.target.name;
    const thisYear = new Date().getFullYear();

    // year validate
    if (type === "day") {


      if (thisValue > 31 && thisName === "day") {
        e.target.value = '';
      }
      else if ((thisValue < 0 || thisValue === 1 || thisValue === '') && thisName === "day") {
        e.target.value = '';
      }
    }
    // month validate
    if (type === "month") {
      if (thisValue > 12 && thisName === "month") {
        e.target.value = '';
      }
      else if ((thisValue < 0 || thisValue === 1 || thisValue === '') && thisName === "month") {
        e.target.value = '';
      }
    }
    // year validate
    if (type === "year") {
      if (thisValue > thisYear && thisName === "year") {
        e.target.value = '';
      }
      else if ((thisValue < 1 || thisValue === 1) && thisName === "year") {
        e.target.value = '';
      }
    }
  }

  handleChangeGender = () => {
    const value = document.getElementById('gender').value;
    if (value === '') {
      this.setState({ isGenderDefault: true });
    }
    this.setState({ isGenderDefault: false });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isPersonOldUnder18,
      classes,
      pristine,
      submitting
    } = this.props;
    const { isGenderDefault } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            {de_de.register_header}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.register_title_personal_info}</h2>
          <div className="input-fields">
            {/* <div className="gender-fields">
              <Field
                name="gender"
                type="number" component={renderField} label="Anrede"
                className="input-id-login gender-spiner"
                checkDay={this.checkDay}
              />
              <span style={(g === undefined) ? {color: 'rgb(117, 117, 117)'} : {color: '#000'}}>
                {(g === undefined) ? 'Anrede' : g}
              </span>
            </div> */}
            {/* <Field
              name="gender"
              type="text"
              component={renderField}
              label="Anrede"
              list="gender"
              className="input-id-login"
            /> */}
            <Field
              name="gender"
              type="text"
              component={renderGender}
              label={de_de.register_placeholder_gender}
              list="gender"
              className="input-id-login"
              onChange={this.handleChangeGender}
              isGenderDefault={isGenderDefault}
            />
            <Field
              name="firstname"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_firstname}
              className="input-id-login"
              normalize={normalizeFirstLastName}
            />
            <Field
              name="lastname"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_lastname}
              className="input-id-login"
              normalize={normalizeFirstLastName}
            />
            <p>{de_de.register_label_birthday}</p>
            <div className="birthday-fields">
              {/* <Field
                className="date"
                name="day"
                type="number" label="TT" component={renderFieldDays}
                checkDay={this.checkDay}
              /> */}
              <Field
                className="date"
                name="day"
                type="number" label={de_de.register_placeholder_day} component={renderBirthDay}
                checkDay={(e) => this.checkBirthDayField(e, 'day')}
                min={1}
              />
              <Field
                className="date"
                name="month"
                type="number" label={de_de.register_placeholder_month} component={renderBirthDay}
                checkDay={(e) => this.checkBirthDayField(e, 'month')}
                min={1}
              />
              <Field
                className="date"
                name="year"
                type="number" label={de_de.register_placeholder_year} component={renderBirthDay}
                checkDay={(e) => this.checkBirthDayField(e, 'year')}
                min={1900}
              />

            </div>
            <span>{isPersonOldUnder18 ? 'Mindestalter ist 18 Jahre!' : ''}</span>
            {/* <span>{isPersonOldUnder18 ? 'Datum nicht valid' : ''}</span> */}
          </div>
          <div className="checkbox-verify">
            <Field
              name="agreed"
              id="agreed"
              component={(props) => <RenderCheckbox
                {...props}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                label={de_de.label_privacy}
              />}
              type="checkbox" />
          </div>
          {/* <p>Mit der Registrierung stimmst du den <a target="_blank" rel='noreferrer noopener' href="https://www.shoppyland.ch/datenschutzagb" >Allgemeinen Geschäftsbedingungen</a> des Einkaufscenter Shoppyland zu. Für die Bearbeitung von Personendaten gilt die <a target="_blank" rel='noreferrer noopener' href="https://www.shoppyland.ch/datenschutzagb" >Datenschutzerklärung</a>.</p> */}
          <div className="submits submit-email-phone">
            <button className="account-submit" type="submit" >
              {de_de.intro1_btn}
            </button>
          </div>
          <p><b>Passantrag-Bedingungen:</b><br />Alle Felder müssen wahrheitsgetreu ausgefüllt sein. Der/Die Antragsteller/-in hat mindestens 18 Jahre alt und in der Schweiz wohnhaft zu sein. Deine Daten dürfen von den Firmen des Einkaufscenters Shoppyland und der Migros-Gruppe für Marketingzwecke verwendet, jedoch nicht an Dritte weitergegeben werden. Vom Shoppy-Newsletter und Shoppy-Bonuspass kannst du dich jederzeit wieder abmelden.<br /><br /><br /></p>
        </div>
      </form>
    );
  }
}

const styles = {
  root: {
    color: '#999',
    '&$checked': {
      color: '#C7D319',
    },
  },
  checked: {},
}

export default reduxForm({
  form: 'register',
  fields,
  destroyOnUnmount: false,
  validate
})(withStyles(styles)(WizardUserName));
