/* eslint-disable no-console */
/* eslint-disable no-this-before-super */
/* eslint-disable constructor-super */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Redirect } from 'react-router-dom';
import uuid from 'uuid4';
import { history } from '../helper';
import Welcome from '../components/welcome';
import WithSplashScreen from '../components/splashscreen';
import HomePage from '../components/home';
import LoginPhone from '../components/loginPhone';
import LoginEmail from '../components/loginEmail';
import VorteliePage from '../components/Vorteile';
import GuestPage from '../components/Guest';
import Register from '../components/register';
import MyPage from '../components/MyPage';
import PointPage from '../components/Points';
import CouponPage from '../components/Angebote';
import VoucherGuestPage from '../components/AngeboteGuest';
import VoucherDetail from '../components/Voucher';
import CouponDetail from '../components/Coupon';
import ListPage from '../components/List';
import BottomNavigation from '../components/BottomNavigation';
import MorePage from '../components/More';
import Profile from '../components/Profile'
import MorePoints from '../components/MorePoints';
import UploadProfile from '../components/UploadProfile';
import Gutschein from '../components/Gutschein';
import ShoppyPass from '../components/ShoppyPass';
import CongratulationScreen from '../components/Points/Congratulation';
import CouponRegular from '../components/Points/CouponRegular'
import CreateShoppingList from '../components/List/AddItem';
import AddItemList from '../components/List/AddList';
import AddArticleItem from '../components/List/AddArticleItem';
import { _clear, _logOut } from '../actions';
import Authenticate from '../components/AuthenticateRoute';
import UpdateProfile from '../components/UpdateProfile';
import GuestCouponDetail from "../components/GuestCouponDetail";
import OutsideGeolocation from "../components/Points/OutsideGeolocation";
import OutsideGeolocationDenied from "../components/Points/OutsideGeolocationDenied";
import { _isBrowser, _checkRefresh } from '../lib';
import { COOKIE_APP_SUGGESTION_NAME } from '../config';
import Cookies from 'js-cookie';
import Rotation from './rotate';
import device from 'current-device';
import * as Sentry from "@sentry/browser";
const isBrowser = _isBrowser();

class App extends Component {
  constructor(props) {
    super(props);
    Sentry.init({
      dsn: 'https://54863867eb0b48f4b6b48b5d52cb321b@sentry.io/1463340',
      environment: process.env.NODE_ENV
    });
    const { dispatch } = this.props;
    this.state = {
      lat: null,
      lng: null,
      isLandcape: device.tablet() || device.desktop()
    }

    let userGuestId = localStorage.getItem('bonus_uuid_guest');
    if (!userGuestId || userGuestId === '') {
      this.guestId = uuid();
      localStorage.setItem('bonus_uuid_guest', this.guestId);
    }

    history.listen((location, action) => {
      const isCookieEmpty = Cookies.get(COOKIE_APP_SUGGESTION_NAME) === undefined;
      if (isCookieEmpty) {
        dispatch(_logOut());
        dispatch(_clear());
      }
      dispatch(_clear());
    });
  }

  handleChangeOrientation = () => {
    device.onChangeOrientation((newOrientation) => {
      if (device.mobile() && newOrientation === 'landscape') {
        this.setState({ isLandcape: true });
      } else {
        this.setState({ isLandcape: false });
      }

      if ((device.tablet() && device.portrait()) || (device.tablet() && device.landscape())) {
        this.setState({ isLandcape: true });
      }
    })

    if ((device.desktop() && device.portrait())) {
      this.setState({ isLandcape: true });
    }

    if ((device.tablet() && device.portrait()) || (device.tablet() && device.landscape())) {
      this.setState({ isLandcape: true });
    }

    if ((device.mobile() && device.portrait())) {
      this.setState({ isLandcape: false });
    }
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    this.handleChangeOrientation();

  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { isLandcape } = this.state;
    return (
      <div className={`App ${isBrowser}`}>
        <Router history={history}>
          <Route path="/" exact component={Welcome} />
          <Authenticate exact path="/login" component={HomePage} />
          <Route exact path="/:page" component={LoginContainer} />
          <Route exact path="/shopping/:subpage" component={LoginContainer} />
          <Route exact path="/shopping/:subpage/:id" component={LoginContainer} />
          <Route exact path="/user/:page/:subpage" component={LoginContainer} />
          <Route exact path="/user/:page/:subpage/:id" component={LoginContainer} />

          <Route exact path="/user/:page" component={UserContainer} />
          <Route exact path="/user/:page/:subpage" component={UserContainer} />

          <Route exact path="/public/:page" component={GuestContainer} />
          <Route exact path="/public/:page/:subpage/:id" component={GuestContainerDetail} />

        </Router>
        {isLandcape &&
          <Rotation
            title={device.desktop() || device.tablet() ? 'Bitte öffnen Sie die Seite auf Ihrem Handy' : 'Bitte drehen Sie Ihr Gerät'}
          />
        }
      </div>
    );
  }
}

const LoginContainer = (props) => (
  <div className="login-container">
    <Route path="/" exact render={(prop) => <Redirect to="/home" />} />
    <Route path="/home" exact component={HomePage} />
    <Route path="/login-phone" exact component={LoginPhone} />
    <Route path="/login-email" exact component={LoginEmail} />
    <Route path="/register" exact component={Register} />
    <Route path="/vortelie" exact component={VorteliePage} />
    <Route path="/more-points" exact component={MorePoints} />
    <Route path="/upload-profile" exact component={UploadProfile} />
    <Route path="/gutschein" exact component={Gutschein} />
    <Route path="/shoppy-pass" exact component={ShoppyPass} />
    <Route path="/update-profile" exact component={UpdateProfile} />
    {/* <Route exact path="/shopping/list-added/:id" component={AddItemList} /> */}
    <Route path="/shopping/list-added/:info" exact component={AddItemList} />
    
    <Route exact path="/shopping/add-article/:info" component={AddArticleItem} />
    <Route path="/user/shopping/create-list" exact component={CreateShoppingList} />
    <Route path="/user/voucher/detail/:id" exact component={VoucherDetail} />
    <Route path="/user/coupon/detail/:id" exact component={CouponDetail} />
  </div>
);

const GuestContainer = () => (
  <div>
    <Route path="/public/guest" exact component={GuestPage} />
    <Route path="/public/coupons" exact component={VoucherGuestPage} />
    <Route path="/public/more" exact component={MorePage} />
    <BottomNavigation />
  </div>
);

const GuestContainerDetail = () => (
  <div>
    <Route path="/public/coupon/detail/:id" exact component={GuestCouponDetail} />
  </div>
);

const UserContainer = () => (
  <div>
    <Route path="/user/my-page" exact component={MyPage} />
    <Route path="/user/points" exact component={PointPage} />
    <Route path="/user/coupons" exact component={CouponPage} />

    <Route path="/user/shopping-list" exact component={ListPage} />
    <Route path="/user/more" exact component={MorePage} />
    <Route path="/user/profile" exact component={Profile} />
    <Route path="/user/more-points" exact component={MorePoints} />
    <Route path="/user/congras" exact component={CongratulationScreen} />
    <Route path="/user/get-point" exact component={CouponRegular} />
    <Route path="/user/outside" exact component={OutsideGeolocation} />
    <Route path="/user/denied" exact component={OutsideGeolocationDenied} />
    <BottomNavigation />
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated
});

export default WithSplashScreen(connect(mapStateToProps)(App));
