export const alertConstants = {
  FETCHING_DATA: 'FETCHING_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',
  // login email
  FETCHING_DATA_EMAIL: 'FETCHING_DATA_EMAIL',
  FETCH_DATA_SUCCESS_EMAIL: 'FETCH_DATA_SUCCESS_EMAIL',
  FETCH_DATA_FAILURE_EMAIL: 'FETCH_DATA_FAILURE_EMAIL',
  //login phone
  FETCHING_DATA_PHONE: 'FETCHING_DATA_PHONE',
  FETCH_DATA_SUCCESS_PHONE: 'FETCH_DATA_SUCCESS_PHONE',
  FETCH_DATA_FAILURE_PHONE: 'FETCH_DATA_FAILURE_PHONE',
  //register
  FETCHING_DATA_REGISTER: 'FETCHING_DATA_REGISTER',
  FETCH_DATA_SUCCESS_REGISTER: 'FETCH_DATA_SUCCESS_REGISTER',
  FETCH_DATA_FAILURE_REGISTER: 'FETCH_DATA_FAILURE_REGISTER',
  //check-in
  FETCHING_DATA_CHECKIN: 'FETCHING_DATA_CHECKIN',
  FETCHING_DATA_SUCCESS_CHECKIN: 'FETCHING_DATA_SUCCESS_CHECKIN',
  FETCHING_DATA_FAILURE_CHECKIN: 'FETCHING_DATA_FAILURE_CHECKIN',
  //clear
  CLEAR: 'CLEAR',
  // get OTP
  FETCHING_DATA_GET_OTP: 'FETCHING_DATA_GET_OTP',
  FETCH_DATA_SUCCESS_GET_OTP: 'FETCH_DATA_SUCCESS_GET_OTP',
  FETCH_DATA_FAILURE_GET_OTP: 'FETCH_DATA_FAILURE_GET_OTP',
  // verify OTP
  FETCHING_DATA_VERIFY_OTP: 'FETCHING_DATA_VERIFY_OTP',
  FETCH_DATA_SUCCESS_VERIFY_OTP: 'FETCH_DATA_SUCCESS_VERIFY_OTP',
  FETCH_DATA_FAILURE_VERIFY_OTP: 'FETCH_DATA_FAILURE_VERIFY_OTP',
  // get Current user
  FETCHING_DATA_CURRENT_USER: 'FETCHING_DATA_CURRENT_USER',
  FETCH_DATA_SUCCESS_CURRENT_USER: 'FETCH_DATA_SUCCESS_CURRENT_USER',
  FETCH_DATA_FAILURE_CURRENT_USER: 'FETCH_DATA_FAILURE_CURRENT_USER',
  // check email 
  FETCHING_DATA_CHECK_EMAIL: 'FETCHING_DATA_CHECK_EMAIL',
  FETCHING_DATA_SUCCESS_CHECK_EMAIL: 'FETCHING_DATA_SUCCESS_CHECK_EMAIL',
  FETCHING_DATA_FAILURE_CHECK_EMAIL: 'FETCHING_DATA_FAILURE_CHECK_EMAIL',
  // check phone
  FETCHING_DATA_CHECK_PHONE: 'FETCHING_DATA_CHECK_PHONE',
  FETCHING_DATA_SUCCESS_PHONE: 'FETCHING_DATA_SUCCESS_PHONE',
  FETCHING_DATA_FAILURE_PHONE: 'FETCHING_DATA_FAILURE_PHONE',
  // log out
  LOGOUT: 'LOGOUT',
  // check email or postcode
  FETCHING_DATA_CHECK_EMAIL_POSTCODE: 'FETCHING_DATA_CHECK_EMAIL_POSTCODE',
  FETCHING_DATA_CHECK_EMAIL_POSTCODE_SUCCESS: 'FETCHING_DATA_CHECK_EMAIL_POSTCODE_SUCCESS',
  FETCHING_DATA_CHECK_EMAIL_POSTCODE_FAILURE: 'FETCHING_DATA_CHECK_EMAIL_POSTCODE_FAILURE',

  // update profile
  FETCHING_DATA_UPDATE_PROFILE: 'FETCHING_DATA_UPDATE_PROFILE',
  FETCHING_DATA_UPDATE_PROFILE_SUCCESS: 'FETCHING_DATA_UPDATE_PROFILE_SUCCESS',
  FETCHING_DATA_UPDATE_PROFILE_FAILURE: 'FETCHING_DATA_UPDATE_PROFILE_FAILURE',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAILURE: 'UPLOAD_PHOTO_FAILURE',
  // shopping list
  FETCHING_DATA_SHOPPING_LIST: 'FETCHING_DATA_SHOPPING_LIST',
  FETCHING_DATA_SHOPPING_LIST_SUCCESS: 'FETCHING_DATA_SHOPPING_LIST_SUCCESS',
  FETCHING_DATA_SHOPPING_LIST_FAILURE: 'FETCHING_DATA_SHOPPING_LIST_FAILURE',
  // create shopping list
  FETCHING_DATA_CREATE_SHOPPING_LIST: 'FETCHING_DATA_CREATE_SHOPPING_LIST',
  FETCHING_DATA_CREATE_SHOPPING_LIST_SUCCESS: 'FETCHING_DATA_CREATE_SHOPPING_LIST_SUCCESS',
  FETCHING_DATA_CREATE_SHOPPING_LIST_FAILURE: 'FETCHING_DATA_CREATE_SHOPPING_LIST_FAILURE',
  // delete shopping list
  FETCHING_DATA_SHOPPING_LIST_DELETE: 'FETCHING_DATA_SHOPPING_LIST_DELETE',
  FETCHING_DATA_SHOPPING_LIST_DELETE_SUCCESS: 'FETCHING_DATA_SHOPPING_LIST_DELETE_SUCCESS',
  FETCHING_DATA_SHOPPING_LIST_DELETE_FAILURE: 'FETCHING_DATA_SHOPPING_LIST_DELETE_FAILURE',
  // shopping list item
  FETCHING_DATA_SHOPPING_LIST_ITEM: 'FETCHING_DATA_SHOPPING_LIST_ITEM',
  FETCHING_DATA_SHOPPING_LIST_ITEM_SUCCESS: 'FETCHING_DATA_SHOPPING_LIST_ITEM_SUCCESS',
  FETCHING_DATA_SHOPPING_LIST_ITEM_FAILURE: 'FETCHING_DATA_SHOPPING_LIST_ITEM_FAILURE',
  // create shopping item
  FETCHING_DATA_SHOPPING_ITEM_CREATE: 'FETCHING_DATA_SHOPPING_ITEM_CREATE',
  FETCHING_DATA_SHOPPING_ITEM_CREATE_SUCCESS: 'FETCHING_DATA_SHOPPING_ITEM_CREATE_SUCCESS',
  FETCHING_DATA_SHOPPING_ITEM_CREATE_FAILURE: 'FETCHING_DATA_SHOPPING_ITEM_CREATE_FAILURE',
  // delete shopping item
  FETCHING_DATA_SHOPPING_ITEM_DELETE: 'FETCHING_DATA_SHOPPING_ITEM_DELETE',
  FETCHING_DATA_SHOPPING_ITEM_DELETE_SUCCESS: 'FETCHING_DATA_SHOPPING_ITEM_DELETE_SUCCESS',
  FETCHING_DATA_SHOPPING_ITEM_DELETE_FAILURE: 'FETCHING_DATA_SHOPPING_ITEM_DELETE_FAILURE',
  // voucher list
  FETCHING_DATA_VOUCHERS_LIST: 'FETCHING_DATA_VOUCHERS_LIST',
  FETCHING_DATA_VOUCHERS_LIST_SUCCESS: 'FETCHING_DATA_VOUCHERS_LIST_SUCCESS',
  FETCHING_DATA_VOUCHERS_LIST_FAILURE: 'FETCHING_DATA_VOUCHERS_LIST_FAILURE',
  // get voucher
  FETCHING_DATA_GET_VOUCHER: 'FETCHING_DATA_GET_VOUCHER',
  FETCHING_DATA_GET_VOUCHER_SUCCESS: 'FETCHING_DATA_GET_VOUCHER_SUCCESS',
  FETCHING_DATA_GET_VOUCHER_FAILURE: 'FETCHING_DATA_GET_VOUCHER_FAILURE',
  FETCHING_DATA_VOUCHER_DELETE: 'FETCHING_DATA_VOUCHER_DELETE',
  FETCHING_DATA_VOUCHER_DELETE_SUCCESS: 'FETCHING_DATA_VOUCHER_DELETE_SUCCESS',
  FETCHING_DATA_VOUCHER_DELETE_FAILURE: 'FETCHING_DATA_VOUCHER_DELETE_FAILURE',
  // redeem voucher
  FETCHING_DATA_REDEEM_VOUCHER: 'FETCHING_DATA_REDEEM_VOUCHER',
  FETCHING_DATA_REDEEM_VOUCHER_SUCCESS: 'FETCHING_DATA_REDEEM_VOUCHER_SUCCESS',
  FETCHING_DATA_REDEEM_VOUCHER_FAILURE: 'FETCHING_DATA_REDEEM_VOUCHER_FAILURE',
  // coupon list
  FETCHING_DATA_COUPON_LIST: 'FETCHING_DATA_COUPON_LIST',
  FETCHING_DATA_COUPON_LIST_SUCCESS: 'FETCHING_DATA_COUPON_LIST_SUCCESS',
  FETCHING_DATA_COUPON_LIST_FAILURE: 'FETCHING_DATA_VOUCHERS_LIST_FAILURE',
  // get coupon
  FETCHING_DATA_GET_COUPON: 'FETCHING_DATA_GET_COUPON',
  FETCHING_DATA_GET_COUPON_SUCCESS: 'FETCHING_DATA_GET_COUPON_SUCCESS',
  FETCHING_DATA_GET_COUPON_FAILURE: 'FETCHING_DATA_GET_COUPON_FAILURE',
  // redeem coupon
  FETCHING_DATA_REDEEM_COUPON: 'FETCHING_DATA_REDEEM_COUPON',
  FETCHING_DATA_REDEEM_COUPON_SUCCESS: 'FETCHING_DATA_REDEEM_COUPON_SUCCESS',
  FETCHING_DATA_REDEEM_COUPON_FAILURE: 'FETCHING_DATA_REDEEM_COUPON_FAILURE',
  FETCHING_DATA_COUPON_DELETE: 'FETCHING_DATA_COUPON_DELETE',
  FETCHING_DATA_COUPON_DELETE_SUCCESS: 'FETCHING_DATA_COUPON_DELETE_SUCCESS',
  FETCHING_DATA_COUPON_DELETE_FAILURE: 'FETCHING_DATA_COUPON_DELETE_FAILURE',
  // next voucher
  FETCHING_DATA_NEXT_VOUCHER: 'FETCHING_DATA_NEXT_VOUCHER',
  FETCHING_DATA_NEXT_VOUCHER_SUCCESS: 'FETCHING_DATA_NEXT_VOUCHER_SUCCESS',
  FETCHING_DATA_NEXT_VOUCHER_FAILURE: 'FETCHING_DATA_NEXT_VOUCHER_FAILURE',
  
  // Menu List with registered user
  USER_REGISTERED_GET_MENU_LIST: 'USER_REGISTERED_GET_MENU_LIST',
  USER_REGISTERED_GET_MENU_LIST_SUCCESS: 'USER_REGISTERED_GET_MENU_LIST_SUCCESS',
  USER_REGISTERED_GET_MENU_LIST_FAILURE: 'USER_REGISTERED_GET_MENU_LIST_FAILURE',

  // Get categories list
  GET_CATEGORIES_DATA: 'GET_CATEGORIES_DATA',
  GET_CATEGORIES_DATA_SUCCESS: 'GET_CATEGORIES_DATA_SUCCESS',
  GET_CATEGORIES_DATA_FAILURE: 'GET_CATEGORIES_DATA_FAILURE',
  
  // Guest get news
  GET_LASTED_NEWS_DATA: 'GET_LASTED_NEWS_DATA',
  GET_LASTED_NEWS_DATA_SUCCESS: 'GET_LASTED_NEWS_DATA_SUCCESS',
  GET_LASTED_NEWS_DATA_FAILURE: 'GET_LASTED_NEWS_DATA_FAILURE',

  // Guest get opening time
  GET_OPENING_TIME: 'GET_OPENING_TIME',
  GET_OPENING_TIME_SUCCESS: 'GET_OPENING_TIME_SUCCESS',
  GET_OPENING_TIME_FAILURE: 'GET_OPENING_TIME_FAILURE',

  // Guest get coupons
  GET_COUPON_IN_GUEST_DATA: 'GET_COUPON_IN_GUEST_DATA',
  GET_COUPON_IN_GUEST_DATA_SUCCESS: 'GET_COUPON_IN_GUEST_DATA_SUCCESS',
  GET_COUPON_IN_GUEST_DATA_FAILURE: 'GET_COUPON_IN_GUEST_DATA_FAILURE',

  // clean unsused quest category

  CLEAN_UNUSED_QUEST_CATEGORY: 'CLEAN_UNUSED_QUEST_CATEGORY',

  // Guest get categories
  GET_CATEGORIES_IN_GUEST_DATA: 'GET_CATEGORIES_IN_GUEST_DATA',
  GET_CATEGORIES_IN_GUEST_DATA_SUCCESS: 'GET_CATEGORIES_IN_GUEST_DATA_SUCCESS',
  GET_CATEGORIES_IN_GUEST_DATA_FAILURE: 'GET_CATEGORIES_IN_GUEST_DATA_FAILURE',

  // categories handle user active
  SAVE_CATEGORY_ID_ACTIVE: 'SAVE_CATEGORY_ID_ACTIVE',

  // Location
  GET_LOCATION: 'GET_LOCATION',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAILURE: 'GET_LOCATION',

  // Shopping list toggle item
  PUT_TOGGLE_SHOPPING_LIST_ITEM: 'PUT_TOGGLE_SHOPPING_LIST_ITEM',
  PUT_TOGGLE_SHOPPING_LIST_ITEM_SUCCESS: 'PUT_TOGGLE_SHOPPING_LIST_ITEM_SUCCESS',
  PUT_TOGGLE_SHOPPING_LIST_ITEM_FAILURE: 'PUT_TOGGLE_SHOPPING_LIST_ITEM_FAILURE',
  // Get mall
  FETCHING_GET_MALL: 'FETCHING_GET_MALL',
  FETCHING_GET_MALL_SUCCESS: 'FETCHING_GET_MALL_SUCCESS',
  FETCHING_GET_MALL_FAILURE: 'FETCHING_GET_MALL_FAILURE',
  // Save image profile
  FETCHING_SAVE_IMAGE_PROFILE: 'FETCHING_SAVE_IMAGE_PROFILE',
  FETCHING_SAVE_IMAGE_PROFILE_SUCCESS: 'FETCHING_SAVE_IMAGE_PROFILE_SUCCESS',
  FETCHING_SAVE_IMAGE_PROFILE_FAILURE: 'FETCHING_SAVE_IMAGE_PROFILE_FAILURE'
};
