import {
  alertConstants
} from '../config';

const INITIALSTATE = {
  data: [],
  isFetching: false,
  error: false,
  emailError: false,
  phoneError: false,
  dataEmail: [],
  dataPhone: []
};

export default (state = INITIALSTATE, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_REGISTER:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_REGISTER:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_REGISTER:
      return {
        ...state,
        isFetching: false,
        error: true
      };
      // check email
    case alertConstants.FETCHING_DATA_CHECK_EMAIL:
      return {
        ...state,
        dataEmail: [],
      };
    case alertConstants.FETCHING_DATA_SUCCESS_CHECK_EMAIL:
      return {
        ...state,
        dataEmail: action.payload,
        emailError: false
      };
    case alertConstants.FETCHING_DATA_FAILURE_CHECK_EMAIL:
      return {
        ...state,
        emailError: true
      };
      // check phone
    case alertConstants.FETCHING_DATA_CHECK_PHONE:
      return {
        ...state,
        dataPhone: [],
      };
    case alertConstants.FETCHING_DATA_SUCCESS_PHONE:
      return {
        ...state,
        dataPhone: action.payload,
        phoneError: false
      };
    case alertConstants.FETCHING_DATA_FAILURE_PHONE:
      return {
        ...state,
        dataPhone: [],
        phoneError: true
      };
    default:
      return state;
  }
};
