/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Fab } from '@material-ui/core';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';

import validate from './form-validation';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';
import { settingContants } from '../../config';

const fields = ['email', 'tel', 'address', 'zipcode', 'city'];
const { de_de } = lang[1];
const normalizePostalCode = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 5) {
    return value;
  }
}

const renderField = ({
  input,
  type,
  label,
  meta: { touched, error },
  className,
  isEmailExist,
  isPhoneExist
}) => (
  <React.Fragment>
    <input
      {...input}
      placeholder={label}
      type={type}
      className={(touched && error) ? `${className} error` : (isEmailExist) ? `${className} error` : `${className}`}
    />
    <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
  );

const renderPhoneField = ({
  meta: { touched, error },
  name,
  input,
  isPhoneExist,
  isPhoneInvalid
}) => (
  <React.Fragment>
    <ReactPhoneInput
      defaultCountry={settingContants.countryCode}
      containerStyle={styleContainer}
      inputClass={(touched && error) || isPhoneExist || isPhoneInvalid ? 'input-id-login-phone error' : 'input-id-login-phone'}
      buttonClass="input-id-login-phone-flag"
      disableDropdown
      disableAreaCodes
      disableSearchIcon
      inputExtraProps={{
        name: name,
        required: true,
      }}
      {...input}
      countryCodeEditable={false}
      inputExtraProps={{
        name: 'phone',
        required: true,
        autoFocus: true
      }}
      // masks={{'vn': '+.. (...) ...-...'}}
    />
    <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
  );

const normalizeAddressPlace = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 50) {
    return value;
  }
}

class WizardUserName extends Component {

  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isPhoneExist,
      isEmailExist,
      isPhoneInvalid,
      submitting
    } = this.props;
    return (
      <form onSubmit={handleSubmit} noValidate={true}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            {de_de.register_header}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.register_title_contact_info}</h2>
          <div className="input-fields">
            <Field
              name="email"
              type="email"
              component={renderField}
              label={de_de.register_placeholder_email}
              className="input-id-login"
              //validate={[required, email, maxLength(100)]}
              isEmailExist={isEmailExist}
            />
            <span>{isEmailExist ? 'Diese E-mail wird bereits genutzt' : ''}</span>
            {/* <Field 
              name="tel"
              type="tel"
              component={renderField}
              label="Handynummer"
              className="input-id-login"
              isPhoneExist={isPhoneExist}
            /> */}

            <Field
              name="tel"
              type="tel"
              component={renderPhoneField}
              className="input-id-login"
              isPhoneExist={isPhoneExist}
              isPhoneInvalid={isPhoneInvalid}
            />

            <span>{isPhoneExist ? 'Diese Telefonnummer wird bereits genutzt' : ''}</span>
            <span>{isPhoneInvalid ? 'Dieses Handynummer ist nicht gültig!' : ''}</span>
            
            <Field
              name="address"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_address}
              className="input-id-login"
              normalize={normalizeAddressPlace}
            />
            <Field
              name="zipcode"
              type="number"
              component={renderField}
              label={de_de.register_placeholder_postcode}
              normalize={normalizePostalCode}
              className="input-id-login"
            />
            <Field
              name="city"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_place}
              className="input-id-login"
              normalize={normalizeAddressPlace}
            />
          </div>
          <div className="submits submit-email-phone">
            <button className="account-submit" type="submit" disabled={submitting}>
              {de_de.intro1_btn}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'register',
  fields,
  destroyOnUnmount: false,
  validate
})(WizardUserName);

const styleContainer = {
  width: '90%',
  margin: '0 auto',
  marginTop: '10px'
};
