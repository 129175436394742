/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helper';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import Item from './Item';
import { _sortData, _unixTimestamp, _dataInfoTracking } from '../../lib';
import { _deleteVoucher, _getListVouchers } from '../../actions';
import DialogConfirmDelete from '../DialogConfirmDeleteVoucher';
import lang from '../../config/languages.json';
import { errorCodeConstants } from '../../config';


class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpired: false,
      open: false,
      id: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isExpired !== this.state.isExpired) {
      _getListVouchers();
    }
  }


  componentDidMount() {
    const { isAuthenticated, _getListVouchers } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getListVouchers();
  }

  handleDeleteVoucher = async (id, event) => {
    const { de_de } = lang[1];
    const isChecked = localStorage.getItem("v") ? true : false;
    const userId = localStorage.getItem('id');
    event.preventDefault();
    const { _getListVouchers, _deleteVoucher } = this.props;
    if (isChecked) {
      await _deleteVoucher(id);
      await _getListVouchers();
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_voucher, errorCodeConstants.voucherDelete);
    }
    this.setState({
      open: true,
      id
    });
  }

  handleClickConfirm = async (id) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _getListVouchers, _deleteVoucher } = this.props;
    await _deleteVoucher(id);
    await _getListVouchers();
    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_voucher, errorCodeConstants.voucherDelete);
    this.setState({
      open: false
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleExpiration = () => {
    this.setState({
      isExpired: true
    })
  }

  render() {
    const isChecked = localStorage.getItem("c") ? true : false;
    const { isAuthenticated, data, type, orderKey, order, isVouchers } = this.props;
    const { isExpired, open, id } = this.state;
    const setting = {
      dots: this.props.settings.dots,
      infinite: this.props.settings.infinite,
      speed: this.props.settings.speed,
      slidesToShow: this.props.settings.slidesToShow,
      slidesToScroll: this.props.settings.slidesToScroll,
      initialSlide: this.props.settings.initialSlide,
      rows: this.props.settings.rows,
      arrows: this.props.settings.arrows,
    };

    const isAuth = isAuthenticated ? 'user' : 'public';
    const titleCarousel = this.props.hasOwnProperty('title') === true ?
      <h1>{this.props.title}</h1> : '';
    return (
      <div className={`carousel-container ${isAuth}`}>
        {titleCarousel}
        <div className="carousel-screen">
          {
            isVouchers.length === 0 ?
              <MyVoucher />
              :
              <Slider {...setting}>
                {
                  isVouchers && _sortData(isVouchers, orderKey, order).map((data, index) => {
                    const { redemption } = data;
                    const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
                    const typeOfCoupon = (minuteMount && !isExpired) || redemption === null ? 'green' : 'disabled';
                    return (
                      <Item
                        key={index}
                        data={data}
                        redemption={redemption}
                        type={typeOfCoupon}
                        expiration={minuteMount}
                        isAuthenticated={isAuthenticated}
                        handleDeleteVoucher={this.handleDeleteVoucher}
                        handleExpiration={this.handleExpiration}
                        isExpired={isExpired}
                      />
                    )
                  })
                }
              </Slider>
          }
          {
            open && !isChecked && (<DialogConfirmDelete
              open={open}
              handleConfirm={this.handleClickConfirm}
              handleClose={this.handleClose}
              id={id}
            />)
          }
        </div>

      </div>
    );
  }
}
const { de_de } = lang[1];
export const MyVoucher = () => (

  <div className="my-voucher">
    {/* <h1>meine Gutscheine</h1> */}
    <div className="my-voucher-screen">
      <p>{de_de.no_voucher}</p>
    </div>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isVouchers: state.vouchers.data,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _deleteVoucher,
    _getListVouchers
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carousel));
