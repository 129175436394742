/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  root: {
    flexGrow: 1,
  },
  linearColorPrimary: {
    backgroundColor: 'transparent',
  },
  linearBarColorPrimary: {
    backgroundColor: '#ffffff',
  }
};

class LinearDeterminate extends Component {
  render() {
    const { classes, completed } = this.props;
    return (
      <div className={`${classes.root} preloader`}>
        <div className="loading">Laden…</div>
        <LinearProgress
          variant="determinate"
          value={completed}
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(LinearDeterminate);
