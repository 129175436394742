import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Fab } from '@material-ui/core';

import validate from './form-validation';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';

const fields = ['gender', 'firstname', 'lastname', 'day', 'month', 'year'];
const renderField = ({
  input,
  type,
  label,
  id,
  list,
  meta: { touched, error },
  checkDay,
  className,
  disabled
}) => (
  <React.Fragment>
    <input
      {...input}
      placeholder={label}
      type={type}
      id={id}
      list={list}
      onKeyUp={checkDay}
      onClick={checkDay}
      disabled={disabled}
      className={(touched && error) ? `${className} error` :  `${className}`}
    />
    <span>{(touched && error) ? `${error}` : ''}</span>
    </React.Fragment>
);

const normalizeFirstLastName = (value) => {
  if (!value) {
    return value
  }
  if (value.length < 40 ) {
    return value;
  }
}

const renderGender = ({
  input,
  className,
  meta: { touched, error },
  onChange,
  isGenderDefault
}) => (
  <select
    id="gender"
    className={(touched && error)
      ? (isGenderDefault ? `${className} error` : `${className} error gender-default`)
      : (isGenderDefault ? `${className}` : `${className}`)
    }
    onChange={onChange}
    {...input}
  >
    <option value="" disabled defaultValue>Anrede</option>
    <option value='Frau'>Frau</option>
    <option value='Herr'>Herr</option>
  </select>
);

class WizardUserName extends Component {
  constructor(props) {
    super(props);
    this.checkDay = this.checkDay.bind(this);
    this.state = {
      isGenderDefault: true,
    }
  }
  
  handleChangeGender = () => {
    const value = document.getElementById('gender').value;
    if (value === '') {
      this.setState({ isGenderDefault: true });
    }
    this.setState({ isGenderDefault: false });
  }

  checkDay(e) {
    const thisValue = e.target.value;
    const thisName = e.target.name;
    const thisYear = new Date().getFullYear();

    // year validate
    if(thisValue > 31 && thisName === "day") {
      e.target.value = '';
    }
    else if((thisValue < 0 || thisValue === 1 || thisValue === '') && thisName === "day") {
      e.target.value = '';
    }
    // month validate
    if(thisValue > 12 && thisName === "month") {
      e.target.value = '';
    }
    else if((thisValue < 0  || thisValue === 1 || thisValue === '')&& thisName === "month") {
      e.target.value = '';
    }
    // year validate
    if(thisValue > thisYear && thisName === "year") {
      e.target.value = '';
    }
    else if((thisValue < 1 || thisValue === 1)&& thisName === "year") {
      e.target.value = '';
    }
  }

  render() {
    const {
      handleSubmit,
      handlePreviousPage,
    } = this.props;
    const { isGenderDefault } = this.state;
  const { de_de } = lang[1];

    return (
      <form onSubmit={handleSubmit}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            Profil aktualisieren
          </Fab>
        </div>
        <div className="section-content">
          <h2 className="brow-color">{de_de.register_title_personal_info}</h2>
          <div className="input-fields">
            {/* <Field
              name="gender"
              type="text"
              component={renderField}
              label="Anrede"
              list="gender"
              className="input-id-login"
            /> */}
            <Field
              name="gender"
              type="text"
              component={renderGender}
              label={de_de.register_placeholder_gender}
              list="gender"
              className="input-id-login"
              onChange={this.handleChangeGender}
              isGenderDefault={isGenderDefault}
            />
            <Field
              name="firstname"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_firstname}
              className="input-id-login"
              normalize={normalizeFirstLastName}
            />
            <Field 
              name="lastname"
              type="text"
              component={renderField}
              label={de_de.register_placeholder_lastname}
              className="input-id-login"
              normalize={normalizeFirstLastName}
            />
            <p>Ihr Geburtsdatum:</p>
            <div className="birthday-fields">
            <div className="render-birthday-input">
              <Field
                className="date disable"
                name="day"
                type="number" label={de_de.register_placeholder_day} component={renderField}
                checkDay={this.checkDay}
                disabled={true}
              />
              </div>
              <div className="render-birthday-input">
              <Field
                className="date disable"
                name="month"
                type="number" label={de_de.register_placeholder_month} component={renderField}
                checkDay={this.checkDay}
                disabled={true}
              />
              </div>
              <div className="render-birthday-input">
              <Field
                className="date disable"
                name="year"
                type="number" label={de_de.register_placeholder_year} component={renderField}
                checkDay={this.checkDay}
                disabled={true}
              />
              </div>
            </div>
          </div>
          <div className="submits submit-email-phone">
            <button className="account-submit brow-background-color" type="submit">
    {de_de.intro1_btn}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

let DefaultForm = reduxForm({
  form: 'updateProfile',
  enableReinitialize : true,
  fields,
  destroyOnUnmount: false,
  validate
})(WizardUserName);

const mapStateToProps = state => {
  let {
    profile: {
      data: {
        data
      },
      birthDay
    }
  } = state;
  if (data !== undefined) {
    data['day'] = birthDay.substring(0, 2);
    data['month'] = birthDay.substring(3, 5);
    data['year'] = birthDay.substring(6, 11);
    if (data.phone && data.phone.substring(0, 1) === '0') {
      data.phone = data.phone.substring(1);
    }
  }

  return {
    initialValues: data
  }
};

DefaultForm = connect(mapStateToProps)(DefaultForm);

export default DefaultForm;
