/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helper';
import Coins from '../../../assets/img/coin-img.svg';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import lang from '../../../config/languages.json';

class GetPointRegular extends Component {

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
  }

  render() {
    const { total_point, reward_point } = this.props;
    const { de_de } = lang[1];
    return (
      <div className="get-point-screen">
        <div className="get-point-gift-bg">
          <div className="image-coin">
            <img src={Coins} alt="" className="responsive-img" />
          </div>
          <h3>+{reward_point} {de_de.scan_text}</h3>
          <h6>{de_de.youhavenow}</h6>
          <h1>{total_point}</h1>
          <h6>{de_de.point_text}</h6>
          <Button
            variant="contained"
            className="btn-back"
            component={Link}
            to="/user/my-page"
          >
                {de_de.intro1_btn}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  reward_point: state.checkin.data.reward_point
});

export default connect(mapStateToProps)(GetPointRegular);