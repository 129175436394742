/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../helper';
import { Link, withRouter } from 'react-router-dom';
import Header from '../shared/back-button';
import CarouselCouponPage from '../CarouselCouponPage';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import KategorieList from '../Kategorie';
import { _getCouponList, _getCategories, _getIdActive } from '../../actions';
import { _unixTimestamp } from '../../lib';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';


function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    width: "100%",
  },
  tabRoot: {
    color: '#5A5A5A',
    "&$tabSelected": {
      color: "#C0A35E",
    },
    "&:focus": {
      color: "#C0A35E"
    }
  },
  tabSelected: {},
  indicator: {
    backgroundColor: "transparent",
  }
});

class Angebote extends Component {
  state = {
    value: 0,
    isShowCategoriesList: true,
  };

  componentDidMount() {
    const { isAuthenticated, _getCouponList, _getCategories, location } = this.props;
    TagManager.dataLayer(tagManagerArgs);
    const { value } = !!location.state && location.state;
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getCouponList();
    _getCategories();
    !!value && this.setState({ value });
  }

  handleChange = (event, value) => {
    this.setState({ value, isShowCategoriesList: true });
  };

  handleChangeCallback = () => {
    this.setState({ isShowCategoriesList: false });
  }

  render() {
    const { classes, isFetching, isCoupon, categories } = this.props;
    const { value, isShowCategoriesList } = this.state;
    const { de_de } = lang[1];

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 2,
      arrows: false
    };

    return (
      <div className="angebote main-page">
        <Header to="" title={de_de.deals} />
        <div className="main-screen">
          <div className="angebote guest-container">
            <div className="guest-screen">
              <div className={`section-2 ${value === 2 ? 'category' : ''}`}>
                <div className={classes.root}>
                  <AppBar position="fixed" color="default" className="tabs-header">
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      classes={{ indicator: classes.indicator }}
                      scrollButtons="auto"
                      className="tabs-container"
                      variant="fullWidth"
                    >
                      <Tab 
                        label={de_de.tabs}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                      {/* <Tab 
                        label="neu" 
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      /> */}
                      <Tab 
                        label={de_de.tabs_beliebt}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                      <Tab 
                        label= {de_de.tabs_kategorie}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                    </Tabs>
                  </AppBar>
                  {value === 0 && <TabContainer>
                    <CarouselCouponPage
                      settings={settings}
                      type="coupon"
                      data={isCoupon}
                      orderKey="id"
                      order="desc"
                    />
                  </TabContainer>}
                  {/* {value === 1 && <TabContainer>
                    <CarouselCouponPage
                      settings={settings}
                      data={isCoupon}
                      orderKey="created_at"
                      order="desc"
                    />
                  </TabContainer>} */}
                  {value === 1 && <TabContainer>
                    <CarouselCouponPage
                      settings={settings}
                      data={isCoupon}
                      orderKey="view"
                      order="desc"
                    />
                  </TabContainer>}
                  {value === 2 && <TabContainer>
                    <KategorieList
                      categories={categories}
                      coupons={isCoupon}
                      isShowCategoriesList={isShowCategoriesList}
                      handleChangeCallback={this.handleChangeCallback}
                    />
                  </TabContainer>}
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isCoupon: state.coupon.data,
  categories: state.Categories.data
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getCouponList,
    _getCategories
  }, dispatch)
);

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Angebote)));
