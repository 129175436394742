/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Fab } from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { settingContants } from '../../config';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';
import * as Sentry from "@sentry/browser";

export const fields = ['tel'];
const { de_de } = lang[1];
const validate = values => {
  
  const errors = {};
  if (!values.tel) {
    errors.tel = 'Required'
  } else if (values.tel.trim() === '+41') {
    errors.tel = 'Required';
  }
  // else if (!/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(values.tel)) {
  //   errors.tel = 'Invalid phone number'
  // }
  // Sentry.captureException(errors);
  return errors;
};


const renderPhoneField = ({
  meta: { touched, error },
  name,
  input,
  isPhoneTrueFormat,
  isPhoneRegistered
}) => (
  <ReactPhoneInput
    defaultCountry={settingContants.countryCode}
    containerStyle={styleContainer}
    inputClass={(touched && error) || isPhoneTrueFormat || isPhoneRegistered ? 'input-id-login-phone error' : 'input-id-login-phone' }
    buttonClass="input-id-login-phone-flag"
    disableDropdown
    disableAreaCodes
    disableSearchIcon
    inputExtraProps={{
      name: name,
      required: true,
      autoFocus: true
    }}
    {...input}
    countryCodeEditable={false}
  />
);

class WizardPhoneForm extends Component {
  render() {
    const {
      handleSubmit,
      handlePreviousPage,
      isPhoneRegistered,
      isPhoneTrueFormat,
      isFetching,
      submitting
    } = this.props;
    return (
      <form  onSubmit={isFetching ? '' : handleSubmit}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
        {de_de.intro2_title}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.login_title_enter_mobile}</h2>
          <p>{de_de.logint_message_enter_mobile}</p>
          <div className="input-fields">
            <Field
              name="tel"
              type="tel"
              component={renderPhoneField}
              label={de_de.register_placeholder_username}
              className="input-id-login"
              isPhoneTrueFormat={!isPhoneTrueFormat}
              isPhoneRegistered={!isPhoneRegistered}
            />
            {!isPhoneRegistered && 
              <div>
                <span>{de_de.login_is_phone}</span>
              </div>
            }
            {!isPhoneTrueFormat &&
              <div>
                <span>{de_de.login_field_true_phone}</span>
              </div>
            }
            <Link to="/login-email" className="link-to-email">
              {de_de.login_number_handy}
            </Link>
          </div>
          <div className="submits">
            <button className="account-submit" type="submit" disabled={submitting}>
              {de_de.login_account_handy}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.LoginPhone.isFetching,
});

export default reduxForm({
  form: 'loginPhone',
  fields,
  destroyOnUnmount: false,
  validate
})(connect(mapStateToProps)(WizardPhoneForm));

const styleContainer = {
  width: '90%',
  margin: '0 auto',
};
