/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../helper';
import Coins from '../../../assets/img/coin-bg.svg';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import lang from '../../../config/languages.json';

class GetPoint extends Component {

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
  }

  render() {
    const { birthday_total_point, level_up_voucher, handleClick } = this.props;
    const { de_de } = lang[1];
    return (
      <div className="get-point-screen">
        <div className="get-point-gift-bg">
          <img src={Coins} alt="" className="responsive-img" />
          <h3>+5 {de_de.scan_text}</h3>
          <h6>{de_de.have_text}</h6>
          <h1>{birthday_total_point}</h1>
          <h6>{de_de.point_text}</h6>
          
          {
            (!!level_up_voucher === true) ? (<Button
              variant="contained"
              className="btn-points"
              onClick={handleClick.bind(this, 'is_level_up')}
            >
            {de_de.save_and_continue}
            </Button>)
              : (<Button
                variant="contained"
                className="btn-back"
                component={Link}
                to="/user/profile"
              >
                    {de_de.intro1_btn}
                </Button>)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});

export default connect(mapStateToProps)(GetPoint);