import { isValidDate } from '../../lib';

const validate = values => {
  const errors = {};
  if (!values.gender) {
    errors.gender = 'Pflichtfeld';
  } else if (values.gender.trim() === '') {
    errors.gender = 'Pflichtfeld';
  }
  if(!values.firstname) {
    errors.firstname = 'Pflichtfeld';
  } else if (values.firstname.trim() === '') {
    errors.firstname = 'Pflichtfeld';
  }
  if(!values.lastname) {
    errors.lastname = 'Pflichtfeld';
  } else if (values.lastname.trim() === '') {
    errors.lastname = 'Pflichtfeld';
  }

  if(!values.address) {
    errors.address = 'Pflichtfeld';
  } else if (values.address.trim() === '') {
    errors.address = 'Pflichtfeld';
  }
  if(!values.zipcode) {
    errors.zipcode = 'Pflichtfeld';
  } else if (values.zipcode.length < 4) {
    errors.zipcode = 'Postleitzahl muss 4 Zahlen haben';
  } else if (values.zipcode.trim() === '') {
    errors.zipcode = 'Pflichtfeld';
  }

  if(!values.city) {
    errors.city = 'Pflichtfeld';
  } else if (values.city.trim() === '') {
    errors.city = 'Pflichtfeld';
  }

  if(!values.phone) {
    errors.phone = 'Pflichtfeld';
  } else if (values.phone.trim() === '' || values.phone.trim() === '+41') {
    errors.phone = 'Pflichtfeld';
  }

  /* Phone Email Validation */
  if(!values.email) {
    errors.email = 'Pflichtfeld';
  }
  // else if (!/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(values.phone)) {
  //   errors.phone = 'Invalid phone number'
  // }
  /* End Phone and Email */

  /* Date Validation */
  if(values.day > 31 || values.day < 1) {
    errors.day = 'Invalid day';
  } else if (values.day && values.day.toString().length === 3) {
    errors.day = 'Invalid month';
  }

  if(values.month > 12 || values.day < 1) {
    errors.month = 'Invalid month';
  } else if (values.month && values.month.toString().length === 3) {
    errors.month = 'Invalid month';
  }

  if(values.year < 1900) {
    errors.year = 'Invalid year';
  }

  const month = (values.month === undefined) ? 13 : values.month;
  const day = (values.day === undefined) ? 32 : values.day;
  const year = (values.year === undefined) ? 0 : values.year;
  const date = String(month + '/' + day + '/' + year);
  if (isValidDate(date) === 'bad_day') {
    errors.year = 'Invalid year';
    errors.month = 'Invalid month';
    errors.day = 'Invalid day';
  }
  /* End Date */

  return errors;
}

export default validate;
