/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as _ from "lodash";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../helper';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { _getIdActive, _getCategories, _getCouponList } from '../../actions';
import CarouselCouponPage from '../CarouselCouponPage';
import GuestCarouselCouponPage from '../GuestCarouselCouponPage';
import { _unixTimestamp } from '../../lib';

class ListPage extends Component {

  state = {
    couponFiltered: []
  };

  componentDidMount() {
    this.props._getIdActive('');
  }

  filterCoupon(coupon, id){
      return [...coupon].filter(item => {
        return item.category_id === id;
      });  
  }

  handeSelectCategory = (id) => {
    const { _getIdActive, handleChangeCallback } = this.props;
    _getIdActive(id);
    handleChangeCallback();
  }

  render() {
    const {
      classes,
      categories,
      isShowCategoriesList,
      activeIds,
      isAuthenticated,
      coupons
    } = this.props;
    
    const couponFiltered = this.filterCoupon(coupons, _.first(activeIds));
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 2,
      arrows: false
    };
    return (
      <div>
        {(isShowCategoriesList) ?
          <div className="list-screen">
            <List component="nav" className={classes.root}>
              {categories.length !== 0 &&
                categories.sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <div
                      key={item.id}
                      className={`
                      ${activeIds.includes(item.id) === true ?
                          (isAuthenticated ? 'activated-brown' : 'activated-blue')
                          :
                          'not-activated'}
                    `}>
                      <ListItem
                        button
                        divider
                        className={classes.dimension}
                        onClick={() => this.handeSelectCategory(item.id)}
                      >
                        <ListItemText primary={item.name} className={`list-item-title ${activeIds.includes(item.id) === true ? 'text-color' : ''}`} />
                        {!activeIds.includes(item.id) &&
                          <ArrowForwardIos className={classes.icon} />
                        }
                      </ListItem>
                    </div>
                  ))
              }
            </List>
          </div>
          : (isAuthenticated ?
            <CarouselCouponPage
              settings={settings}
              data={couponFiltered}
              orderKey="created_at"
              order="desc"
            /> :
            <GuestCarouselCouponPage
              settings={settings}
              data={couponFiltered}
              orderKey="created_at"
              order="desc"
            />
          )
        }
      </div>
    )
  }
}
const ListPageStyle = withStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: '#C0A35E'
  },
  dimension: {
    height: '66px'
  }
}))(withRouter(ListPage));

const mapStateToProps = state => ({
  activeIds: state.ActiveCategoriesId.activeIds,
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getIdActive,
    _getCategories,
    _getCouponList
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ListPageStyle);
