import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { _saveImageProfile } from '../../actions';
import EXIF from 'exif-js';
import { b64toBlob, history } from '../../helper';
import { errorCodeConstants } from '../../config';
import lang from '../../config/languages.json';
import { _dataInfoTracking } from '../../lib';


import ResultUpload from './result-upload';
import UploadImage from './upload-select';

class UploadProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreview: null,
      file: null,
      page: 1,
      imageDirection: 0,
      isCordova: false
    };
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.takePhotoCordova = this.takePhotoCordova.bind(this);
    this.takeFileCordova = this.takeFileCordova.bind(this);
    this.cameraCallback = this.cameraCallback.bind(this);
    this.galleryCallback = this.galleryCallback.bind(this);
  }

  isPhoneGap() {
    return (window.cordova || window.PhoneGap || window.phonegap)
      && /^file:\/{3}[^\/]/i.test(window.location.href)
      && /ios|iphone|ipod|ipad|android/i.test(navigator.userAgent);
  }

  componentDidMount() {
    this.setState({
      isCordova: window.cordova
    });
  }

  getImageDirection = (file) => {
    EXIF.getData(file, () => {
      const orientation = EXIF.getTag(file, "Orientation");
      let direction = 0;
      switch (orientation) {
        case 8:
          direction = -90;
          break;
        case 3:
          direction = 180;
          break;
        case 6:
          direction = 90;
          break;
        default:
          return false;
      }
      this.setState({ imageDirection: direction });
    });
  }

  handleUploadImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreview: reader.result
      });
    };

    reader.readAsDataURL(file);
    this.getImageDirection(file);
    this.setState({ page: this.state.page + 1 });
  }

  onChangeTakeImage = (e) => {
    let file = e.target.files[0];
    this.getImageDirection(file);
    this.setState({
      imagePreview: file,
      file: file,
      page: this.state.page + 1
    });
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  }

  handleSave = data => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _saveImageProfile } = this.props;
    const imgURL = this.editor.getImageScaledToCanvas().toDataURL();
    const block = imgURL.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const newImage = b64toBlob(realData, contentType);
    let formData = new FormData();

    formData.append(
      'file',
      newImage,
      'test.jpg'
    );

    _dataInfoTracking(userId.id, de_de.sentry_info, de_de.sentry_profile_image_upload, errorCodeConstants.profileImageUpload);

    const imageProfile = _saveImageProfile(formData);
    imageProfile.then((res) => {
      history.push("/user/profile");
    });
  }

  handleBackToProfile = () => {
    history.push("/user/profile");
  }

  handlePreviousPage = () => {
    this.setState({ page: this.state.page - 1 });
  }

  cameraCallback(imageData) {
    const imageSrc = "data:image/jpeg;base64," + imageData;
    this.setState({
      imagePreview: imageSrc,
      page: 2
    });
  }

  galleryCallback(imageData){
    const imageSrc = "data:image/jpeg;base64," + imageData;
    this.setState({
      imagePreview: imageSrc,
      page: 2
    });
  }

  galleryError(err){
    alert('Can\'t get photo, please try again !');
    console.log(err);
  }

  cameraError(err){
    alert('Can\'t take photo, please try again !');
    console.log(err);
  }

  takePhotoCordova(){
    navigator.camera.getPicture(this.cameraCallback, this.cameraError, {
      sourceType: 1,
      allowEdit: false,
      correctOrientation: true,
      quality: 100,
      destinationType: 0
    });
    console.log('takePhoto');
  }

  takeFileCordova(){
    navigator.camera.getPicture(this.galleryCallback, this.galleryError, {
      sourceType: 0,
      allowEdit: false,
      correctOrientation: true,
      quality: 100,
      destinationType: 0
    });
    console.log('takeFile');
  }

  render() {
    const { isFetching } = this.props;
    const { imagePreview, page, imageDirection, isCordova } = this.state;
    const disable = isFetching ? true : false;
    return (
      <div className="upload-profile-container">
        {page === 1 &&
          <UploadImage
            handleUploadImage={this.handleUploadImage}
            handlePreviousPage={this.handleBackToProfile}
            onChangeTakeImage={this.onChangeTakeImage}
            takePhotoCordova={this.takePhotoCordova}
            takeFileCordova={this.takeFileCordova}
            isCordova={isCordova}
          />
        }
        {page === 2 &&
          <ResultUpload
            handleSaveProfilePicture={this.handleUploadImage}
            src={imagePreview}
            imageDirection={imageDirection}
            setEditorRef={this.setEditorRef}
            handleSave={this.handleSave}
            handlePreviousPage={isFetching ? null : this.handlePreviousPage}
            disabled={disable}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.imageProfile.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _saveImageProfile
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UploadProfile);
