/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Progress from './progress';
import imgBird from '../../assets/img/bird.png';
// import device from 'current-device';
// import { constantConfig } from '../../config';

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        setSplashScreen: true,
        completed: 0
      };
    }

    componentDidMount() {
      // After getting the API response from server
      this.timer = setInterval(this.progress, 500);
      // if (device.mobile()) {
      //   this.timer = setInterval(this.progress, 500);
      // } else {
      //   window.location.replace(constantConfig.URL_REDIRECT)
      // }
    }

    componentWillUnmount() {
      clearInterval(this.timer);
    }

    progress = () => {
      const { completed } = this.state;
      if (completed === 100) {
        this.setState({
          setSplashScreen: false,
          completed: 0
        });
      } else {
        const diff = Math.random() * 150;
        this.setState({
          completed: Math.min(completed + diff, 100)
        });
      }
    };

    render() {
      const { setSplashScreen, completed } = this.state;
      if (setSplashScreen)
        return (<div className='splash-screen-container'>
          <Progress
            completed={completed}
          />
          <div className='splash-screen'>
            <img src={imgBird} className='img-bird responsive-img' alt='' />
          </div>
        </div>);
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withSplashScreen;
