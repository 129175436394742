/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import CouponItem from './Item';
import lang from '../../../config/languages.json';

const CouponScreen = (props) => {
  const { handleClick, is_birthday } = props;
  const isText = is_birthday ? 'is_get_point_birthday' : 'is_get_point_level_up';
    const { de_de } = lang[1];
  return (
    <div className="coupon-screen">
      <div className="coupon-gift-bg">
       <CouponItem 
        confirmed={false}
        type="green"
        expire={de_de.kaffee_years}
       />
      </div>
      <Button
        variant="contained"
        className="btn-points"
        onClick={handleClick.bind(this, isText)}
      >
        {de_de.save_and_continue}
      </Button>
    </div>
  );
}

export default CouponScreen;