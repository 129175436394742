/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as _ from "lodash";
import { history } from '../../helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Header from '../shared/back-button';
import CarouselCouponLatest from '../CarouselCoupon';
import CarouselCouponByView from '../CarouselCouponByView';
import News from '../News';
import IconDocs from '../../assets/img/docs.svg';
import SampleCard from '../../assets/img/shoppypass-card-back.png';
import Preloader from '../Preloader';
import { _getUserProfile, _getMall, _getShoppingList } from '../../actions';
import { _getOpeningTime, _getLastedNews } from '../../actions/guest';
import { _getLocation, _getDistance, _dataInfoTracking, _createStringFromTemplate } from '../../lib';
import lang from '../../config/languages.json';
import { settingContants, errorCodeConstants } from '../../config';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';
class MyPage extends Component {
  
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      distance: null
    }
  }

  componentDidMount() {
    TagManager.dataLayer(tagManagerArgs);
    this._isMounted = true;
    const { isAuthenticated, _getUserProfile, _getOpeningTime, _getLastedNews, _getMall, _getShoppingList } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getUserProfile();
    _getOpeningTime();
    _getLastedNews();
    _getMall();
    _getShoppingList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _getCheckIn = async () => {
    const { isProfile } = this.props;
    const userId = isProfile && isProfile.id;
    const { statusTimeNow } = this.props.openTime;
    const { distance } = this.state;
    const { de_de } = lang[1];

    let newDistance = distance;
    const latMall = this.props.isMall.data.latitude;
    const lngMall = this.props.isMall.data.longitude;
    await _getLocation()
      .then((res) => {
        const { coords: { latitude, longitude } } = res;
        const distanceUser = _getDistance(latitude, longitude, latMall, lngMall);
        
        newDistance = distanceUser;

        if (newDistance < settingContants.kmFromMall && statusTimeNow === 'open') {
          _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_checkin_success, errorCodeConstants.checkinSuccessul);
          history.push('/user/points');
        }
    
        if (newDistance < settingContants.kmFromMall && statusTimeNow === 'closed') {
          _dataInfoTracking(userId, de_de.sentry_warning, de_de.sentry_checkin_out_of_opening_time, errorCodeConstants.checkinFailOutOfOpeningTime);
          history.push('/user/outside');
        }
        if (newDistance > settingContants.kmFromMall) {
          _dataInfoTracking(userId, de_de.sentry_warning, de_de.sentry_checkin_out_of_range, errorCodeConstants.checkinFailOutOfRang);
          history.push('/user/outside');
        }
        this._isMounted && this.setState({
          distance: newDistance
        });
      })
      .catch((err) => {
        _dataInfoTracking(userId, de_de.sentry_warning, de_de.sentry_location_deactivate, errorCodeConstants.locationDeactivate);
        console.log(err)
      });
  }

  render() {
    const { isProfile, isFetching, openTime, news, shoppingList } = this.props;
    const point = isProfile && isProfile.point;
    const level = isProfile && isProfile.level;
    const firstName = isProfile && isProfile.firstname;
    const lastName = isProfile && isProfile.lastname;
    const isExtra10Points = isProfile && isProfile.extra_10_points;
    const userId = isProfile && isProfile.id;
    localStorage.setItem('id', userId);
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 1,
      arrows: false
    };
    const { de_de } = lang[1];
    return (
      <div className="main-page">
        <Header to="" title="shoppyland" />
        <div className="main-screen">
          <div className="my-page-container">
            {isFetching ? <Preloader /> :
              <React.Fragment>
                <div className="my-page-screen">
                  <div className="my-page-tabs">
                    <Link to="/user/profile">
                      <div className="tab-inside">
                        <div className={`c100 p${point} dark small`}>
                          <span>{point}<br />/100</span>
                          <div className="slice">
                            <div className="bar"></div>
                            <div className="fill"></div>
                          </div>
                        </div>
                        <div className="title">{de_de.level_text}<br /> {level}</div>
                      </div>
                    </Link>
                    <Link to="/user/shopping-list">
                      <div className="tab-inside">
                        <div className="icon">
                          <img src={IconDocs} alt="" />
                        </div>
                        <div className="title">{de_de.shopping}<br /> {_.get(shoppingList, 'data.length', 0)}</div>
                      </div>
                    </Link>
                  </div>
                  <div className="section-1">
                    <div className="sample-card">
                      <Link to='/shoppy-pass'>
                        <img src={SampleCard} alt="" />
                      </Link>
                    </div>
                    <h1>{de_de.hallo_text} {firstName} {lastName}!</h1>
                    {/* <p>Wir haben heute von <strong>09:00</strong> bis <strong>20:00</strong> Uhr für Sie geöffnet.</p> */}
                    {openTime.status === 'open' ?
                      <p>{de_de.opentime_first_text} <strong>{openTime.timeFrom}</strong> {de_de.opentime_mid_text} <strong>{openTime.timeTo}
                      </strong> {de_de.opentime_last_text}
                      </p>
                      :
                      /*<p>{de_de.shop_close}</p>*/
                      <p></p>
                    }
                    <Button
                      variant="contained"
                      className="btn-sign-up"
                      // component={Link}
                      // to="/user/points"
                      onClick={this._getCheckIn}
                    >
                      {de_de.checkin2_text}
                    </Button>
                    {!isExtra10Points && <span className="text-warning">{de_de.checkin_small_text}</span>}
                  </div>
                  <div className="section-2">
                    <CarouselCouponLatest
                      title={de_de.voucher_header}
                      settings={settings}
                      orderKey="id"
                      order="desc"
                      view={false}
                    />
                  </div>
                  <div className="section-3">
                    <CarouselCouponByView
                      title={de_de.header_offers}
                      settings={settings}
                      orderKey="view"
                      order="desc"
                    />
                  </div>
                  <div className="section-4">
                    <News news={news.News} url={news.NewsUrl} />
                  </div>
                </div>
              </React.Fragment>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isProfile: state.profile.data.data,
  isFetching: state.profile.isFetching,
  openTime: state.Guest.dataOpeningTime,
  news: state.Guest.dataLastedNews,
  isMall: state.getMall,
  shoppingList: state.shoppingList,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getUserProfile,
    _getOpeningTime,
    _getLastedNews,
    _getLocation,
    _getMall,
    _getShoppingList
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(MyPage);
