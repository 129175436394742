import Cookies from 'js-cookie';
import { COOKIE_APP_SUGGESTION_NAME } from '../config';

import { alertConstants } from '../config';

export const getData = ($type) => {
  return {
    type: $type
  };
};

export const getDataSuccess = ($type, data) => {
  return {
    type: $type,
    payload: data,
  };
};

export const getDataFailure = ($type, error) => {
  return {
    type: $type,
    payload: error,
  };
};

export const _clear = () => ({
  type: alertConstants.CLEAR
});

export const _logOut = () => {
  localStorage.removeItem('bonus_app_tk');
  localStorage.removeItem('del');
  localStorage.removeItem('delall');
  localStorage.removeItem('c');
  localStorage.removeItem('cp');
  localStorage.removeItem('v');
  localStorage.removeItem('id');
  Cookies.remove(COOKIE_APP_SUGGESTION_NAME);
  return {
    type: alertConstants.LOGOUT
  }
};
