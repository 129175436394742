import {
  alertConstants,
} from '../config';

export const _getIdActive = (id) => {
  return {
    type: alertConstants.SAVE_CATEGORY_ID_ACTIVE,
    id
  }
}
