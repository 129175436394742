import React from 'react';
import { Link } from 'react-router-dom';
import "./back-button.scss";

const BackButton = (props) => {
  const {onClick} = props;
  const isTo = props.hasOwnProperty('to')
   
  return isTo ? (<div  className="back-button">
    <Link to={props.to} />
      <p>{props.title}</p>
    </div>) : 
    (<div  className="back-button" onClick={onClick}>
      <i className="icon-back"></i>
      <p>{props.title}</p>
    </div>)

}

export default BackButton;
