import {
  $fetch,
  alertConstants,
  methodConstants,
  endpointContants
} from '../config';

export const loginEmail = (user = {}, postCode = false) => {
  let apiRoute = endpointContants.ENDPOINT_LOGIN_BY_EMAIL_AND_BIRTHDAY;
  if (postCode) {
    apiRoute = endpointContants.ENDPOINT_LOGIN_BY_EMAIL_AND_POSTCOST;
  }
  return $fetch(
    endpointContants.ROOT_URL,
    apiRoute,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_EMAIL,
    alertConstants.FETCH_DATA_SUCCESS_EMAIL,
    alertConstants.FETCH_DATA_FAILURE_EMAIL,
    user,
    false
  );
};

export const checkEmailOrPostCode = (email = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_CHECK_EMAIL_OR_POST_CODE,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE,
    alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE_SUCCESS,
    alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE_FAILURE,
    email,
    false
  );
};

export const getOTPCode = (phone = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GET_OTP,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_GET_OTP,
    alertConstants.FETCH_DATA_SUCCESS_GET_OTP,
    alertConstants.FETCH_DATA_FAILURE_GET_OTP,
    phone,
    false
  );
};

export const loginPhone = (user = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_LOGIN_BY_PHONE_AND_OTP,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_PHONE,
    alertConstants.FETCH_DATA_SUCCESS_PHONE,
    alertConstants.FETCH_DATA_FAILURE_PHONE,
    user,
    false
  );
 
};

export const uploadProfile = (data) => {
  return $fetch(
    endpointContants.ROOT_URL,
    '/api/users/uploadPhoto',
    methodConstants.POST,
    alertConstants.UPLOAD_PHOTO,
    alertConstants.UPLOAD_PHOTO_SUCCESS,
    alertConstants.UPLOAD_PHOTO_FAILURE,
    data,
    true
  );
};

export const getError = (state, id) => state.error;
export const getErrorEmail = (state, id) => state[id];
