/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Header from '../shared/back-button';

import Carousel from '../GuestCarouselCouponPage';
import KategorieList from '../Kategorie';

import { _getIdActive } from '../../actions';
import { _unixTimestamp } from '../../lib';
import lang from '../../config/languages.json';

import {
  _getGuestCoupons,
  _getGuestCategories,
  _cleanUnusedQuestCategories
} from '../../actions/guest';

function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    width: "100%",
  },
  tabRoot: {
    color: '#5A5A5A',
    "&$tabSelected": {
      color: "#C0A35E",
    },
    "&:focus": {
      color: "#C0A35E"
    }
  },
  tabSelected: {},
  indicator: {
    backgroundColor: "transparent",
  }
});

class Angebote extends Component {
  state = {
    value: 0,
    isShowCategoriesList: true,
  };

  async componentDidMount() {
    const { _getGuestCoupons, _getGuestCategories, _cleanUnusedQuestCategories } = this.props;
    await _getGuestCoupons();
    await _getGuestCategories();
    await _cleanUnusedQuestCategories();
  }

  handleChange = (event, value) => {
    this.setState({ value, isShowCategoriesList: true });
  };

  handeSelectCategory = () => {
    this.setState({ isShowCategoriesList: false });
  }

  render() {
    const { classes, coupons, categories } = this.props;
    const { value, isShowCategoriesList } = this.state;
    const { de_de } = lang[1];
    
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 2,
      arrows: false
    };

    return (
      <div className="main-page">
        <Header to="" title={de_de.deals} />
        <div className="main-screen">
          <div className="angebote guest-container">
            <div className="guest-screen">
              <div className="section-2 guest-mode">
                <div className={classes.root}>
                  <AppBar position="fixed" color="default" className="tabs-header">
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      classes={{ indicator: classes.indicator }}
                      scrollButtons="auto"
                      className="tabs-container"
                      variant="fullWidth"
                    >
                      <Tab 
                        label={de_de.tabs}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                      <Tab 
                        label={de_de.tabs_beliebt}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                      <Tab 
                        label={de_de.tabs_kategorie}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      />
                    </Tabs>
                  </AppBar>
                  {value === 0 &&
                    <TabContainer>
                      <Carousel
                        settings={settings}
                        type="coupon"
                        data={coupons}
                        orderKey="id"
                        order="desc"
                      />
                    </TabContainer>}
                  {value === 1 &&
                    <TabContainer>
                      <Carousel
                        settings={settings}
                        data={coupons}
                        orderKey="view"
                        order="desc"
                      />
                    </TabContainer>}
                  {value === 2 &&
                    <TabContainer>
                      <KategorieList
                        categories={categories}
                        coupons={coupons}
                        isShowCategoriesList={isShowCategoriesList}
                        handleChangeCallback={this.handeSelectCategory}
                      />
                    </TabContainer>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  coupons: state.Guest.dataCoupons,
  categories: state.Guest.dataCategories,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getGuestCoupons,
    _getGuestCategories,
    _getIdActive,
    _cleanUnusedQuestCategories
  }, dispatch)
);

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Angebote)));
