/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import ThumbNail from '../../../assets/img/thumbnail.jpg';
import ItemLogo from '../../../assets/img/item-logo.jpg';
import { _getTime } from '../../../lib';
import Timer from '../../CoundownTimer';

class Item extends Component {

  constructor(props){
    super(props);
    this.state = {
      isExpired: false
    }
    this.handleExpiration = this.handleExpiration.bind(this);
  }

  handleExpiration(){
    this.setState({
      isExpired: true
    })
  }

  render(){
    let expired;
    const { classes, data, redemption, expiration, handleDeleteCoupon, isQuestMode, isAuthenticated} = this.props;
    const { isExpired } = this.state;
    const type = isExpired ? 'disabled' : !isQuestMode ? expiration || redemption === null ? !isExpired ? 'gold' : 'disabled' : 'disabled' : expiration || redemption === null ? !isExpired ? '' : 'disabled' : 'disabled';
    //const couponDefault = expiration || redemption === null ? '' : 'disabled';
    const { id, start_date, end_date, image, name, description, shop, mall, teaser } = data;
    const userId = localStorage.getItem('id');
  if (redemption) {
    expired = expiration && !isExpired ? (<div className="expire">
      {!isExpired && <Timer 
        expiration={expiration}
        handleExpiration={this.handleExpiration }
      />}
      {isExpired && <span>Eingelöst</span>}
    </div>) 
    :  (
        <React.Fragment>
          <div className="expire">
          {!isExpired && <Timer 
        expiration={expiration}
        handleExpiration={this.handleExpiration }
      />}
      {isExpired && <span>Eingelöst</span>}
          </div>
          <div className="expire">
            <Button 
              size="small" 
              className="btn-delete"
              onClick={handleDeleteCoupon.bind(this, id)}
            >
              <DeleteIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
              Löschen
            </Button>
          </div>
        </React.Fragment >
      ) 
  } else {
    expired = (<div className="date-expire">Gültig: {_getTime(!!start_date && start_date).date} - {_getTime(!!end_date && end_date).date}</div>)
  }

  return (
    <Link to={isAuthenticated ? {
      pathname: `/user/coupon/detail/${id}`,
      state: {
        email: userId
      }
    } : '#'}>
      <div className="coupon carousel-item">
        <div className={`carousel-view ${type}`}>
          <div className="item-content">
            <div className="item-column">
              <div className="thumbnail">
                {shop !== null &&
                  <img src={shop.logo} alt="" className="responsive-img" />
                }
              </div>
            </div>
            <div className="item-column"></div>
          </div>
          <div className="item-content">
            <div className="item-column">
              <h2>{name}</h2>
            </div>
            <div className="item-column">
              <div className="thumbnail" style={{backgroundImage:  `url(${image})`}}>
              </div>
            </div>
          </div>
          <div className="item-content">
            {teaser !== null &&
              <div className="item-description">
                {teaser}
              </div>
            }
            <div className="item-date-expired">
              {expired}
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    </Link>
  );
}
}

function DeleteIcon(props) {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1206 2.87513H11.0787V0.632045C11.0787 0.296902 10.7987 0.0249023 10.4537 0.0249023H4.54839C4.20339 0.0249023 3.92339 0.296902 3.92339 0.632045V2.87513H0.879639C0.534639 2.87513 0.254639 3.14713 0.254639 3.48228C0.254639 3.81742 0.534639 4.08942 0.879639 4.08942H1.72776V15.2484C1.72776 16.2004 2.52526 16.9751 3.50526 16.9751H11.4965C12.4759 16.9751 13.2728 16.2007 13.2728 15.2484V4.08942H14.1206C14.4656 4.08942 14.7456 3.81742 14.7456 3.48228C14.7456 3.14713 14.4656 2.87513 14.1206 2.87513ZM12.0228 4.08942H13.2728V3.77603C13.2728 3.44059 12.9928 3.16889 12.6478 3.16889C12.3028 3.16889 12.0228 3.44089 12.0228 3.77603V4.08942ZM2.97776 4.08942H1.72776V3.77603C1.72776 3.44089 2.00776 3.16889 2.35276 3.16889C2.69776 3.16889 2.97776 3.44089 2.97776 3.77603V4.08942ZM2.97776 4.08942V15.2484C2.97776 15.531 3.21433 15.7608 3.50526 15.7608H11.4965C11.7868 15.7608 12.0228 15.531 12.0228 15.2484V4.08942H2.97776ZM6.6137 13.57V5.4904C6.6137 5.15526 6.3337 4.88326 5.9887 4.88326C5.6437 4.88326 5.3637 5.15526 5.3637 5.4904V13.57C5.3637 13.9051 5.6437 14.1771 5.9887 14.1771C6.3337 14.1771 6.6137 13.9054 6.6137 13.57ZM9.63745 13.57V5.4904C9.63745 5.15526 9.35745 4.88326 9.01245 4.88326C8.66745 4.88326 8.38745 5.15526 8.38745 5.4904V13.57C8.38745 13.9051 8.66745 14.1771 9.01245 14.1771C9.35745 14.1771 9.63745 13.9054 9.63745 13.57ZM5.17339 2.87513H9.82839V1.23919H5.17339V2.87513Z" fill="#C0A35E" />
    </svg>

  );
}

export default withStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  }
}))(Item);
