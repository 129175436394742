import {
  $fetch,
  alertConstants,
  methodConstants,
  endpointContants
} from '../config';

export const _getOpeningTime = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_OPENING_TIME,
    methodConstants.GET,
    alertConstants.GET_OPENING_TIME,
    alertConstants.GET_OPENING_TIME_SUCCESS,
    alertConstants.GET_OPENING_TIME_FAILURE,
  );
};

export const _getLastedNews = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_GET_LASTED_NEWS,
    methodConstants.GET,
    alertConstants.GET_LASTED_NEWS_DATA,
    alertConstants.GET_LASTED_NEWS_DATA_SUCCESS,
    alertConstants.GET_LASTED_NEWS_DATA_FAILURE,
  );
};

export const _getGuestCoupons = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_COUPONS_LIST,
    methodConstants.GET,
    alertConstants.GET_COUPON_IN_GUEST_DATA,
    alertConstants.GET_COUPON_IN_GUEST_DATA_SUCCESS,
    alertConstants.GET_COUPON_IN_GUEST_DATA_FAILURE,
  );
};

export const _getGuestCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_GET_COUPON + id,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_GET_COUPON,
    alertConstants.FETCHING_DATA_GET_COUPON_SUCCESS,
    alertConstants.FETCHING_DATA_GET_COUPON_FAILURE,
  );
};

export const _getGuestCategories = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_CATEGORIES_LIST,
    methodConstants.GET,
    alertConstants.GET_CATEGORIES_IN_GUEST_DATA,
    alertConstants.GET_CATEGORIES_IN_GUEST_DATA_SUCCESS,
    alertConstants.GET_CATEGORIES_IN_GUEST_DATA_FAILURE,
  );
};

export const _deleteGuestCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_DELETE_COUPON + `${id}/redeemed`,
    methodConstants.DELETE,
    alertConstants.FETCHING_DATA_COUPON_DELETE,
    alertConstants.FETCHING_DATA_COUPON_DELETE_SUCCESS,
    alertConstants.FETCHING_DATA_COUPON_DELETE_FAILURE,
  );
};

export const _getGuestRedeemCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GUEST_GET_COUPON + `${id}/redeem`,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_REDEEM_COUPON,
    alertConstants.FETCHING_DATA_REDEEM_COUPON_SUCCESS,
    alertConstants.FETCHING_DATA_REDEEM_COUPON_FAILURE,
  );
};

export const _cleanUnusedQuestCategories = () => {
  return {
    type : alertConstants.CLEAN_UNUSED_QUEST_CATEGORY
  }
}
