/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../shared/back-button';
import Button from '@material-ui/core/Button';
import lang from '../../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../../index';

const GetPoint = (props) => {
  const { handleClick, isAuthenticated, isDataPoints } = props;
  const checkLevelUpVoucher = !isDataPoints.hasOwnProperty('level_up_voucher') ? 'is_get_point_level_up' : 'is_show_point_level_up';
  const { de_de } = lang[1];
  
  TagManager.dataLayer(tagManagerArgs);

  return (
    <div className="main-page">
      <Header to="" title={de_de.point} />
      <div className="congras-screen main-screen">
        <div className="congras-screen">
          <div className="congras-gift-bg">
            <h2>{de_de.congras_title}</h2>
            <h6>{de_de.youhavenow}</h6>
            <h1>100</h1>
            <h6>{de_de.point_reach_text}</h6>
          </div>
          <Button
            variant="contained"
            className="btn-points"
            onClick={handleClick.bind(this, checkLevelUpVoucher)}
          >
            {de_de.birthday_btn_gift}
      </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isDataPoints: state.checkin.data,
  isFetching: state.checkin.isFetching
});

export default connect(mapStateToProps)(GetPoint);