import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
//const middleWare = [process.env.NODE_ENV === 'development' ? applyMiddleware(ReduxThunk, logger) : applyMiddleware(ReduxThunk)];

// const store = createStore(rootReducer, middleWare);

// export default store;

const middleware = [thunk];

const store = createStore(rootReducer,
                 composeWithDevTools(applyMiddleware(...middleware)));

  export default store;