/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../helper';
import { withRouter } from 'react-router-dom';
import Header from '../../shared/back-button';
import Button from '@material-ui/core/Button';
import { _createShoppingItem } from '../../../actions';
import lang from '../../../config/languages.json';
import { _dataInfoTracking } from '../../../lib';
import { errorCodeConstants } from '../../../config';
import * as _ from 'lodash';
class AddItem extends Component {

  constructor(props) {
    super(props);
    this.handleCreateItemButton = this.handleCreateItemButton.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      value: '',
      // itemId: null,
      // itemName: null,
      // itemCreatedDate: null,
    }
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    // const { match: { params : { info } } } = this.props;
    // const decodedParams = info ? atob(info) : '';
    // const params = decodedParams.split('/');
    // this.setState({
    //   itemId: params[0],
    //   itemName: params[1],
    //   itemCreatedDate: params[2],
    // })
    if (!isAuthenticated) {
      history.push('/login');
    }
    this.articleItem.focus();
  }

  handleOnChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  async handleCreateItemButton() {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _createShoppingItem } = this.props;
    const { value } = this.state;
    const { match: { params : { info } } } = this.props;
    const decodedParams = info ? atob(info) : '';
    const params = decodedParams.split('/');
    const itemId = params[0];
    const itemName= params[1];
    const itemCreatedDate = params[2];
    await _createShoppingItem(itemId, {
      name: value
    });

    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_entry_added, errorCodeConstants.entryAdd);

    history.push({
      pathname: `/shopping/list-added/${btoa(`${itemId}/${itemName}/${itemCreatedDate}`)}`,
    });
  }

  goBack() {
    history.goBack();
  }

  render() {
    const { id } = this.props.match.params;
    const { isFetching } = this.props;
    const { de_de } = lang[1];

    const disable = isFetching ? true : false;
    return (
      <div className="article main-page">
        <Header 
          // to={`/shopping/list-added/${id}`} 
          title={de_de.add_article_title}
          onClick={this.goBack}
        />
        <div className="main-screen">
          <div className="add-item-container">
            <div className="add-item-screen">
              <form>
                <input 
                  type="text" 
                  className="outlined-bare" 
                  placeholder={de_de.add_article} 
                  onChange={this.handleOnChange}
                  ref={(input) => this.articleItem = input}
                  maxLength = "100"
                />
                <Button
                  variant="contained"
                  className="btn-points"
                  disabled={disable}
                  // component={Link}
                  // to="list-added"
                  onClick={this.handleCreateItemButton}
                >
                  {de_de.save_article}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isFetching: state.createItem.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ 
    _createShoppingItem
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddItem));