/* eslint-disable no-unused-vars */
// export const _setTimeExpire = () => {
//   const exhrs = JSON.parse(localStorage.getItem('bonus_app_tk'));
//   exhrs && setTimeout(function () {
//     localStorage.removeItem('bonus_app_tk');
//   }, 1000 * 60 * (exhrs.expires_in / 60));
// };

export const _getTime = (data) => {
  let d = new Date(data)
  if(Number.isNaN(d.getMonth())) {
    let arr = data.split(/[- :]/);
    d = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  }
  const year = d.getFullYear();
  const month = d.getMonth() >= 10 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  const day = d.getDate() >= 10 ? d.getDate() : `0${d.getDate()}`;
  const hours = d.getHours() >= 10 ? d.getHours() : `0${d.getHours()}`;
  const min = d.getMinutes() >= 10 ? d.getMinutes() : `0${d.getMinutes()}`;
  const sec = d.getSeconds() >= 10 ? d.getSeconds() : `0${d.getSeconds()}`;
  const _date = `${day}.${month}.${year}`;
  const _time = `${hours}:${min}:${sec}`;
  return {
    time: _time,
    date: _date
  };
};

export const _unixTimestamp = (redeemed_at, expire_at) => {
  let distant;
  const expire = new Date(expire_at).getTime();
  const redeemed = new Date(redeemed_at).getTime();
  const now =  Date.now();
  if (expire - now > 0) {
    distant = expire - now;
  } else {
    distant = 0;
  }
  
  return distant;
};
