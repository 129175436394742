/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import Header from '../shared/back-button';
import Header from '../shared/guest-back-button';
import DialogConfirm from '../DialogCoupon';
import Barcode from '../../assets/img/barcode.svg';
import { _getGuestCoupon, _getGuestRedeemCoupon } from '../../actions';
import { _getTime, _unixTimestamp } from '../../lib';
import Preloader from '../Preloader';
import Timer from '../CoundownTimer';
import JSBarCode from 'jsbarcode';
import lang from '../../config/languages.json';


class GuestCouponDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      confirmed: false,
      open: false,
      redemption: null,
      expired: false
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { _getGuestCoupon, match: { params: { id } } } = this.props;
    if (this.props.isCoupon.data === prevProps.isCoupon.data) {
      await _getGuestCoupon(id);
      const { expired } = this.state;
      const { barcode, redemption } = this.props.isCoupon.data;
      const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
    
      if (barcode && !expired && minuteMount > 0) {
        JSBarCode("#barcode", barcode, {
          format: "EAN13",
          textMargin: 0,
          fontOptions: "normal",
          width: 2,
          height: 50,
          displayValue: true,
          fontSize: "13px",
        });
      }
    }
  }

  async componentDidMount() {
    const { _getGuestCoupon, match: { params: { id } } } = this.props;
    await _getGuestCoupon(id);

    const { expired } = this.state;
    const { barcode, redemption } = this.props.isCoupon.data;
    const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
    
    if (barcode && !expired && minuteMount > 0) {
      JSBarCode("#barcode", barcode, {
        format: "EAN13",
        textMargin: 0,
        fontOptions: "normal",
        width: 2,
        height: 50,
        displayValue: true,
        fontSize: "13px",
      });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleClickConfirm = async (id) =>  {
    await this.props._getGuestRedeemCoupon(id)
    this.setState({
      // confirmed: true,
      open: false
    });
  }

  handleExpiration = () =>  {
    this.setState({
      expired: true
    })
  }

  render() {
    const { id, name, image, description, start_date, end_date, redemption, barcode } = this.props.isCoupon.data;
    const { isFetching } = this.props.isCoupon;
    const { confirmed, open, expired } = this.state;
    const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
    const { de_de } = lang[1];
    return (
      <div className="voucher main-page">
        <Header to="" title={de_de.deals} />
        <div className="main-screen">
          {isFetching ? <Preloader /> :
            <div className="voucher-container">
              <div className="voucher-screen">
                <div className="section-1">
                  <div className="voucher-photo">
                    <img src={image} alt="" className="responsive-img" />
                  </div>

                  <div className="content">
                    <h1>{name}</h1>
                    <div className="content-inside">{description}</div>
                    <div className="voucher-date-expired">{de_de.valid_text} {_getTime(!!start_date && start_date).date} - {_getTime(!!end_date && end_date).date}</div>
                    {
                      redemption ? (
                        <React.Fragment>
                          { barcode && !expired && minuteMount > 0 ?
                            (<div className="barcode">
                            {/* <img src={barCodeImg} alt="" className="responsive-img" /> */}
                            <svg id="barcode" className="responsive-img"></svg>
                            </div>) : ''
                        }
                          <div className="time-expired">
                            <Timer
                              expiration={minuteMount}
                              handleExpiration={this.handleBinding}
                            />
                          </div>
                        </React.Fragment>
                      )
                        : <DialogConfirm
                          handleConfirm={this.handleClickConfirm.bind(this, id)}
                          handleClickOpen={this.handleClickOpen}
                          handleClose={this.handleClose}
                          open={this.state.open}
                          id={id}
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isCoupon: state.couponDetail
});


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getGuestCoupon,
    _getGuestRedeemCoupon
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(GuestCouponDetail);
