import { combineReducers } from 'redux';
import { reducer as formReducer} from 'redux-form';
import DataReducer from './data.reducer';
import { LoginEmail, LoginPhone } from './login';
import MenuList from './menu';
import { GetOTP, VerifyOTP } from './otp';
import Register from './register';
import CheckInReducer from './checkin.reducer';
import { ProfileReducer , UploadPhoto } from './profile.reducer';
import UpdateProfile from './update-profile';
import GetShoppingList from './shopping.list.reducer';
import GetShoppingListItem from './shopping.list.item.reducer';
import GetVoucherList from './get.voucher.list.reducer';
import GetVoucher from './get.voucher.reducer';
import RedeemVoucher from './redeem.voucher.reducer';
import GetCoupon from './get.coupon.list.reducer';
import GetCouponDetail from './get.coupon.reducer';
import RedeemCoupon from './redeem.coupon.reducer';
import GetNextLevelVoucher from './next.level.voucher.reducer';
import Categories from './get.categories';
import Guest from './guest';
import ActiveCategoriesId from './active.filter.categories';
import getMall from './get.mall.reducer';
import createShoppingList from './shopping.list.create.reducer';
import createItemList from './shopping.item.create.reducer';
import saveImageProfile from './save.image.profile.reducer';

const rootReducer = combineReducers({
  ActiveCategoriesId,
  res: DataReducer,
  form: formReducer,
  LoginEmail,
  LoginPhone,
  MenuList,
  Register,
  checkin: CheckInReducer,
  GetOTP,
  Guest,
  VerifyOTP,
  profile: ProfileReducer,
  UpdateProfile,
  UploadPhoto,
  shoppingList: GetShoppingList,
  shoppingListItem: GetShoppingListItem,
  vouchers: GetVoucherList,
  details: GetVoucher,
  redeem: RedeemVoucher || RedeemCoupon,
  coupon: GetCoupon,
  couponDetail: GetCouponDetail,
  nextLevel: GetNextLevelVoucher,
  Categories,
  getMall,
  createList: createShoppingList,
  createItem: createItemList,
  imageProfile: saveImageProfile
});

export default rootReducer;
