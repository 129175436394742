import {
  alertConstants
} from '../config';

const INITIALSTATE = {
  activeIds: [],
};

export default (state = INITIALSTATE, action) => {
  switch (action.type) {
    case alertConstants.SAVE_CATEGORY_ID_ACTIVE:
      if (state.activeIds.includes(action.id)) {
        return state;
      }
      return {
        ...state,
        activeIds: [ action.id],
        isFetching: true
      };
    default:
      return state;
  }
};
