/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Button } from '@material-ui/core';
import { Fab } from '@material-ui/core';

import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';

class ResultUpload extends Component {

  render() {
    const { src, setEditorRef, handleSave, handlePreviousPage, imageDirection, disabled } = this.props;
    const { de_de } = lang[1];
    return (
      <div>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
        {de_de.upload_text}
          </Fab>
        </div>
        <div className="upload-content clear-padding">
          <Dropzone
            disableClick
            multiple={false}
            className="section-drop"
          >
            <AvatarEditor
              ref={setEditorRef}
              image={src}
              scale={1.2}
              width={200}
              height={200}
              borderRadius={100}
              rotate={imageDirection}
              style={{width: '100%', height: 'auto'}}
              className="editor-canvas"
            />
          </Dropzone>
          <div className="section-2 save-photo">
            <Button
              variant="contained"
              className="btn-upload"
              disabled={disabled}
              onClick={handleSave}
            >
                  {de_de.upload_save}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultUpload;
