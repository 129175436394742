import React from 'react';

const BackIcon = (props) => (
  <svg className={props.className} width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="9" height="15">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.000488281 0.000488281H8.45113V14.7358H0.000488281V0.000488281Z" fill="white"/>
    </mask>
    <g mask="url(#mask0)">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.51594 14.7357C7.27564 14.7357 7.03714 14.6457 6.85444 14.4666L0.274538 8.01662C-0.0908617 7.65838 -0.0908617 7.07779 0.274538 6.71956L6.85444 0.268676C7.21894 -0.0895588 7.81204 -0.0895588 8.17654 0.268676C8.54284 0.626912 8.54284 1.2075 8.17654 1.56574L2.25904 7.36809L8.17654 13.1696C8.54284 13.5278 8.54284 14.1093 8.17654 14.4666C7.99384 14.6457 7.75534 14.7357 7.51594 14.7357Z" fill="white"/>
    </g>
    </svg>
);

export default BackIcon;
