/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import lang from '../../config/languages.json';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

class News extends Component {
  handleGetMoreNews = (link) => {
    window.open(link, "_blank");
  }

  render() {
    const { news, url, isAuthenticated } = this.props;
    const { de_de } = lang[1];
    return (
      <div className="news-container">
        <h1>{de_de.news_header}</h1>
        <div className="news-screen">
          {news && news.length !== 0 &&
            news.map((item) => (
              <div className="card" key={item.url}>
                <div className="card-media">
                  { item.image_source &&
                    <img src={item.image_source} alt="" className="responsive-img" />
                  }
                </div>
                <div className={isAuthenticated ? "card-content" : "card-conten-guest"}>
                  {item.title && item.url &&
                    <div className="link">
                      <OpenInNew />
                      <h5 onClick={() => this.handleGetMoreNews(item.url)}>
                        {item.title}
                      </h5>
                    </div>
                  }
                </div>
                {/* <div className="card-action">
                  {item.url &&
                    <a target="_blank">
                      <OpenInNew />
                      So konnen sie Punkte sammeln
                    </a>
                  }
                </div> */}
              </div>
            ))
          }
          <div className="card">
            {url &&
              <div className="card-action">
                <Button
                  variant="contained"
                  className="btn-all-news custome-btn"
                  onClick={() => this.handleGetMoreNews(url)}
                >
                  {de_de.news_btn_title}
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});

export default connect(mapStateToProps)(News);
