import {
  $fetch
} from '../core';
import {
  alertConstants,
  methodConstants,
  endpointContants
} from '../config';

function trimStartEndAnd (obj) {
  for(var key in obj) {
    obj[key] = obj[key].replace(/^\s+|\s+$/gm,'');
  }
  return obj;
}

export const registerUser = (user = {}) => {
  const userTrim = trimStartEndAnd(user);
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_REGISTER,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_REGISTER,
    alertConstants.FETCH_DATA_SUCCESS_REGISTER,
    alertConstants.FETCH_DATA_FAILURE_REGISTER,
    userTrim,
    false
  );
};

export const getEmail = (email = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_CHECK_EMAIL_EXISTED,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_CHECK_EMAIL,
    alertConstants.FETCHING_DATA_SUCCESS_CHECK_EMAIL,
    alertConstants.FETCHING_DATA_FAILURE_CHECK_EMAIL,
    email,
    false,
  );
}

export const getPhone = (phone = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_CHECK_PHONE_EXISTED,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_CHECK_PHONE,
    alertConstants.FETCHING_DATA_SUCCESS_PHONE,
    alertConstants.FETCHING_DATA_FAILURE_PHONE,
    phone,
    false
  );
}

export const getOTP = (phone = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GET_OTP,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_GET_OTP,
    alertConstants.FETCH_DATA_SUCCESS_GET_OTP,
    alertConstants.FETCH_DATA_FAILURE_GET_OTP,
    phone,
    false
  );
};

export const verfiyOTP = (phone = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_VERIFY_OTP,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_VERIFY_OTP,
    alertConstants.FETCH_DATA_SUCCESS_VERIFY_OTP,
    alertConstants.FETCH_DATA_FAILURE_VERIFY_OTP,
    phone,
    false
  );
}

export const updateProfile = (user = {}, userId) => {
  const userTrim = trimStartEndAnd(user);
  return $fetch(
    endpointContants.ROOT_URL,
    `${endpointContants.ENDPOINT_UPDATE_USER}${userId}`,
    methodConstants.PUT,
    alertConstants.FETCHING_DATA_UPDATE_PROFILE,
    alertConstants.FETCHING_DATA_UPDATE_PROFILE_SUCCESS,
    alertConstants.FETCHING_DATA_UPDATE_PROFILE_FAILURE,
    userTrim,
    true
  );
}

export const getError = (state) => state.error;
export const checkEmailPhoneErr = (state, err) => state[err];
