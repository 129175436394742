/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Fab } from '@material-ui/core';

import BackIcon from '../shared/back-icon';
import { IconPhoto, IconCamera } from '../shared/icon-camera-photo';
import lang from '../../config/languages.json';

class UploadSelect extends Component {

  render() {
    const { handleUploadImage, handlePreviousPage, onChangeTakeImage, takePhotoCordova , takeFileCordova , isCordova } = this.props;
    const { de_de } = lang[1];
    return (
      <div>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
        {de_de.upload_text}
          </Fab>
        </div>
        <div className="upload-content">
          <div className="section-1">
            <h1 className="upload-title">{de_de.upload_profilbild}</h1>
            <p>{de_de.upload_profilbild_content}</p>
          </div>
          <div className="section-2">
            <input
              type="file"
              style={{display: 'none'}}
              onChange={onChangeTakeImage}
              accept="image/*"
              capture="camera"
              ref={cameraInput => this.cameraInput = cameraInput}
            />
            <input
              style={{display: 'none'}}
              type="file"
              onChange={handleUploadImage}
              accept=".png, .jpg"
              ref={fileInput => this.fileInput = fileInput}
            />
            <Fab
              variant="extended"
              className="btn-upload img-camera"
              onClick={() => isCordova ? takePhotoCordova() : this.cameraInput.click()}
            >
              <IconCamera className="svg-camera" />
              {de_de.upload_photo}
            </Fab>
            <Fab
              variant="extended"
              className="btn-upload img-file"
              onClick={() => isCordova ? takeFileCordova() : this.fileInput.click()}
            >
              <IconPhoto className="svg-photo" />
                {de_de.upload_up}
            </Fab>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadSelect;
