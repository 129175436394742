/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import Item from './Item';
import Preloader from '../Preloader';
import { _sortData, _unixTimestamp } from '../../lib';
import { _deleteGuestCoupon, _getGuestCoupons, _cleanUnusedQuestCategories } from '../../actions';

class Carousel extends Component {

  constructor(props){
    super(props);
    this.handleDeleteCoupon = this.handleDeleteCoupon.bind(this);
  } 

   handleDeleteCoupon = async (id, event) =>  {
    const { _getGuestCoupons, _deleteGuestCoupon, _cleanUnusedQuestCategories } = this.props;
    event.preventDefault();
    await _deleteGuestCoupon(id);
    await _getGuestCoupons();
    await _cleanUnusedQuestCategories();
  }

  render() {
    const { isAuthenticated, data, type, view, isFetching, orderKey, order } = this.props;
  
  const setting = {
    dots: this.props.settings.dots,
    infinite: this.props.settings.infinite,
    speed: this.props.settings.speed,
    slidesToShow: this.props.settings.slidesToShow,
    slidesToScroll: this.props.settings.slidesToScroll,
    initialSlide: this.props.settings.initialSlide,
    rows: this.props.settings.rows,
    arrows: this.props.settings.arrows,
  };

  const isAuth = isAuthenticated ? 'user' : 'public';
  const titleCarousel = this.props.hasOwnProperty('title') === true ? <h1>{this.props.title}</h1> : '';
  if (isFetching)
    return <Preloader />
  return (
    <div className={`carousel-container ${isAuth}`}>
      {titleCarousel}
      <div className="carousel-screen">
        <Slider {...setting}>
          {
            data && _sortData(data, orderKey, order).map((value, index) => {
              const couponPublic = value.public;
              const viewCoupon = value.view;
              const { redemption } = value;
              const minuteMount =  redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
              const typeOfCoupon = minuteMount || redemption === null ? 'gold-guest' : 'disabled';
              const couponDefault = minuteMount || redemption === null ? '' : 'disabled';
              // if (redemption === null || (redemption && minuteMount > 0)) {
                if (couponPublic) {
                  return (
                    <Item
                      key={index}
                      data={value}
                      redemption={redemption}
                      type={typeOfCoupon}
                      expiration={minuteMount}
                      handleDeleteCoupon={this.handleDeleteCoupon}
                      isAuthenticated={isAuthenticated}
                    />
                  )
                } 
                return (
                  null
                )
              // }
            })
          }
        </Slider>
      </div>

    </div>
  );
  }
  
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isFetching: state.coupon.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _deleteGuestCoupon,
    _getGuestCoupons,
    _cleanUnusedQuestCategories
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carousel));
