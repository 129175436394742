/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import lang from '../../../config/languages.json';

const BirthdayScreen = (props) => {

  const { handleClick, isAuthenticated, isDataPoints } = props;
  const checkBirthdayVoucher = !isDataPoints.hasOwnProperty('birthday_voucher') ? 'is_get_point_birthday' : 'is_show_point_birthday';
  const { de_de } = lang[1];
  return (
    <div className="point-screen">
      <div className="point-gift-bg">
        <h1>{de_de.happy_birthday}</h1>
        <h5>{de_de.greeting_text_birthday}</h5>
      </div>
      <Button
        variant="contained"
        className="btn-points"
        onClick={handleClick.bind(this, checkBirthdayVoucher)}
      >
          {de_de.birthday_btn_gift}
      </Button>
    </div>
  );
}


const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isDataPoints: state.checkin.data,
  isFetching: state.checkin.isFetching
});

export default connect(mapStateToProps)(BirthdayScreen);