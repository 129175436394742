/* eslint-disable no-unused-vars */
import React from 'react';
import Countdown from 'react-countdown-now';

const Timer = (props) => {
  const {
    expiration,
    handleExpiration
  } = props;
  return (<Countdown
    date={Date.now() + expiration}
    daysInHours
    renderer={renderer}
    onComplete={handleExpiration}
  />);
};

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span>Eingelöst</span>;
  } else {
    const hour = hours >= 10 ? hours : `0${hours}`;
    const minute = minutes >= 10 ? minutes : `0${minutes}`;
    const second = seconds >= 10 ? seconds : `0${seconds}`;
    const timeLeft = `${hour}:${minute}:${second}`;
    return timeLeft === "00:00:00" ? <span>Eingelöst</span> : <span>{hour}:{minute}:{second}</span>;
  }
};

export default Timer;