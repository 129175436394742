/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { history } from '../../helper';
import Slider from "react-slick";
import Item from './Item';
import { _sortData, _unixTimestamp, _dataInfoTracking } from '../../lib';
import { _deleteCoupon, _getCouponList } from '../../actions';
import DialogConfirmDelete from '../DialogConfirmDeleteCoupon';
import lang from '../../config/languages.json';
import { errorCodeConstants } from '../../config';

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpired: false,
      open: false,
      id: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isExpired !== this.state.isExpired) {
      _getCouponList();
    }
  }

  componentDidMount() {
    const { isAuthenticated, _getCouponList } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getCouponList();
  }

  handleDeleteCoupon = async (id, event) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const isChecked = localStorage.getItem("c") ? true : false;
    event.preventDefault();
    const { _getCouponList, _deleteCoupon } = this.props;
    if (isChecked) {
      await _deleteCoupon(id);
      await _getCouponList();
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_coupon, errorCodeConstants.couponDelete);
    }
    this.setState({
      open: true,
      id
    })
  }

  handleClickConfirm = async (id) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _getCouponList, _deleteCoupon } = this.props;
    await _deleteCoupon(id);
    await _getCouponList();
    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_user_deleted_coupon, errorCodeConstants.couponDelete);
    this.setState({
      open: false
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleExpiration = () => {
    this.setState({
      isExpired: true
    })
  }

  render() {
    const isChecked = localStorage.getItem("c") ? true : false;
    const { isAuthenticated, view, orderKey, order, isCoupon } = this.props;
    const { isExpired, open, id } = this.state;
    let counter = 0;
    const setting = {
      dots: this.props.settings.dots,
      infinite: this.props.settings.infinite,
      // infinite: true,
      speed: this.props.settings.speed,
      slidesToShow: this.props.settings.slidesToShow,
      slidesToScroll: this.props.settings.slidesToScroll,
      initialSlide: this.props.settings.initialSlide,
      rows: this.props.settings.rows,
      arrows: this.props.settings.arrows,
    };

    const isAuth = isAuthenticated ? 'user' : 'public';
    const titleCarousel = this.props.hasOwnProperty('title') === true ? <h1>{this.props.title}</h1> : '';

    return (
      <div className={`carousel-container ${isAuth}`}>
        {titleCarousel}
        <div className="carousel-screen">
          <Slider {...setting}>
            {
              isCoupon && _sortData(isCoupon, orderKey, order).slice(0, 3).map((value, index, array) => {
                const couponPublic = value.public;
                const viewCoupon = value.view;
                const { redemption } = value;
                const minuteMount = redemption && _unixTimestamp(redemption.redeemed_at, redemption.expires_at);
                const typeOfCoupon = (minuteMount && !isExpired) || redemption === null ? 'gold' : 'disabled';
                const couponDefault = (minuteMount && !isExpired) || redemption === null ? '' : 'disabled';
                // if (array.length === index + 1) {
                //   if (!couponPublic) {
                //     return (
                //       <Link key={index} to={{
                //           pathname: "/user/coupons",
                //           state: {
                //             value: 1
                //           }
                //         }}>
                //         <div
                //           className="btn-all"
                //         >
                //           <div className="carousel-view">
                //             alle angebote<br />
                //             anzeigen
                //           </div>
                //         </div>
                //       </Link>
                //     )
                //   } 
                //   return (
                //     <Link key={index} to={{
                //       pathname: "/user/coupons",
                //       state: {
                //         value: 1
                //       }
                //     }}>
                //       <div
                //         className="btn-all"
                //       >
                //         <div className="carousel-view">
                //           alle angebote<br />
                //           anzeigen
                //           </div>
                //       </div>
                //     </Link>
                //   )
                // }
                if (!couponPublic) {
                  return (
                    <Item
                      key={index}
                      data={value}
                      redemption={redemption}
                      type={typeOfCoupon}
                      expiration={minuteMount}
                      handleDeleteCoupon={this.handleDeleteCoupon}
                      isAuthenticated={isAuthenticated}
                      handleExpiration={this.handleExpiration}
                      isExpired={isExpired}
                    />
                  )
                } 
                return (
                  <Item
                    key={index}
                    data={value}
                    redemption={redemption}
                    type={couponDefault}
                    expiration={minuteMount}
                    handleDeleteCoupon={this.handleDeleteCoupon}
                    isAuthenticated={isAuthenticated}
                    handleExpiration={this.handleExpiration}
                    isExpired={isExpired}
                  />
                )

              })
            }
            <Link key={'show-all'} to={{
                          pathname: "/user/coupons",
                          state: {
                            value: 0
                          }
                        }}>
                        <div
                          className="btn-all"
                        >
                          <div className="carousel-view">
                            alle angebote<br />
                            anzeigen
                          </div>
                        </div>
                      </Link>
          </Slider>
          {
            open && !isChecked && (<DialogConfirmDelete
              open={open}
              handleConfirm={this.handleClickConfirm}
              handleClose={this.handleClose}
              id={id}
            />)
          }
        </div>

      </div>
    );
  }

}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isCoupon: state.coupon.data,
  email: state.profile.data.data
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _deleteCoupon,
    _getCouponList
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carousel));
