const urlDev = 'https://bonusapp-stage-backend.3100.hostserv.eu';
//const urlProd = 'https://bonusapp-backend.3100.hostserv.eu';
const urlProd = 'https://bonusapp-stage-backend.3100.hostserv.eu';
const url = process.env.NODE_ENV === 'development' ? urlDev : urlProd;

export const endpointContants = {
  ROOT_URL: url,
  ENDPOINT_GET_CURRENT_USER: '/api/auth/me',
  ENDPOINT_LOGIN_BY_EMAIL_AND_BIRTHDAY: '/api/auth/login/email_birthday',
  ENDPOINT_LOGIN_BY_EMAIL_AND_POSTCOST: '/api/auth/login/email_postcode',
  ENDPOINT_CHECK_EMAIL_EXISTED: '/api/users/emailExists',
  ENDPOINT_CHECK_PHONE_EXISTED: '/api/users/phoneExists',
  ENDPOINT_LOGIN_BY_PHONE_AND_OTP: '/api/auth/login/sms',
  ENDPOINT_CHECK_EMAIL_OR_POST_CODE: '/api/users/checkBirthdayPostcode',
  ENDPOINT_UPDATE_USER: '/api/users/',
  ENDPOINT_UPLOAD_PHOTO: '/api/users/uploadPhoto',
  ENDPOINT_GET_OTP: '/api/users/sendOtp',
  ENDPOINT_VERIFY_OTP: '/api/users/verifyOtp',
  ENDPOINT_LOGOUT: '/api/auth/logout',
  ENDPOINT_REFRESH_TOKEN: '/api/auth/refresh',
  ENDPOINT_REGISTER: '/api/auth/register',
  ENDPOINT_USER_CHECKIN: '/api/checkin',
  ENDPOINT_GET_COUPON: '/api/coupons/:id',
  ENDPOINT_LIST_COUPONS: '/api/coupons',
  ENDPOINT_GET_MENU: '/api/menus/:id',
  ENDPOINT_LIST_MENU: '/api/menus',
  ENDPOINT_GET_LATEST_NEWS: '/api/news/lastest',
  ENDPOINT_LIST_NEWS: '/api/news',
  // Shopping List
  ENDPOINT_SHOPPING_LIST: '/api/shoppinglists', // Get
  ENDPOINT_SHOPPING_LIST_ITEM: '/api/shoppinglists/', // Get
  ENDPOINT_SHOPPING_LIST_CREATE: '/api/shoppinglists', // Post
  ENDPOINT_SHOPPING_ITEM_CREATE: '/api/shoppinglists/',
  // Delete shopping list/item
  ENDPOINT_SHOPPING_LIST_DELETE: '/api/shoppinglists/',
  ENDPOINT_SHOPPING_ITEM_DELETE: '/api/shoppinglists/',
  // Vouchers
  ENDPOINT_VOUCHERS_LIST: '/api/vouchers/my_vouchers',
  ENDPOINT_GET_VOUCHER: '/api/vouchers/',
  ENDPOINT_NEXT_LEVELUP_VOUCHER: '/api/vouchers/next_levelup_voucher', // Next Level Up Voucher
  ENDPOINT_REDEEM_VOUCHER: '/api/vouchers/',
  ENDPOINT_DELETE_VOUCHER: '/api/vouchers/',
  // Coupon
  ENDPOINT_COUPON_CATEGORY: '/api/coupons_categories',
  ENDPOINT_COUPON_LIST: '/api/coupons',
  ENDPOINT_COUPON_GET: '/api/coupons/',
  ENDPOINT_COUPON_REDEEM: '/api/coupons/',
  ENDPOINT_COUPON_DELETE: '/api/coupons/',
  
  // Guest
  ENDPOINT_GUEST_COUPONS_LIST: '/api/guest/coupons',
  ENDPOINT_GUEST_CATEGORIES_LIST: '/api/guest/coupon_categories',
  ENDPOINT_GUEST_DELETE_COUPON: '/api/guest/coupons/',
  ENDPOINT_GUEST_GET_COUPON: '/api/guest/coupons/',
  //Guest Lasted News
  ENDPOINT_GUEST_GET_LASTED_NEWS: '/api/news/latest',
  // Opening Time
  ENDPOINT_OPENING_TIME: '/api/malls/openingtimes',
  // Get mall
  ENDPOINT_GET_MALL: '/api/malls/settings'
}




