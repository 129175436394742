/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Fab } from '@material-ui/core';
import { connect } from 'react-redux';
import ReactCodeInput from '../shared/verification';
import BackIcon from '../shared/back-icon';
import lang from '../../config/languages.json';

const { de_de } = lang[1];
const props = {
  inputStyle: {
    fontFamily: 'ITC Avant Garde Gothic Std',
    fontWeight: 'normal',
    width: '60px',
    height: '60px',
    fontSize: '14px',
    MozAppearance: 'textfield',
    backgroundColor: '#f5f5f5',
    color: '#000000',
    border: '0px solid',
    marginLeft: '4px',
    textAlign: 'center'
  },
  placeholders: ['C', 'O', 'D', 'E']
};

class WizardCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };
    this.handSubmitCode = this.handSubmitCode.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.resetCodeField = this.resetCodeField.bind(this);
  }

  async handSubmitCode(e) {
    e.preventDefault();
    const { code } = this.state;
    const { handleRegister } = this.props;
    return await handleRegister(code);
  }

  onChangeCode(value) {
    this.setState({ code: value });
  }

  resetCodeField(e){
    const { handleGetOtherCode } = this.props;
    this.setState({
      code: ''
    });
    handleGetOtherCode(e);
  }

  render() {
    const { handlePreviousPage, handleGetOtherCode, codeError, isFetching } = this.props;
    const { code } = this.state;
    const disable = isFetching ? true : false;
    return (
      <form onSubmit={this.handSubmitCode}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            {de_de.register_header}
          </Fab>
        </div>
        <div className="section-content">
          <h2>{de_de.register_title_verify_mobile}</h2>
          <p>{de_de.register_verify_mobile_message}</p>
          <div className="input-fields code-input">
            <ReactCodeInput
              type='number'
              fields={4}
              {...props}
              pattern="\d*" onChange={this.onChangeCode}
              code={code}
            />
            <span style={{ display: 'block', color: 'red', marginTop: '10px' }}>{codeError ? 'Dies ist ein ungültiger Code' : ''}</span>
            <p>{de_de.register_verify_exist_code_question}</p>
            <span
              className="resend-code"
              onClick={(e) => this.resetCodeField(e)}
            >
              {de_de.resend_code}
            </span>
          </div>
          <div className="submits submit-code-input">
            <button className="account-submit" type="submit" disabled={disable}>
              {de_de.register_button_submit_code}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.VerifyOTP.isFetching
});

export default connect(mapStateToProps)(WizardCodeForm);
