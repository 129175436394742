/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import lang from '../../config/languages.json';

const style = theme => ({
  root: {
    color: '#ffffff'
  }
})


class AlertDialog extends React.Component {

  render() {
    const { classes } = this.props;
    const { de_de } = lang[1];
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.props.handleClickOpen}
          className="btn-sign-in"
        >
          Angebot einlösen
        </Button>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={true}
          className="dialog-screen"
          classes={classes}
        >
          <Button 
                onClick={this.props.handleClose} 
                color="primary" autoFocus
                className="btn-deny"
              >
                <ClearIcon classes={classes} />
            </Button>
          <div className="dialog-bg"></div>
          <DialogTitle id="alert-dialog-title">
          {"Achtung!"}</DialogTitle>
          <DialogContent>

            <DialogContentText className="alert-dialog-description">
               {de_de.attention_sub}


            </DialogContentText>
            <DialogContentText className="alert-dialog-description">
                {de_de.attention_content}

  
            </DialogContentText>
            <DialogContentText className="alert-dialog-description">
            <Button 
              onClick={this.props.handleConfirm} 
              color="primary"
              className="btn-sign-in"
            >
                {de_de.attention_btn}
            </Button>
              <Button 
                onClick={this.props.handleClose} 
                color="primary" autoFocus
                className="btn-deny"
              >
                {de_de.redeem_later}
            </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
});


export default withStyles(style)(connect(mapStateToProps)(AlertDialog));
