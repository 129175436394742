/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helper';
import { bindActionCreators } from 'redux';
import { _checkIn } from '../../actions';
import { Link, withRouter } from 'react-router-dom';
import Header from '../shared/back-button';
import BirthdayScreen from './Birthday';
import CouponScreen from './Coupon';
import CouponLevelUp from './CouponLevelUp';
import GetPointBirthday from './GetPoints';
import GetPointDaily from './GetPointRegular';
import GetPointReward from './GetPointReward';
import PassScannedScreen from './PassScanned';
import CongrasScreen from './Congratulation';
import Preloader from '../Preloader';
import { errorCodeConstants } from '../../config';
import { _dataInfoTracking } from '../../lib';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';
// const obj = {
//   "is_birthday": false,
//   "birthday_voucher": null,
//   "birthday_reward_point": 0,
//   "birthday_total_point": 0,
//   "is_level_up": false,
//   "level_up_voucher": null,
//   "level_up_reward_point": 0,
//   "level_up_total_point": 0,
//   "reward_point": 1,
//   "total_point": 25
// }

class Punkte extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      showScreen: null,
    };
  }

  componentDidMount() {
    const { _checkIn, isAuthenticated } = this.props;
    TagManager.dataLayer(tagManagerArgs);
    if (!isAuthenticated) {
      history.push('/login');
    }
    isAuthenticated && _checkIn();
  }

  handleClick = (event) => {
    this.setState({
      showScreen: event
    });
  }

  render() {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { isDataPoints, classes, isFetching } = this.props;
    const { showScreen } = this.state;
    let typeOfPoint = '';
    Object.keys(isDataPoints).forEach(key => {

      if (key === 'reward_point' && isDataPoints['reward_point'] === 1 && isDataPoints['total_point'] !== 100) {
        typeOfPoint = key;
      }

      if (key === 'is_normal_checkin' && isDataPoints['is_normal_checkin']) {
        typeOfPoint = key;
      }

      if (isDataPoints['is_birthday'] && isDataPoints['birthday_reward_point']) {
        if (key === 'is_birthday') {
          typeOfPoint = key;
        }
      }

      if (isDataPoints['is_level_up'] && isDataPoints['level_up_reward_point'] && !isDataPoints['is_birthday']) {
        if (key === 'is_level_up') {
          typeOfPoint = key;
        }
      }
    });

    showScreen && (typeOfPoint = showScreen);
    return (
      <div className="main-page">
        <Header to="" title="Punkte" />
        <div className="main-screen">
          <div className="point-container">
            {isFetching && <Preloader />}
            {
              (() => {
                const { birthday_voucher, total_point, level_up_voucher, birthday_total_point } = isDataPoints;
                switch (typeOfPoint) {
                  case 'is_birthday':
                      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_checkin_birthday, errorCodeConstants.checkinSucessPlusBirthday);
                    return <BirthdayScreen
                      handleClick={this.handleClick}
                    />;
                  case 'is_show_point_birthday':
                    return < CouponScreen
                      handleClick={this.handleClick}
                      is_birthday={true}
                      birthday_voucher={birthday_voucher} />
                  case 'is_get_point_birthday':
                    return < GetPointBirthday
                      handleClick={this.handleClick}
                      birthday_total_point={birthday_total_point}
                      level_up_voucher={level_up_voucher} />
                  case 'is_level_up':
                      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_checkin_levelup, errorCodeConstants.checkinSucessPlusLevelUp);
                    return <CongrasScreen
                      handleClick={this.handleClick}
                    />;
                  case 'is_show_point_level_up':
                    return <CouponLevelUp
                      handleClick={this.handleClick} />
                  case 'is_get_point_level_up':
                    return <GetPointDaily
                      handleClick={this.handleClick}
                      total_point={total_point} />
                  case 'reward_point':
                    return <GetPointReward
                      handleClick={this.handleClick}
                      total_point={total_point}
                    />;
                  case 'is_normal_checkin':
                    return <GetPointReward
                      handleClick={this.handleClick}
                      total_point={total_point}
                    />;
                  default:
                      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_checkin_scanned, errorCodeConstants.checkinScanned);
                    return !isFetching && <PassScannedScreen />;
                }
              })()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isDataPoints: state.checkin.data,
  isFetching: state.checkin.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _checkIn
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Punkte);