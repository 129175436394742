import {
  alertConstants,
  setCookieForToken,
  COOKIE_APP_SUGGESTION_NAME
} from '../config';
import Cookies from 'js-cookie';

const INITIALSTATE_EMAIL = {
  data: [],
  isFetching: false,
  isAuthenticated: Cookies.get(COOKIE_APP_SUGGESTION_NAME) ? true : false,
  error: false,
  dataCheckEmailPostcode: [],
  errorCheckEmail: false,
};

export const LoginEmail = (state = INITIALSTATE_EMAIL, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_EMAIL:
      return {
        ...state,
        data: [],
        isAuthenticated: false,
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_EMAIL:
      localStorage.setItem('bonus_app_tk', JSON.stringify(action.payload.data));
      setCookieForToken(action.payload.data.expires_in / 86400);
      return {
        ...state,
        data: action.payload,
        isAuthenticated: true,
        isFetching: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_EMAIL:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    case alertConstants.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        error: false
      };
    case alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE:
      return {
        ...state,
        dataCheckEmailPostcode: [],
      };
    case alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE_SUCCESS:
      return {
        ...state,
        dataCheckEmailPostcode: action.payload,
        errorCheckEmail: false
      };
    case alertConstants.FETCHING_DATA_CHECK_EMAIL_POSTCODE_FAILURE:
      return {
        ...state,
        errorCheckEmail: true
      };
    default:
      return state;
  }
};

const INITIALSTATE_PHONE = {
  data: [],
  isAuthenticated: Cookies.get(COOKIE_APP_SUGGESTION_NAME) ? true : false,
  isFetching: false,
  loginError: false,
  error: false
};

export const LoginPhone = (state = INITIALSTATE_PHONE, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_PHONE:
      return {
        ...state,
        data: [],
        isAuthenticated: false,
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_PHONE:
      localStorage.setItem('bonus_app_tk', JSON.stringify(action.payload.data));
      // const setTime = 30 / 86400;
      // setCookieForToken(setTime);
      const tokenExpries = action.payload.data.expires_in / 86400;
      setCookieForToken(tokenExpries);
      return {
        ...state,
        data: action.payload,
        isAuthenticated: true,
        loginError: false,
        isFetching: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_PHONE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        loginError: true,
        error: true
      };
    case alertConstants.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        error: false
      };
    default:
      return state;
  }
};
