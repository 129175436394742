import {
  $fetch,
  alertConstants,
  methodConstants,
  endpointContants
} from '../config';

export const _fetchData = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_LIVE,
    methodConstants.GET,
    alertConstants.FETCHING_DATA,
    alertConstants.FETCH_DATA_SUCCESS,
    alertConstants.FETCH_DATA_FAILURE
  );
};

export const _registerUser = (user = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_REGISTER,
    methodConstants.POST,
    alertConstants.FETCHING_DATA,
    alertConstants.FETCH_DATA_SUCCESS,
    alertConstants.FETCH_DATA_FAILURE,
    user
  );
}

export const _checkIn = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_USER_CHECKIN,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_CHECKIN,
    alertConstants.FETCHING_DATA_SUCCESS_CHECKIN,
    alertConstants.FETCHING_DATA_FAILURE_CHECKIN
  )
}

export const _getUserProfile = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GET_CURRENT_USER,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_CURRENT_USER,
    alertConstants.FETCH_DATA_SUCCESS_CURRENT_USER,
    alertConstants.FETCH_DATA_FAILURE_CURRENT_USER,
  );
};

export const _getShoppingList = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_LIST,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_SHOPPING_LIST,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_SUCCESS,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_FAILURE
  );
};

export const _getShoppingListItem = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_LIST_ITEM + `${id}/items`,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_ITEM,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_ITEM_SUCCESS,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_ITEM_FAILURE
  );
};

export const _createShoppingList = (bodyParameter = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_LIST_CREATE,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_CREATE_SHOPPING_LIST,
    alertConstants.FETCHING_DATA_CREATE_SHOPPING_LIST_SUCCESS,
    alertConstants.FETCHING_DATA_CREATE_SHOPPING_LIST_FAILURE,
    bodyParameter
  );
};

export const _createShoppingItem = (id, bodyParameter = {}) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_ITEM_CREATE + `${id}/items`,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_CREATE,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_CREATE_SUCCESS,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_CREATE_FAILURE,
    bodyParameter
  );
};

export const _toggleShoppingItem = (listID, itemId ) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_LIST_ITEM + `${listID}/items/${itemId}/toggle_active`,
    methodConstants.PUT,
    alertConstants.PUT_TOGGLE_SHOPPING_LIST_ITEM,
    alertConstants.PUT_TOGGLE_SHOPPING_LIST_ITEM_SUCCESS,
    alertConstants.PUT_TOGGLE_SHOPPING_LIST_ITEM_FAILURE,
  );
};

export const _getListVouchers = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_VOUCHERS_LIST,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_VOUCHERS_LIST,
    alertConstants.FETCHING_DATA_VOUCHERS_LIST_SUCCESS,
    alertConstants.FETCHING_DATA_VOUCHERS_LIST_FAILURE
  );
};

export const _getVoucher = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GET_VOUCHER + id,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_GET_VOUCHER,
    alertConstants.FETCHING_DATA_GET_VOUCHER_SUCCESS,
    alertConstants.FETCHING_DATA_GET_VOUCHER_FAILURE,
  );
};

export const _getRedeemVoucher = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_REDEEM_VOUCHER + `${id}/redeem`,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_REDEEM_VOUCHER,
    alertConstants.FETCHING_DATA_REDEEM_VOUCHER_SUCCESS,
    alertConstants.FETCHING_DATA_REDEEM_VOUCHER_FAILURE,
  );
};

export const _getCouponList = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_COUPON_LIST,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_COUPON_LIST,
    alertConstants.FETCHING_DATA_COUPON_LIST_SUCCESS,
    alertConstants.FETCHING_DATA_COUPON_LIST_FAILURE
  );
}

export const _getCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_COUPON_GET + id,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_GET_COUPON,
    alertConstants.FETCHING_DATA_GET_COUPON_SUCCESS,
    alertConstants.FETCHING_DATA_GET_COUPON_FAILURE,
  );
};

export const _getRedeemCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_COUPON_REDEEM + `${id}/redeem`,
    methodConstants.POST,
    alertConstants.FETCHING_DATA_REDEEM_COUPON,
    alertConstants.FETCHING_DATA_REDEEM_COUPON_SUCCESS,
    alertConstants.FETCHING_DATA_REDEEM_COUPON_FAILURE,
  );
};

export const _getNextLevelVoucher = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_NEXT_LEVELUP_VOUCHER,
    methodConstants.GET,
    alertConstants.FETCHING_DATA_NEXT_VOUCHER,
    alertConstants.FETCHING_DATA_NEXT_VOUCHER_SUCCESS,
    alertConstants.FETCHING_DATA_NEXT_VOUCHER_FAILURE,
  );
}

export const _deleteShoppingItem = (listId, id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_ITEM_DELETE + `${listId}/items/${id}`,
    methodConstants.DELETE,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_DELETE,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_DELETE_SUCCESS,
    alertConstants.FETCHING_DATA_SHOPPING_ITEM_DELETE_FAILURE,
  );
};

export const _deleteCoupon = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_COUPON_DELETE + `${id}/redeemed`,
    methodConstants.DELETE,
    alertConstants.FETCHING_DATA_COUPON_DELETE,
    alertConstants.FETCHING_DATA_COUPON_DELETE_SUCCESS,
    alertConstants.FETCHING_DATA_COUPON_DELETE_FAILURE,
  );
};

export const _deleteVoucher = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_DELETE_VOUCHER + `${id}/redeemed`,
    methodConstants.DELETE,
    alertConstants.FETCHING_DATA_VOUCHER_DELETE,
    alertConstants.FETCHING_DATA_VOUCHER_DELETE_SUCCESS,
    alertConstants.FETCHING_DATA_VOUCHER_DELETE_FAILURE,
  );
};
export const _getCategories = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_COUPON_CATEGORY,
    methodConstants.GET,
    alertConstants.GET_CATEGORIES_DATA,
    alertConstants.GET_CATEGORIES_DATA_SUCCESS,
    alertConstants.GET_CATEGORIES_DATA_FAILURE,
  )
}

export const _deleteShoppingList = (id) => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_SHOPPING_LIST_DELETE + id,
    methodConstants.DELETE,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_DELETE,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_DELETE_SUCCESS,
    alertConstants.FETCHING_DATA_SHOPPING_LIST_DELETE_FAILURE,
  );
};

export const _getMall = () => {
  return $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_GET_MALL,
    methodConstants.GET,
    alertConstants.FETCHING_GET_MALL,
    alertConstants.FETCHING_GET_MALL_SUCCESS,
    alertConstants.FETCHING_GET_MALL_FAILURE
  );
};

export const _saveImageProfile = (bodyParameter = {}) => {
  return  $fetch(
    endpointContants.ROOT_URL,
    endpointContants.ENDPOINT_UPLOAD_PHOTO,
    methodConstants.POST,
    alertConstants.FETCHING_SAVE_IMAGE_PROFILE,
    alertConstants.FETCHING_SAVE_IMAGE_PROFILE_SUCCESS,
    alertConstants.FETCHING_SAVE_IMAGE_PROFILE_FAILURE,
    bodyParameter
  );
}
