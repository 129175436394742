import React, { Component } from 'react';
import { history } from '../../../helper';

import "./back-button.scss";

class BackButton extends Component {
  handleBack = () => {
    history.goBack();
  }
  render() {
    const { title } = this.props;
    return (
      <div  className="guest-back-button">
        {/* <Link to="#" onClick={this.handleBack}/> */}
        <span onClick={this.handleBack} />
        <p>{title}</p>
      </div>
    )
  }
}

export default BackButton;
