/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helper';
import { bindActionCreators } from 'redux';
import { _getUserProfile, _getListVouchers, _getNextLevelVoucher } from '../../actions';
import Header from '../shared/back-button';
import Carousel from '../Carousel';
import Preloader from '../Preloader';
import { NextVoucher } from './next-voucher';
import ProfileImg from '../../assets/img/profile.jpg';
import lang from '../../config/languages.json';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from '../../index';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.handleUploadProfile = this.handleUploadProfile.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated, _getUserProfile, _getNextLevelVoucher } = this.props;
    TagManager.dataLayer(tagManagerArgs);
    if (!isAuthenticated) {
      history.push('/login');
    }
    _getUserProfile();
    _getNextLevelVoucher();
  }

  handleUploadProfile() {
    this.props.history.push('/upload-profile');
  }

  render() {
    const { userInfo, error, isFetching, isNextVoucher } = this.props;

    const tempProfile = { firstname: '', lastname: '', gender: '', point: 0, level: 1, photo_url: '' };
    const {
      firstname, lastname, gender,
      point, level, photo_url
    } = (userInfo === undefined || error) ? tempProfile : userInfo;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      initialSlide: 0,
      rows: 1,
      arrows: false
    };
    const profieImgSrc = (photo_url === '' || photo_url === null) ? ProfileImg : photo_url;
    const { de_de } = lang[1];

    return (
      <div className="main-page-profile">
        <Header to="" title={de_de.point} />
        {isFetching ? <Preloader /> :
          <div className="main-screen">
            <div className="profile-container">
              <div className="profile-screen">
                <div className="section-1">
                  <h1>{de_de.level_title} {(level)}</h1>
                  <div className="profile-img-container">
                    <img
                      src={profieImgSrc}
                      alt=""
                      onClick={this.handleUploadProfile}
                    />
                  </div>
                  <p>{`${firstname} ${lastname}`}</p>
                </div>
                <div className="section-2">
                  <NextVoucher value={point} isNextVoucher={isNextVoucher.name} />
                </div>
                <div className="section-3">
                  <Carousel
                    title={de_de.my_coupon_title}
                    settings={settings}
                    type="voucher"
                    orderKey="id"
                    order="desc"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  error: state.profile.error,
  userInfo: state.profile.data.data,
  isFetching: state.profile.isFetching,
  isNextVoucher: state.nextLevel.data
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getUserProfile,
    _getNextLevelVoucher
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
