/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import './assets/scss/custom.scss';
import App from './app/App';
import TagManager from 'react-gtm-module';

const elemId = 'root';
export const tagManagerArgs = {
  gtmId: 'GTM-MG8RDBQ',
  dataLayer: {
    userProject: 'Shoppyland BonusApp',
},
dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>
    , document.getElementById(elemId));
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// let deferredPrompt;
// const path = window.location.pathname;

// window.addEventListener('beforeinstallprompt', e => {
//   // For older browsers
//   e.preventDefault();
//   deferredPrompt = e;
//   const checkA2hs = localStorage.getItem('a2hs');
//   const a2hsBtn = document.querySelector(".add-to-home-screen");  // hide our user interface that shows our A2HS button
//   a2hsBtn.style.display = 'none';  // Show the prompt
//   const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

//   const isAndroid = /(android)/i.test(navigator.userAgent)
//   if (isAndroid && !isInStandaloneMode()) {
//     setTimeout(() => {
//       // See if the app is already installed, in that case, do nothing
//       if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {
//         return false;
//       }

//       if (!checkA2hs && path === '/user/my-page') {
//         showAddToHomeScreen();
//       } else {
//         const a2hsBtn = document.querySelector(".add-to-home-screen");  // hide our user interface that shows our A2HS button
//         a2hsBtn.style.display = 'none';
//       }

//       const closeBtn = document.querySelector(".button-close");
//       closeBtn.addEventListener('click', e => {
//         e.stopPropagation();
//         localStorage.setItem('a2hs', true);
//         const a2hsBtn = document.querySelector(".add-to-home-screen");  // hide our user interface that shows our A2HS button
//         a2hsBtn.style.display = 'none';
//       }, true);

//     }, 1500);
//   } else {
//     const a2hsBtn = document.querySelector(".add-to-home-screen");  // hide our user interface that shows our A2HS button
//     a2hsBtn.style.display = 'none';  // Show the prompt
//   }

// })

// function showAddToHomeScreen() {
//   const a2hsBtn = document.querySelector(".add-to-home-screen");
//   a2hsBtn.style.display = "flex";
//   a2hsBtn.addEventListener("click", addToHomeScreen);
// }

// function addToHomeScreen() {
//   const a2hsBtn = document.querySelector(".add-to-home-screen");  // hide our user interface that shows our A2HS button
//   a2hsBtn.style.display = 'none';  // Show the prompt
//   deferredPrompt.prompt();  // Wait for the user to respond to the prompt
//   deferredPrompt.userChoice
//     .then(function (choiceResult) {

//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the A2HS prompt');
//       } else {
//         console.log('User dismissed the A2HS prompt');
//       }

//       deferredPrompt = null;

//     });
// }
