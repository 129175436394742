/* eslint-disable no-unused-vars */
import axios from 'axios';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../helper';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Header from '../../shared/back-button';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import IconCropQuare from '@material-ui/icons/CropSquare';
import {
  _getShoppingListItem,
  // _getShoppingList, 
  _deleteShoppingItem,
  _deleteShoppingList,
  _toggleShoppingItem
} from '../../../actions';
import Preloader from '../../Preloader';
import DialogConfirm from '../../Dialog';
import DialogConfirmDeleteAll from '../../DialogDeleteAll';
import { _getTime, _dataInfoTracking } from '../../../lib';
import { errorCodeConstants } from '../../../config';
import lang from '../../../config/languages.json';

class ListPage extends Component {
  constructor(props) {
    //console.log(props);
    super(props);
    this.state = {
      checked: [],
      confirmed: false,
      open: false,
      openDialogDeleteAll: false,
      id: null,
      list: [],
      itemId: null,
      itemName: null,
      itemCreatedDate: null
    };
  }

  async componentDidMount() {
    const { match: { params : { info } } } = this.props;
    const decodedParams = info ? atob(info) : '';
    const params = decodedParams.split('/');
    const itemId = params[0];
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _getShoppingListItem, isAuthenticated, match } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }

    await _getShoppingListItem(itemId);

    const { isShoppingListItem: { data } } = this.props;
    const { checked } = this.state;
    const newChecked = [...checked];
    data.forEach(item => {
      if (item.is_active === true) {
        newChecked.push(item.id);
      }
    });

    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_list_of_view, errorCodeConstants.listView);
    
    this.setState({ 
      checked: newChecked,
      itemId: params[0],
      itemName: params[1],
      itemCreatedDate: params[2]
    });
  }

  handleToggle = async (value) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_entry_checked, errorCodeConstants.entryCheck);
      newChecked.push(value);
    } else {
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_entry_unchecked, errorCodeConstants.entryUncheck);
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
    const { _toggleShoppingItem } = this.props;
    await _toggleShoppingItem(this.state.itemId, value);
  };

  handleClickOpen = async (id) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { match } = this.props;
    const listId = this.state.itemId;
    const isChecked = localStorage.getItem("del") ? true : false;
    if (isChecked) {
      await this.props._deleteShoppingItem(listId, id);
      await this.props._getShoppingListItem(listId);
      _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_entry_deleted, errorCodeConstants.entryDelete);
    }
    this.setState({
      open: true,
      id
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      openDialogDeleteAll: false,
      id: null
    });
  };

  handleClickConfirm = async (id) => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { match } = this.props;
    const listId = this.state.itemId;
    await this.props._deleteShoppingItem(listId, id);
    await this.props._getShoppingListItem(listId);
    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_entry_deleted, errorCodeConstants.entryDelete);
    this.setState({
      confirmed: true,
      open: false
    });

  }

  handleClickConfirmDeleteAll = async () => {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { isShoppingListItem: { data }, _deleteShoppingItem, match, _deleteShoppingList } = this.props;
    const listId = this.state.itemId;
    let arr = [];
    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_list_of_delete, errorCodeConstants.listDelete);

    if (data.length > 0) {
      data.map(async (value, index) => {
        const { id } = value;
        return await arr.push(_deleteShoppingItem(listId, id));
      });

      axios.all(arr).then(async () => {
        await _deleteShoppingList(listId);
        history.push('/user/shopping-list');
      });
    } else {
      await _deleteShoppingList(listId);
      history.push('/user/shopping-list');
    }

    this.setState({
      openDialogDeleteAll: false
    });
  };

  handleOpenDeleteAll = async () => {
    const isChecked = localStorage.getItem("delall") ? true : false;
    const { isShoppingListItem: { data }, _deleteShoppingItem, match, _deleteShoppingList } = this.props;
    const listId = this.state.itemId;
    let arr = [];

    if (isChecked) {
      if (data.length > 0) {
        data.map(async (value, index) => {
          const { id } = value;
          return await arr.push(_deleteShoppingItem(listId, id));
        });

        axios.all(arr).then(async () => {
          await _deleteShoppingList(listId);
          history.push('/user/shopping-list');
        });
      } else {
        await _deleteShoppingList(listId);
        history.push('/user/shopping-list');
      }
    }

    this.setState({
      openDialogDeleteAll: true
    });
  }

  render() {
    const { classes, match, isShoppingListItem, isFetching, location } = this.props;
    //console.log(this.state);
    const { itemId, itemName, itemCreatedDate } = this.state;
    //const name = !_.isEmpty(location) ? '' : ;
    //const created_at = '';
    const { open, id, checked, list, openDialogDeleteAll } = this.state;
    const { data } = isShoppingListItem;
    const isChecked = localStorage.getItem("del") ? true : false;
    const isCheckedDeleteAll = localStorage.getItem("delall") ? true : false;
    return (
      <div className="list-page main-page">
        <Header to="/user/shopping-list" title="einkaufszettel" />
        <div className="icon-delete-header">
          <IconButton
            aria-label="Comments" className="icon-delete"
            onClick={this.handleOpenDeleteAll}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <div className="main-screen">
          {isFetching ? <Preloader /> :
            (<div className="list-container">
              <div className="list-screen">
                <div className="list-header">
                  <h1>{_.isEmpty(itemName) ? '' : itemName}</h1>
                  <span>{_.isEmpty(itemCreatedDate) ? '' : _getTime(itemCreatedDate).date}</span>
                </div>
                <List className={`${classes.root} add-item-to-list`}>
                  {data && data.map((value, index) => {
                    const { id, name, is_active } = value;
                    return (
                      <ListItem key={index} role={undefined} divider dense button onClick={() => this.handleToggle(id)}>

                        <Checkbox icon={<IconCropQuare />} checkedIcon={<CheckedIcon />}
                          checked={this.state.checked.indexOf(id) !== -1}
                          tabIndex={-1}
                          classes={{
                            root: classes.checkbox,
                            checked: classes.checked,
                          }}
                        />
                        <ListItemText primary={_.isEmpty(name) ? '' : name}
                          className="title-list"
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="Comments" className="icon-delete"
                            onClick={this.handleClickOpen.bind(this, id)}
                          >
                            <DeleteIcon />
                          </IconButton>

                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  }
                  )}
                </List>
                {
                  open && !isChecked &&
                  (<DialogConfirm
                    handleConfirm={this.handleClickConfirm}
                    handleClose={this.handleClose}
                    open={this.state.open}
                    id={id}
                  />)
                }
                {
                  openDialogDeleteAll && !isCheckedDeleteAll &&
                  (<DialogConfirmDeleteAll
                    handleConfirm={this.handleClickConfirmDeleteAll}
                    handleClose={this.handleClose}
                    open={this.state.openDialogDeleteAll}
                  />)
                }
                <Button
                  size="small"
                  className="btn-add"
                  component={Link}
                  to={{
                    pathname: `/shopping/add-article/${btoa(`${itemId}/${itemName}/${itemCreatedDate}`)}`,
                  }}
                >
                  <AddIcon className={classNames(classes.leftIcon, classes.iconSmall, classes.icon)} />

                </Button>
              </div>
            </div>)}

        </div>
      </div>
    )
  }
}



function DeleteIcon(props) {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1206 2.87513H11.0787V0.632045C11.0787 0.296902 10.7987 0.0249023 10.4537 0.0249023H4.54839C4.20339 0.0249023 3.92339 0.296902 3.92339 0.632045V2.87513H0.879639C0.534639 2.87513 0.254639 3.14713 0.254639 3.48228C0.254639 3.81742 0.534639 4.08942 0.879639 4.08942H1.72776V15.2484C1.72776 16.2004 2.52526 16.9751 3.50526 16.9751H11.4965C12.4759 16.9751 13.2728 16.2007 13.2728 15.2484V4.08942H14.1206C14.4656 4.08942 14.7456 3.81742 14.7456 3.48228C14.7456 3.14713 14.4656 2.87513 14.1206 2.87513ZM12.0228 4.08942H13.2728V3.77603C13.2728 3.44059 12.9928 3.16889 12.6478 3.16889C12.3028 3.16889 12.0228 3.44089 12.0228 3.77603V4.08942ZM2.97776 4.08942H1.72776V3.77603C1.72776 3.44089 2.00776 3.16889 2.35276 3.16889C2.69776 3.16889 2.97776 3.44089 2.97776 3.77603V4.08942ZM2.97776 4.08942V15.2484C2.97776 15.531 3.21433 15.7608 3.50526 15.7608H11.4965C11.7868 15.7608 12.0228 15.531 12.0228 15.2484V4.08942H2.97776ZM6.6137 13.57V5.4904C6.6137 5.15526 6.3337 4.88326 5.9887 4.88326C5.6437 4.88326 5.3637 5.15526 5.3637 5.4904V13.57C5.3637 13.9051 5.6437 14.1771 5.9887 14.1771C6.3337 14.1771 6.6137 13.9054 6.6137 13.57ZM9.63745 13.57V5.4904C9.63745 5.15526 9.35745 4.88326 9.01245 4.88326C8.66745 4.88326 8.38745 5.15526 8.38745 5.4904V13.57C8.38745 13.9051 8.66745 14.1771 9.01245 14.1771C9.35745 14.1771 9.63745 13.9054 9.63745 13.57ZM5.17339 2.87513H9.82839V1.23919H5.17339V2.87513Z" fill="#C0A35E" />
    </svg>

  );
}


function CheckedIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.05152L8.92235 14.8571L22 2" stroke="#C0A35E" strokeWidth="4" />
    </svg>

  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isShoppingList: state.shoppingList.data,
  isShoppingListItem: state.shoppingListItem,
  isFetching: state.shoppingListItem.isFetching,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _getShoppingListItem,
    // _getShoppingList,
    _deleteShoppingItem,
    _deleteShoppingList,
    _toggleShoppingItem
  }, dispatch)
);

export default withStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  checkbox: {
    color: '#5A5A5A',
    '&$checked': {
      color: '#C0A35E',
    },
  },
  checked: {},

  icon: {
    color: '#C0A35E'
  },
  dimension: {
    height: '66px'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  }
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPage)));
