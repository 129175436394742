export function authHeader() {
  let user = JSON.parse(localStorage.getItem('bonus_app_tk'));

  if (user && user.access_token) {
      return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + user.access_token 
      };
  } else {
      return {};
  }
}