import {
  alertConstants
} from '../config';

const INITIALSTATE_GET = {
  data: [],
  isFetching: false,
  error: false
};

const INITIALSTATE_VERIFY = {
  data: [],
  isFetching: false,
  error: false
};

export const GetOTP = (state = INITIALSTATE_GET, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_GET_OTP:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_GET_OTP:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isSent: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_GET_OTP:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};

export const VerifyOTP = (state = INITIALSTATE_VERIFY, action) => {
  switch (action.type) {
    case alertConstants.FETCHING_DATA_VERIFY_OTP:
      return {
        ...state,
        data: [],
        isFetching: true
      };
    case alertConstants.FETCH_DATA_SUCCESS_VERIFY_OTP:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: false
      };
    case alertConstants.FETCH_DATA_FAILURE_VERIFY_OTP:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};
