/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Fab } from '@material-ui/core';

import BackIcon from '../shared/back-icon';

class WizardPostCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: ''
    };
    this.handSubmitCode = this.handSubmitCode.bind(this);
    this.handleChangePostCode = this.handleChangePostCode.bind(this);
  }

  handleChangePostCode(e) {
    this.setState({ postcode: e.target.value });
  }

  handSubmitCode(e) {
    e.preventDefault();
    const { postcode } = this.state;
    const { handleLoginPostCode } = this.props;
    return handleLoginPostCode(postcode);
  }
  render() {
    const { postcode } = this.state;
    const {
      handlePreviousPage,
      isSubmitBirthDayError
    } = this.props;
    return (
      <form onSubmit={this.handSubmitCode}>
        <div className="section-back">
          <Fab
            variant="extended"
            className="back-button img-camera"
            onClick={handlePreviousPage}
          >
            <BackIcon className="svg-back-icon" />
            anmeldung
          </Fab>
        </div>
        <div className="section-content">
          <h2>Ihr Geburtsdatum</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin commodo in nisi.</p>
          <div className="input-fields field-email">
            <input
              type="text"
              value={postcode}
              className="input-id-login"
              placeholder="PLZ"
              onChange={this.handleChangePostCode}
            />
            {isSubmitBirthDayError && 
              <div>
                <span>Ihre PLZ ist falsch!</span>
              </div>
            }
          </div>
          <div className="submits submit-email">
            <button className="account-submit" type="submit">
              PLZ bestätigen
            </button>
          </div>
        </div>
      </form>
    );
  }
}
 
export default WizardPostCodeForm;
