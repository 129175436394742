import * as _ from "lodash";
import {
  alertConstants
} from '../config';

const INITIALSTATE = {
  dataOpeningTime: [],
  isFetchingOpentime: false,
  errorOpentime: false,
  dataLastedNews: [],
  isFetchingNews: false,
  errorNews: false,
  dataCoupons: [],
  isFetchingCoupons: false,
  errorCoupons: false,
  dataCategories: [],
  isFetchingCategories: false,
  errorCategories: false,
};

export default (state = INITIALSTATE, action) => {
  switch (action.type) {
    // Opening time
    case alertConstants.GET_OPENING_TIME:
      return {
        ...state,
        dataOpeningTime: [],
        isFetchingOpentime: true
      };
    case alertConstants.GET_OPENING_TIME_SUCCESS:
      return {
        ...state,
        dataOpeningTime: action.payload.data,
        isFetchingOpentime: false
      };
    case alertConstants.GET_OPENING_TIME_FAILURE:
      return {
        ...state,
        isFetchingOpentime: false,
        errorOpentime: action.payload
      };

    // News
    case alertConstants.GET_LASTED_NEWS_DATA:
      return {
        ...state,
        dataLastedNews: [],
        isFetchingNews: true
      };
    case alertConstants.GET_LASTED_NEWS_DATA_SUCCESS:
      return {
        ...state,
        dataLastedNews: action.payload.data,
        isFetchingNews: false
      };
    case alertConstants.GET_LASTED_NEWS_DATA_FAILURE:
      return {
        ...state,
        isFetchingNews: false,
        errorNews: action.payload
      };

    // Coupons
    case alertConstants.GET_COUPON_IN_GUEST_DATA:
      return {
        ...state,
        dataCoupons: [],
        isFetchingCoupons: true
      };
    case alertConstants.GET_COUPON_IN_GUEST_DATA_SUCCESS:
      return {
        ...state,
        dataCoupons: action.payload.data,
        isFetchingCoupons: false
      };
    case alertConstants.GET_COUPON_IN_GUEST_DATA_FAILURE:
      return {
        ...state,
        isFetchingCoupons: false,
        errorCoupons: action.payload
      };

    // Categories
    case alertConstants.GET_CATEGORIES_IN_GUEST_DATA:
      return {
        ...state,
        dataCategories: [],
        isFetchingCategories: true
      };
    case alertConstants.GET_CATEGORIES_IN_GUEST_DATA_SUCCESS:
      return {
        ...state,
        dataCategories: action.payload.data,
        isFetchingCategories: false
      };
    case alertConstants.GET_CATEGORIES_IN_GUEST_DATA_FAILURE:
      return {
        ...state,
        isFetchingCategories: false,
        errorCategories: action.payload
      };
    case alertConstants.CLEAN_UNUSED_QUEST_CATEGORY:
        const coupons = state.dataCoupons;
        const categories = state.dataCategories;
        const filteredAuestCategory = _.map(categories, (category) => {
          return _.find(coupons, { category_id : category.id}) ? category : undefined;
        });
      return {
        ...state,
        dataCategories: _.compact(filteredAuestCategory)
      };
    default:
      return state;
  }
};
