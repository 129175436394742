/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../../helper';
import { withRouter, Link } from 'react-router-dom';
import Header from '../../shared/back-button';
import Button from '@material-ui/core/Button';
import { _createShoppingList } from '../../../actions';
import lang from '../../../config/languages.json';
import { _dataInfoTracking } from '../../../lib';
import { errorCodeConstants } from '../../../config';

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.handleCreateListButton = this.handleCreateListButton.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      value: ''
    }
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      history.push('/login');
    }
    this.createList.focus();
  }

  handleOnChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  async handleCreateListButton() {
    const { de_de } = lang[1];
    const userId = localStorage.getItem('id');
    const { _createShoppingList } = this.props;
    const { value } = this.state;
    await _createShoppingList({
      name: value
    });

    _dataInfoTracking(userId, de_de.sentry_info, de_de.sentry_list_of_create, errorCodeConstants.listCreate);

    history.push('/user/shopping-list');
  }

  goBack() {
    history.goBack();
  }

  render() {
    const { isFetching } = this.props;
    const { de_de } = lang[1];
    const disable = isFetching ? true : false;
    return (
      <div className="add-item main-page">
        <Header
          title={de_de.new_shopping_list}
          onClick={this.goBack}
        />
        <div className="main-screen">
          <div className="add-item-container">
            <div className="add-item-screen">
              <form>
                <input 
                  type="text" 
                  className="outlined-bare" 
                  placeholder={de_de.new_shopping_list_form_placeholder} onChange={this.handleOnChange} 
                  ref={(input) => this.createList = input}
                  maxLength = "100"
                />
                <Button
                  variant="contained"
                  className="btn-points"
                  disabled={disable}
                  onClick={this.handleCreateListButton}
                >
                  {de_de.new_shopping_list_submit_btn}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.LoginEmail.isAuthenticated || state.LoginPhone.isAuthenticated,
  isFetching: state.createList.isFetching
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    _createShoppingList
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddItem));
